import React, {Component, Fragment} from 'react';
import moment from "moment";
import MaturityDateUtil from "../../common/MaturityDateUtil";

/**
 * MaturityDatePicker Class.
 *
 */
class MaturityDatePicker extends Component {

    /**
     * Constructor.
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.getValue = this.getValue.bind(this);
        this.someIncludedDateInMonth = this.someIncludedDateInMonth.bind(this);
        this.includedDates = this.includedDates.bind(this);
        this.getDateFromMonthAndYearState = this.getDateFromMonthAndYearState.bind(this);
        this.setMonthAndYearStateFromDate = this.setMonthAndYearStateFromDate.bind(this);
        this.dateIsIncluded = this.dateIsIncluded.bind(this);
        this.prevTermOrYear = this.prevTermOrYear.bind(this);
        this.nextTermOrYear = this.nextTermOrYear.bind(this);

        this.state = {
            month: this.props.month !== undefined ? this.props.month : this.getValue().month(),
            year: this.props.year !== undefined ? this.props.year : this.getValue().year(),
            colorIcon: this.props.colorIcon === undefined ? 'calendar-icon' : 'calendar-icon-'+this.props.colorIcon
        };
    }

    /**
     * Get value.
     *
     */
    getValue() {
        return this.props.value.isValid() ? this.props.value : moment();
    }

    /**
     *
     * @param firstDayOfMonth
     * @returns {boolean | *}
     */
    someIncludedDateInMonth(firstDayOfMonth) {
        return this.includedDates().some(it => it.isSame(firstDayOfMonth, 'month'));
    }

    /**
     *
     * @returns {moment.Moment[]}
     */
    includedDates() {
        return MaturityDateUtil.getAllStandardTermDays(this.props.productCardTerms);
    }

    /**
     *
     * @returns {moment.Moment}
     */
    getDateFromMonthAndYearState() {
        return moment().date(1).month(this.state.month).year(this.state.year);
    }

    /**
     *
     * @param date
     */
    setMonthAndYearStateFromDate(date) {
        this.setState({
            month: date.month(),
            year: date.year()
        });
    }

    /**
     *
     * @param date
     * @returns {boolean | *}
     */
    dateIsIncluded(date) {
        return this.includedDates().some(it => it.isSame(date, 'day'));
    }

    /**
     * Go to previous term or year.
     *
     */
    prevTermOrYear() {

        const prevYearMoment = this.getDateFromMonthAndYearState().subtract(1, 'year');

        /*
         * Get prev term closest moment.
         */
        const closestMomentsForTerms = MaturityDateUtil.getClosestMomentsForTerms(this.props.productCardTerms);
        const beforeTermsClosestMoments = closestMomentsForTerms.filter(it => it.isBefore(this.getDateFromMonthAndYearState(), 'day'));
        const prevTermClosestMoment = beforeTermsClosestMoments[beforeTermsClosestMoments.length - 1];

        /*
         * Go to previous term closest moment whenever it exists and it in the last 12 months.
         */
        const prevMomentToGo = prevTermClosestMoment !== undefined && prevTermClosestMoment.isAfter(prevYearMoment) ? prevTermClosestMoment : prevYearMoment;

        this.setState({
            month: prevMomentToGo.month(),
            year: prevMomentToGo.year()
        });

    }

    /**
     * Go to next term or year.
     *
     */
    nextTermOrYear() {

        const nextYearMoment = this.getDateFromMonthAndYearState().add(1, 'year');

        /*
         * Get next term closest moment.
         */
        const closestMomentsForTerms = MaturityDateUtil.getClosestMomentsForTerms(this.props.productCardTerms);
        const afterTermsClosestMoments = closestMomentsForTerms.filter(it => it.isAfter(this.getDateFromMonthAndYearState().add(1, 'month'), 'day'));
        const nextTermClosestMoment = afterTermsClosestMoments[0];

        /*
         * Go to next term closest moment whenever it exists and it in the last 12 months.
         */
        const nextMomentToGo = nextTermClosestMoment !== undefined && nextTermClosestMoment.isBefore(nextYearMoment, 'day') ? nextTermClosestMoment : nextYearMoment;

        this.setState({
            month: nextMomentToGo.month(),
            year: nextMomentToGo.year()
        });

    }

    /**
     *
     * @param month
     * @param year
     * @returns {Array}
     */
    static daysOfMonth(month, year) {
        const firstDayOfMonth = moment().date(1).month(month).year(year);
        const firstDayToShow = firstDayOfMonth.clone().subtract(firstDayOfMonth.day(), 'day');
        const lastDayOfMonth = firstDayOfMonth.clone().add(1, 'month').subtract( 1, 'day');
        const lastDayToShow = lastDayOfMonth.clone().add(6 - lastDayOfMonth.day(), 'day');

        const dates = [];

        for (let i = firstDayToShow; i.isSameOrBefore(lastDayToShow); i = i.clone().add(1, 'day')) {
            dates.push(i);
        }

        return dates;
    }

    /**
     *
     * @returns {*}
     */
    render() {
        return (
            <span className="curves-maturity-date-picker">
                <div className="display"
                     onClick={() =>
                        this.setState({
                            show: true,
                            month: this.props.month !== undefined ? this.props.month : this.getValue().month(),
                            year: this.props.year !== undefined ? this.props.year : this.getValue().year()
                        })
                }>
                    {this.props.value.format('dddd Do MMMM YYYY')}
                    <div className={this.state.colorIcon}></div>
                    <span className="margin-left-10px">({this.props.value.startOf('day').diff(moment().startOf('day'), 'day')} days)</span>
                </div>

                <div className={"curves-popover-message popover-top z-index-11-important left-50per " +
                                "top-45px translate-x-minus-50per line-height-25px " + (this.state.show ? " popover-visible" : "")}>
                    {this.renderMonth()}
                </div>
                {this.state.show &&
                    <div className="curves-disable-all-transparent"
                         onClick={e => {
                             this.setState({show: false});
                             e.preventDefault();
                         }}></div>
                }
            </span>
        );
    }

    /**
     * Render month.
     *
     * @returns {*}
     */
    renderMonth() {
        const daysOfMonth = MaturityDatePicker.daysOfMonth(this.state.month, this.state.year);

        return (
            <Fragment>
                <div className="display-flex">
                    <div className="flex-1">
                        <a className="cursor-pointer"
                           onClick={this.prevTermOrYear}>&lt;&lt;</a>
                    </div>
                    <div className="flex-1">
                        <a className="cursor-pointer"
                           onClick={() => this.setMonthAndYearStateFromDate(this.getDateFromMonthAndYearState().subtract(1, 'month'))}>&lt;</a>
                    </div>
                    <div className="flex-5 white-space-nowrap text-align-center">
                        {this.getDateFromMonthAndYearState().format('MMMM YYYY')}
                    </div>
                    <div className="flex-1">
                        <a className="cursor-pointer"
                           onClick={() => this.setMonthAndYearStateFromDate(this.getDateFromMonthAndYearState().add(1, 'month'))}>&gt;</a>
                    </div>
                    <div className="flex-1 text-align-right">
                        <a className="cursor-pointer"
                           onClick={this.nextTermOrYear}>&gt;&gt;</a>
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        <td className="padding-left-5px padding-right-5px">Sun</td>
                        <td className="padding-left-5px padding-right-5px">Mon</td>
                        <td className="padding-left-5px padding-right-5px">Tue</td>
                        <td className="padding-left-5px padding-right-5px">Wed</td>
                        <td className="padding-left-5px padding-right-5px">Thu</td>
                        <td className="padding-left-5px padding-right-5px">Fri</td>
                        <td className="padding-left-5px padding-right-5px">Sat</td>
                    </tr>
                    </thead>
                    <tbody>
                    {[...Array(daysOfMonth.length / 7)].map((_, week) =>
                        <tr key={week}>
                            {daysOfMonth.filter((_, dateIdx) => Math.floor(dateIdx / 7) === week).map(date =>
                                <td key={date.format('Y-M-D')}
                                    className={'day padding-left-5px padding-right-5px cursor-pointer text-align-right '
                                    + (this.getValue() && this.getValue().isSame(date, 'day') ? ' selected ' : '')
                                    + (this.dateIsIncluded(date) ? ' enabled ' : '')
                                    + (MaturityDateUtil.isHolyday(date.toDate()) ? ' holyday ' : '')}
                                    onClick={e => {
                                        !MaturityDateUtil.isHolyday(date.toDate()) && (this.setState({show: false})
                                                                                   || this.props.onChange(date));

                                        e.preventDefault();
                                    }}>
                                    {date.date()}
                                </td>
                            )}
                        </tr>
                    )}
                    </tbody>
                </table>
            </Fragment>
        );
    }

}

export default MaturityDatePicker;
