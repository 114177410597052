export const PRODUCT_TYPES = {
    TERM_DEPOSIT: 'TERM_DEPOSIT',
    NEGOTIABLE_CERTIFICATE_OF_DEPOSIT: 'NEGOTIABLE_CERTIFICATE_OF_DEPOSIT',
    FLOATING_TERM_DEPOSIT: 'FLOATING_TERM_DEPOSIT',
    FLOATING_NCD: 'FLOATING_NCD',
    CASH_ACCOUNT: 'CASH_ACCOUNT',
    NOTICE_ACCOUNT: 'NOTICE_ACCOUNT'
};

const LABELS = [
    {product: PRODUCT_TYPES.TERM_DEPOSIT, label: 'Term Deposits', secondary_label:'Term Deposit'},
    {product: PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT, label: 'Negotiable Certificates of Deposit', secondary_label:'Negotiable Certificate of Deposit'},
    {product: PRODUCT_TYPES.FLOATING_TERM_DEPOSIT, label: 'Floating Term Deposits', secondary_label:'Floating Term Deposit'},
    {product: PRODUCT_TYPES.FLOATING_NCD, label: 'Floating NCDs', secondary_label:'Floating NCD'},
    {product: PRODUCT_TYPES.CASH_ACCOUNT, label: 'Cash Accounts', secondary_label:'Cash Account'},
    {product: PRODUCT_TYPES.NOTICE_ACCOUNT, label: 'Notice Accounts', secondary_label:'Notice Account'},
];

export const productLabels = (product) => {
    const productFound = LABELS.find(it => it.product === product);
    return productFound ? productFound.label : product.toString();
};

export const productSecondaryLabels = (product) => {
    const productFound = LABELS.find(it => it.product === product);
    return productFound ? productFound.secondary_label : product.toString();
};

/**
 * Used to know if a product has termDeposit behaviour, it means that allow EOIs and responds to filters
 * @param product
 * @returns {boolean}
 */
export const hasTermDepositBehavior = (product) => {
    return product.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT || product.product_type === PRODUCT_TYPES.TERM_DEPOSIT ;
};

