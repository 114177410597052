import React, {Component, Fragment} from 'react';
import {leftMenu, rightMenu} from "../config/routes";
import {NavLink, withRouter} from "react-router-dom";
import queryString from "query-string";
import GlobalStore from "../store/GlobalStore";
import UserStatService from "../../service/UserStatService";
import window from "global";
import Spinner from "../common/Spinner";
import UserNotificationService from "../../service/UserNotificationService";
import ReactDOM from 'react-dom';



class NotificationsMenu extends Component {

    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.renderNotifications = this.renderNotifications.bind(this);
        this.renderNotificationsBadge = this.renderNotificationsBadge.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.state = {
            menuOpen: false,
            notifications: undefined
        }

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClickOutside(event) {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.toggleMenu(false);
        }
    }

    markAsRead(notificationId) {
        UserNotificationService.markAsRead(notificationId).then(() => this.fetchData());
    }

    componentDidMount() {
        this.fetchData();
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    

    fetchData() {
        UserNotificationService.findAll().then(notifications => {
            this.setState({
                notifications: notifications
            })
        });
    }

    toggleMenu(open) {
        this.setState({menuOpen: open})
    }

    renderNotifications() {
        if (this.state.notifications === undefined) {
            return (<Spinner/>)
        } else {
            if (this.state.notifications.length === 0) {
                return (
                    <div  className={"notification-item"}>
                        <div className={"notification-text"}>
                            No notifications
                        </div>
                    </div>
                )
            } else {
                return (
                    this.state.notifications.map(notification =>
                        <div key={"notification_"+notification.id} className={"notification-item " + (notification.readDate === null?"new":"")}>
                            <div className={"notification-text"}>
                                {notification.content}
                            </div>
                            {notification.readDate === null && <button className={"mark-as-read"} onClick={() => this.markAsRead(notification.id)}><span>x</span></button>}
                        </div>
                    )
                )
            }
        }
    }

    renderNotificationsBadge() {
        if (this.state.notifications !== undefined) {
            const notificationsUnread = this.state.notifications.filter(notification => notification.readDate === null).length;
            if(notificationsUnread > 0){
                return (
                    <span className={"badge"}>{notificationsUnread}</span>
                )
            }
        }
    }

    render() {
        return (
            <div className={"notifications-menu"}>
                <a href="#" className={"menu-option"} onClick={() => this.toggleMenu(!this.state.menuOpen)}>
                    Notifications
                </a>
                {this.renderNotificationsBadge()}
                <div className={"notifications-container" + (this.state.menuOpen ? " open" : "")}>
                    <div className={"notifications-header"}>
                        Notifications
                    </div>
                    <div className={"notifications-body"}>
                        {this.renderNotifications()}
                    </div>
                </div>
            </div>
        );
    }

};

export default withRouter(GlobalStore.connect(NotificationsMenu));