import React, { Component } from 'react';
import GlobalStore from "../store/GlobalStore";
import window from "global/window";
import GeneralInquiryForm from './GeneralInquiryForm';
import DialogUtil from "../../common/DialogUtil";
import SimpleDialogInquiry from "../../client/dialog/SimpleDialogInquiry";
import UserStatService from "../../service/UserStatService";
import LiveChatButton from "./LiveChatButton";
import DateUtil from "../../common/DateUtil";


class FloatFooter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            position : {
                position : 'absolute',
                bottom: 0
            },
            subjectMail:'General enquiry through Yieldhub'
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.generalQuote = this.generalQuote.bind(this);
    }


    handleScroll() {

        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = Math.round(windowHeight + window.pageYOffset);

        if (windowBottom  >= docHeight) {
            this.setState({
                position : {
                    position : 'relative',
                },
            });
        } else {
            if (windowBottom < (docHeight/1.2)){
                    this.setState({
                        position : {
                            position : 'fixed',
                            bottom: 0,
                        },
                    });

            }
        }
    }

    generalQuote() {
        UserStatService.createPageLoadUserStat('GENERAL_INQUIRY');
        GlobalStore.setState({
            showGlobalEnquiryForm:true
        });

        DialogUtil.open(
            <SimpleDialogInquiry className="curves-general-inquiry" title="General Quote"
                        onClickOutside={DialogUtil.close}>
                <GeneralInquiryForm />
            </SimpleDialogInquiry>
        );
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }



    render() {
        let mailTo = '';
         if (this.props.globalStore.user.relationship_manager_email || this.props.globalStore.user.relationship_manager_email!=''){
            mailTo = 'mailto:'+this.props.globalStore.user.relationship_manager_email+'?subject='+this.state.subjectMail;
         }
         let name =  this.props.globalStore.user.relationship_manager_name ? this.props.globalStore.user.relationship_manager_name : '';

        return (

                <div className='curves-float-footer'  style={this.state.position} >
                   <div className="first-column">

                   {name &&
                    <div className="item-footer-float">{name}</div>
                   }
                    {mailTo &&
                     <div className="item-footer-float"><a href={mailTo} target="_blank">{this.props.globalStore.user.relationship_manager_email}</a></div>
                    }

                   <div className="item-footer-float">02 9690 2188</div>
                   </div>

                   <div className="second-column">
                       <div className="last-update-datetime">
                           Last Update: {DateUtil.formatISO_8601_1(this.props.globalStore.lastUpdateDateTime)}
                       </div>
                       <button  onClick={this.generalQuote} className="button-inquiry">GENERAL QUOTE</button>
                       <LiveChatButton/>
                   </div>
               </div>
            );
    }
}

export default GlobalStore.connect(FloatFooter);
