import React, {Component, Fragment} from 'react';
import Spinner from "../common/Spinner";
import {formatTermName} from "../../common/termLabel";
import {
    format_num,
    formatAmount,
    formatRate,
    strip_num
} from "../../common/formatUtil";
import UserStatService from "../../service/UserStatService";
import MaturityDateUtil from "../../common/MaturityDateUtil";
import {getBankImageUrl} from "../../common/bankImageUtil";
import BankImage from "./BankImage";
import moment from "moment";
import DialogUtil from "../../common/DialogUtil";
import EOIService from "../../service/EOIService";
import MaturityLookingForSomethingElseDialog from "../dialog/MaturityLookingForSomethingElseDialog";
import {FINANCIAL_SCHEME} from "../../common/types/financialSchemeType";
import {getFullBankRating} from "../../common/bankRatingUtil";
import {FOSSIL_FUEL_FREE} from "../../common/types/fossilFuelFreeTypes";
import MaturityDatePicker from "../common/MaturityDatePicker";
import MaturityRedeemDialog from "../dialog/MaturityRedeemDialog";
import DateUtil from "../../common/DateUtil";
import {PRODUCT_TYPES} from "../../common/types/productTypes";
import {CSSTransition} from "react-transition-group";
import MaturityService from "../../service/MaturityService";
import {getTermGroup} from "../../common/rateUtil";

const formatAttributes = (maturity) => {
    let attributes = [];
    let allTerms = maturity.main_product.attributes.filter(item => (+item.value !== 0)).map((attr) => {
        return {name: attr.name, order: attr.order}
    });

    maturity.secondary_products.forEach((product) => {
        product.attributes.forEach((attr) => {
            const term = {name: attr.name, order: attr.order};
            if (+attr.value !== 0 && allTerms.find((element) => {return element.name === term.name}) === undefined) {
                allTerms.push(term);
            }
        });
    });

    allTerms.sort((a, b) => {return a.order - b.order;});

    allTerms.forEach((term) => {
        const primaryAttribute = maturity.main_product.attributes.find((element) => {return element.name === term.name});

        let secondaryProductsAttribute = [];
        maturity.secondary_products.forEach((product) => {
            const attr = product.attributes.find((element) => {return element.name === term.name});
            if (attr !== undefined) {
                secondaryProductsAttribute.push({product: product, attribute: attr});
            }
        });

        const primaryProductAttribute = primaryAttribute !== undefined ? {product: maturity.main_product, attribute: primaryAttribute} : undefined;

        const element = {
            name: term.name,
            primary_value: primaryAttribute !== undefined ? primaryAttribute.value : null,
            primary_product_attribute: primaryProductAttribute,
            secondary_products_attributes: secondaryProductsAttribute
        };

        attributes.push(element);
    });
    return attributes;
};

class MaturityEOI extends Component {
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.submitEOI = this.submitEOI.bind(this);
        this.selectTerm = this.selectTerm.bind(this);
        this.selectMaturityDate = this.selectMaturityDate.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.handleLookingForSomethingElse = this.handleLookingForSomethingElse.bind(this);
        this.renderSelectTerm = this.renderSelectTerm.bind(this);
        this.renderMaturityForm = this.renderMaturityForm.bind(this);
        this.renderTermProductAlternatives = this.renderTermProductAlternatives.bind(this);
        this.renderBankInformation = this.renderBankInformation.bind(this);
        this.renderRolloverDataFragment = this.renderRolloverDataFragment.bind(this);
        this.renderRolloverForm = this.renderRolloverForm.bind(this);
        this.renderSuccessSubmit = this.renderSuccessSubmit.bind(this);
        this.shouldRenderCommentsInput = this.shouldRenderCommentsInput.bind(this);
        this.changeRolloverAmount = this.changeRolloverAmount.bind(this);
        this.changeRolloverOption = this.changeRolloverOption.bind(this);
        this.closeSuccessInformation = this.closeSuccessInformation.bind(this);

        const attributes = formatAttributes(props.maturity);
        const showLiveRate = this.props.showLiveRate;
        const rateRequestTerms = [];
        const selectTermsOpen = false;

        const defaultTerm = attributes.find((element) => {return element.name === this.props.maturity.contract.default_term});
        const defaultProductAndAttribute = defaultTerm && this.getProductAndAttributeOnTerm(defaultTerm, this.props.maturity);
        const defaultAttribute = defaultProductAndAttribute && defaultProductAndAttribute[1];
        const defaultMaturityDate = defaultAttribute && MaturityDateUtil.closestDate(new Date(), parseInt(hackTermDays(defaultAttribute.name)));
        //If Maturity rates hasn't defined yet -> set main product as default product.
        const defaultProduct = defaultProductAndAttribute?defaultProductAndAttribute[0]:this.props.maturity.main_product;



        this.state = {
            submitOk: null,
            sending: false,
            sentLookingForSomethingElse: false,
            redeem: {
                sent: false,
                success: false
            },
            validationErrors: {},
            attributes: attributes,
            selectedTerm: defaultTerm,
            selectedProduct: defaultProduct,
            selectedAttribute: defaultAttribute,
            maturityDate: defaultMaturityDate,
            rolloverAmount: 0,
            overflow: 'hidden',
            showLiveRate: showLiveRate,
            rateRequestTerms: rateRequestTerms,
            selectTermsOpen: selectTermsOpen,
            rateRequestSubmitted: false,
            rolloverBank: true
        };
    }

    componentDidMount() {
        /**
         * HACK: to show date picker I need overflow: visible, not hidden.
         */
        setTimeout(() => {
            this.setState({overflow: 'visible'});
        }, 1000)
    }


    selectTerm(term) {
        UserStatService.createMaturityEOIFormOpenUserStat(this.props.maturity.contract.id, term.name);
        const productAndAttribute = this.getProductAndAttributeOnTerm(term, this.props.maturity);
        this.setState({
            selectedTerm: term,
            maturityDate: MaturityDateUtil.closestDate(new Date(), parseInt(hackTermDays(term.name))),
            selectedProduct: productAndAttribute[0],
            selectedAttribute: productAndAttribute[1],
            rolloverBank:true
        });
    }

    /**
     * Set maturity date.
     *
     * @param maturityDate
     */
    selectMaturityDate(maturityDate) {

        const term = this.state.attributes.find(it => MaturityDateUtil.getPossibleMaturityMoments(it.name)
                                                       .some(mom => mom.isSame(maturityDate, 'day')));

        if (!term) {
            return;
        }

        if (this.state.selectedTerm.name !== term.name) {
            this.selectTerm(term);
        }

        this.setState({maturityDate: maturityDate.toDate()});

    }

    selectProduct(product, attribute, primary=true) {
        this.setState({
            selectedProduct: product,
            selectedAttribute: attribute,
            rolloverBank: primary
        });
    }

    selectTermToRequest(term){
        let selectedTermsToRequest = this.state.rateRequestTerms;
        if(this.isTermSelectedToRequest(term)){
            selectedTermsToRequest = selectedTermsToRequest.filter(item => item !== term)
        }else{
            selectedTermsToRequest.push(term);
        }
        this.setState({rateRequestTerms:selectedTermsToRequest});
    }

    isTermSelectedToRequest(term){
        const selectedTermsToRequest = this.state.rateRequestTerms;
        return selectedTermsToRequest.find(el => el === term);
    }


    isEOISubmitFail() {
        return this.state.submitOk !== null && this.state.submitOk === false;
    }

    isEOISubmitSuccess() {
        return this.state.submitOk !== null && this.state.submitOk === true;
    }

    closeSuccessInformation() {
        const rolloverSameBank = this.state.selectedProduct.id === this.props.maturity.main_product.id;
        this.props.rolloverSuccessAction(rolloverSameBank,this.state.rateRequestSubmitted);
        this.setState({
            submitOk: null
        });
    }

    changeRolloverAmount(event) {
        try {
            this.setState({
                rolloverAmount: strip_num(event.target.value)
            });
        }
        catch (e) {}
    }

    changeRolloverOption(value) {
        let amount = +this.props.maturity.contract.consideration;
        if (value === 'consideration_plus_interest') {
            amount += +this.props.maturity.contract.interest
        } else if (value === 'other') {
            amount = 0;
        }
        this.setState({
            rolloverAmount: amount,
            rolloverOption: value
        });
    }

    handleLookingForSomethingElse() {
        DialogUtil.open(
            <MaturityLookingForSomethingElseDialog
                contract={this.props.maturity.contract}
                term={moment(this.state.maturityDate).startOf('day').diff(moment().startOf('day'), 'day')}
                amount={this.state.rolloverAmount !== 0 ? this.state.rolloverAmount: undefined}
            />).then(() => {
            DialogUtil.close();
            this.setState({sentLookingForSomethingElse: true});
        });
    }

    handleRedeem() {
        DialogUtil.open(
            <MaturityRedeemDialog
                contract={this.props.maturity.contract}
            />).then(() => {
            DialogUtil.close();
            this.props.redeemSuccessAction();
        });

    }
    /**
     * It returns an array where in the first position contains a product and in the second the attribute
     **/
    getProductAndAttributeOnTerm(term, maturity) {
        const primaryAttributes = maturity.main_product.attributes;
        let attribute = primaryAttributes.find(function(element) {return element.name === term.name;});
        let haveValue = attribute !== undefined && attribute.value !== '' && attribute.value !== undefined && attribute.value !== null
            && !isNaN(attribute.value.replace(/,/, '.')) && +attribute.value !== 0;
        if (haveValue) {
            return [maturity.main_product, attribute];
        } else {
            for (let i = 0; i < maturity.secondary_products.length; i++) {
                const product = maturity.secondary_products[i];
                attribute = product.attributes.find(function(element) {return element.name === term.name;});
                haveValue = attribute !== undefined && attribute.value !== '' && attribute.value !== undefined && attribute.value !== null
                    && !isNaN(attribute.value.replace(/,/, '.')) && +attribute.value !== 0;
                if (haveValue) {
                    return [product, attribute];
                }
            }
        }
        return undefined;
    }

    submitEOI() {
        if (this.state.rolloverAmount === 0) {
            return;
        }
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const eoi = {
            product_id: this.state.selectedProduct.id,
            product_attribute_id: this.state.selectedAttribute.product_attribute_id,
            amount: this.state.rolloverAmount,
            maturity_day_diff: moment(this.state.maturityDate).startOf('day').diff(moment().startOf('day').add(parseInt(this.state.selectedAttribute.name), 'days'), 'days'),
            additional_note: this.state.comments
        };
        this.setState({sending: true});
        EOIService.post(eoi)
            .then((response) => {
                this.setState({submitOk: true, sending: false, comments:''});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false, comments:''});
            });
    }

    requestRates(){
        const validationErrors = this.validateRequestRates();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const eoi = {
            product_id: this.state.selectedProduct.id,
            terms: this.state.rateRequestTerms,
            amount: this.state.rolloverAmount,
            additional_note: this.state.comments
        };
        this.setState({sending: true});
        MaturityService.requestRate(eoi)
            .then((response) => {
                this.setState({submitOk: true, sending: false, comments:'', rateRequestSubmitted: true});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false, comments:''});
            });

    }

    validateRequestRates() {
        const validationErrors = {};

        if (this.state.rateRequestTerms.length === 0 ) {
            validationErrors.term = 'Please select a term.';
        }
        this.setState({validationErrors});

        return validationErrors;
    }

    validate() {
        const validationErrors = {};

        if (isNaN(this.state.rolloverAmount)) {
            validationErrors.rolloverAmount = 'Must be a number.';
        }
        else if (this.state.rolloverAmount <= 0) {
            validationErrors.rolloverAmount = 'Must be greater than $ ' + formatAmount(this.props.product.min_deposit);
        }
        this.setState({validationErrors});

        return validationErrors;
    }

    renderTermsToRequestRates() {
        return <div className="select-terms">
            <button onClick={() => this.setState({selectTermsOpen: !this.state.selectTermsOpen})}>Request Rates</button>

            <CSSTransition in={this.state.selectTermsOpen} timeout={500} unmountOnExit>
                <Fragment>
                    <div className="term-options custom">
                        <button className={this.isTermSelectedToRequest("1-12 mth") ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest("1-12 mth")}>1-12 mth
                        </button>
                        <button className={this.isTermSelectedToRequest("1yr and greater") ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest("1yr and greater")}>1YR AND GREATER
                        </button>
                    </div>
                    <div className="term-options">
                        <button className={this.isTermSelectedToRequest(30) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(30)}>1 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(60) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(60)}>2 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(90) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(90)}>3 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(120) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(120)}>4 yr
                        </button>
                        <button className={this.isTermSelectedToRequest(150) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(150)}>5 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(180) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(180)}>6 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(210) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(210)}>7 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(240) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(240)}>8 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(270) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(270)}>9 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(300) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(300)}>10 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(330) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(330)}>11 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(360) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(360)}>1 yr
                        </button>
                        <button className={this.isTermSelectedToRequest(540) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(540)}>18 mth
                        </button>
                        <button className={this.isTermSelectedToRequest(720) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(720)}>2 yr
                        </button>
                        <button className={this.isTermSelectedToRequest(1080) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(1080)}>3 yr
                        </button>
                        <button className={this.isTermSelectedToRequest(1440) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(1440)}>4 yr
                        </button>
                        <button className={this.isTermSelectedToRequest(1800) ? "active select-btn" : "select-btn"}
                                onClick={() => this.selectTermToRequest(1800)}>5 yr
                        </button>
                    </div>
                </Fragment>
            </CSSTransition>
        </div>;
    }

    renderLookingForSomethingElse() {
        if (this.state.selectedTerm === undefined) {
            return;
        }
        return (
            <div className="looking-something-else">
                <button className="button-link" onClick={() => this.handleLookingForSomethingElse()}>Looking for something else?</button>
                {this.state.sentLookingForSomethingElse &&
                    <p>Your query has been sent, a Curve representative will be in touch shortly.</p>
                }
            </div>
        )
    }

    renderTermProductAlternatives() {
        if (this.state.selectedTerm === undefined) {
            if(!this.state.showLiveRate){
                return (
                        "Rates have not been set by " + this.props.maturity.main_product.bank.name+ " yet. "+
                        "If you're looking to roll for specific terms please 'Request Rates' and will " +
                        "chase them up!"
                )
            }
            return;
        }
        if (this.state.selectedTerm.secondary_products_attributes.length === 0) {
            return (
                <Fragment>
                    {this.renderBankInformation(this.state.selectedTerm.primary_product_attribute.product,
                        this.state.selectedTerm.primary_product_attribute.attribute, true)}
                </Fragment>
            )
        } else if (this.state.selectedTerm.primary_product_attribute === undefined) {
            return (
                <Fragment>
                    <p className="select-alternative-message">
                        {this.props.maturity.main_product.bank.name} is unable to provide a rate for this term.
                    </p>
                    {this.state.selectedTerm.secondary_products_attributes.map((productAttribute) => {
                        return this.renderBankInformation(productAttribute.product, productAttribute.attribute, false);
                    })}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    {this.renderBankInformation(this.state.selectedTerm.primary_product_attribute.product,
                        this.state.selectedTerm.primary_product_attribute.attribute, true)}
                    {this.state.selectedTerm.secondary_products_attributes.map((productAttribute) => {
                        return this.renderBankInformation(productAttribute.product, productAttribute.attribute, false);
                    })}
                </Fragment>
            )
        }
    }

    renderBankInformation(product, term, primary) {
        const bank = product.bank;
        const primaryBankImageUrl = bank && getBankImageUrl(bank.name, bank.logo);
        const selected = this.state.selectedProduct !== undefined && this.state.selectedProduct.id === product.id;
        return (
            <div key={product.id+"_"+term} className={selected ? 'bank-container selected' : 'bank-container'}
                 onClick={() => this.selectProduct(product, term, primary)} >
                <p>
                    {primary ? ('Rollover with the same bank') : ('Switch to another bank')}
                </p>
                <div className="bank-data">
                    <div className="icons">

                        
                        {bank.fossil_fuel_free === FOSSIL_FUEL_FREE.FOSSIL_FUEL_FREE &&
                        <div className="fossil-fuel-free" tooltip="Fossil Fuel Free – See 'About' for more info"
                             flow="right"/>
                        }
                        {bank.financial_scheme === FINANCIAL_SCHEME.ADI_BRANCH_OF_FOREIGN_BANK &&
                        <div className="branch-foreign-bank" tooltip="Branch of a Foreign Bank – See 'About' for more info"
                             flow="right"/>
                        }
                    </div>
                    <div className="img-container">
                        <div className="img">
                            <BankImage bankImageUrl={primaryBankImageUrl} bankName={bank.name}/>
                        </div>
                        <div className="rating">
                            {getFullBankRating(product.bank)}
                        </div>
                    </div>
                    <div className="rate">
                        <div>{this.renderAttributeRate(term)}</div>
                    </div>
                </div>
            </div>
        )
    }

    renderAttributeRate(attribute) {
        if (attribute.variable_margin !== null) {
            return (
                <Fragment>
                    <span className="variable-margin">{"+" + attribute.variable_margin.margin}</span>
                    <span className="rate-value">{formatRate(attribute.value)}</span>
                </Fragment>


            )
        } else {
            return (
                <span className="rate-value">{formatRate(attribute.value)}</span>
            )
        }
    }

    renderSelectTerm() {
        if(!this.props.showLiveRate){
            return;
        }else{
            return (
                <div className="select-term">
                    <table className="sub-terms">
                        <tbody>
                            <tr>
                                {this.state.attributes.map(attribute => {
                                    return <td key={attribute.name} onClick={() => this.selectTerm(attribute)} className={attribute === this.state.selectedTerm ? "selected" : undefined}>
                                        <div className="term-rate-div">
                                            <p className="term">{formatTermName(attribute.name)}</p>
                                        </div>
                                        <div className="term-rate-div">
                                            <p className={'rate' + (attribute.primary_value === null ? ' switch' : '')}>
                                                {attribute.primary_value !== null ? this.renderAttributeRate(attribute.primary_product_attribute.attribute) : "SWITCH"}
                                            </p>
                                        </div>

                                    </td>
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    renderMaturityForm() {
        return (
            <Fragment>
                <div className="product-details maturity">
                    <div className="maturity-container">
                        <div className="contract-data">
                            <div className="description">
                                <div className="description-title"><span>From Maturity</span></div>
                                <div className="description-item">
                                    <span>Product:</span>
                                    {this.props.maturity.contract.product_type === PRODUCT_TYPES.TERM_DEPOSIT ? 'Term Deposit' : 'NCD' }
                                </div>
                                <div className="description-item">
                                    <span>Maturity Date:</span> {DateUtil.formatDate(this.props.maturity.contract.maturity_date, 'DD/MM/YYYY')}
                                </div>
                                {this.props.maturity.contract.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT &&
                                <div className="description-item">
                                    <span>Face value:</span> ${formatAmount(this.props.maturity.contract.face_value)}
                                </div>
                                }
                                <div className="description-item">
                                    <span>Consideration:</span> ${formatAmount(this.props.maturity.contract.consideration)}
                                </div>
                                <div className="description-item">
                                    <span>Interest:</span> ${formatAmount(this.props.maturity.contract.interest)}
                                </div>
                                <div className="description-item">
                                    <span>Term:</span> {this.props.maturity.contract.term}
                                </div>
                                <div className="description-item">
                                    <span>Previous Yield:</span> {formatRate(this.props.maturity.contract.yield)}
                                </div>
                                {this.props.maturity.contract.additional_comments &&
                                    <div className="description-item">
                                        <span>Additional Comments:</span> 
                                        <div className="additional-comments">
                                            {this.props.maturity.contract.additional_comments}
                                        </div>

                                    </div>
                                }
                                {this.props.maturity.contract.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT &&
                                    <div className="description-item">
                                        <p className="estimated-rate-description">See margin - yields dependant on benchmark update</p>
                                    </div>
                                }
                            </div>
                            <div className="redeem">
                                <button onClick={() => this.handleRedeem()}>Redeem funds</button>
                                {this.state.redeem.sent && this.state.redeem.success &&
                                    <div>Instructions have been sent and the redemption will be processed shortly.</div>
                                }
                                {this.state.redeem.sent && !this.state.redeem.success &&
                                <div>There was an error, please try again later.</div>
                                }
                            </div>
                        </div>
                        <div className="product-alternatives">
                            <div className="term-product-alternatives">
                                {this.renderTermProductAlternatives()}
                            </div>
                            {this.renderLookingForSomethingElse()}
                        </div>
                    </div>
                    <div className="disclaimer">
                        <p>
                            Rates are indicative at the time of publishing and are subject to change. Deals are
                            subject to confirmation by a Curve representative.
                        </p>
                        <p>
                            Please note break fees may apply for the early redemption or sale of this investment.
                            Some term deposits may be unbreakable or have a notice period required, please refer
                            to the terms and conditions. A copy of the terms and conditions relating to this
                            deposit is available upon request.
                        </p>
                    </div>
                </div>
                <div className="eoi-select-amount">
                    {this.renderRolloverDataFragment()}
                </div>
            </Fragment>
        )
    }

    renderRolloverDataFragment() {
        if (this.state.selectedProduct === undefined && this.state.showLiveRate ) {
            return (
                <div className="select-term"><p>Roll / Switch: Please select a term from above to continue.</p></div>
            )
        } else {
            return (
                <Fragment>
                    {this.state.sending && <div className="eoi-sending"><Spinner/></div>}
                    <div className="eoi-data rollover-form">
                        {this.isEOISubmitFail() &&
                        <div className="error-message">An error occurs, please try again later</div>
                        }
                        {this.isEOISubmitSuccess() ?
                            this.renderSuccessSubmit()
                            :
                            (this.state.showLiveRate && !this.state.selectedTerm)?this.renderSelectTermDialog():this.renderRolloverForm()
                        }
                    </div>
                </Fragment>
            )
        }
    }

    renderSuccessSubmit() {
        return (
            <div className="success-eoi-container">
                <h3>Sent message</h3>
                <p>Thank you - A Curve team member will be in touch with you shortly.</p>
                <div className="button-container">
                    <button className="accept-btn" onClick={() => this.closeSuccessInformation()}>DONE</button>
                </div>
            </div>
        );
    }

    renderSelectTermDialog(){
        return(
            <div className='select-term-dialog'>
                <h2>Please select terms</h2>
            </div>
        );
    }

    shouldRenderCommentsInput() {
        if (this.state.showLiveRate) {
            return true;
        } else {
            return this.state.rateRequestTerms.length > 0
        }
    }

    renderRolloverForm() {
        return (
            <Fragment>
                <div className="rollover-form-data">
                    <div className="eoi-title">Rollover</div>
                    <div className="rollover-options">

                        {this.props.maturity.contract.product_type === PRODUCT_TYPES.TERM_DEPOSIT &&
                        <Fragment>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="rollover_option" value="consideration"
                                           onChange={event => this.changeRolloverOption(event.target.value)}/>
                                    <div className="rollover-label">Principal:</div>
                                    <div className="rollover-value">${formatAmount(this.props.maturity.contract.consideration)}</div>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="rollover_option" value="consideration_plus_interest"
                                           onChange={event => this.changeRolloverOption(event.target.value)}/>
                                    <div className="rollover-label">Principal + interest:</div>
                                    <div className="rollover-value">
                                        ${formatAmount(+this.props.maturity.contract.consideration + +this.props.maturity.contract.interest)}
                                    </div>
                                </label>
                            </div>
                        </Fragment>
                        }
                        {this.props.maturity.contract.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT &&
                        <div className="radio">
                            <label>
                                <input type="radio" name="rollover_option" value="consideration"
                                       onChange={event => this.changeRolloverOption(event.target.value)}/>
                                <div className="rollover-label">Principal:</div>
                                <div className="rollover-value">${formatAmount(this.props.maturity.contract.face_value)}</div>
                            </label>
                        </div>
                        }
                        <div className="radio">
                            <label>
                                <input type="radio" name="rollover_option" value="other"
                                       onChange={event => this.changeRolloverOption(event.target.value)}
                                       checked={this.state.rolloverOption === "other"}
                                />
                                <div className="rollover-label">Other:</div>
                                <div className="rollover-value">
                                    $<input type="text"
                                            value={this.state.rolloverOption === 'other' ? format_num(this.state.rolloverAmount) : 0}
                                            onChange={event => this.changeRolloverAmount(event)}
                                            onClick={() => this.changeRolloverOption("other")}
                                />
                                    <div className="validation-errors">{this.state.validationErrors.rolloverAmount}</div>
                                </div>
                            </label>
                        </div>
                    </div>
                    {this.state.showLiveRate &&
                    <div className="maturity-date">
                        <label>
                            Maturity Date:
                            <MaturityDatePicker value={moment(this.state.maturityDate)}
                                                onChange={this.selectMaturityDate}
                                                productCardTerms={this.state.attributes}
                                                colorIcon="red" />
                        </label>
                    </div>
                    }
                    {!this.state.showLiveRate &&
                        this.renderTermsToRequestRates()
                    }
                    { this.shouldRenderCommentsInput() &&
                        <div className="comments">
                            <label>
                                Comments:
                            </label>
                            <div>
                              <textarea className="width-100per-minus-100px margin-top-10px margin-right-50px margin-bottom-10px height-60px background-color-grey6"
                                        onChange={e => this.setState({comments: e.target.value})}
                                        value={this.state.comments} />
                            </div>
                        </div>
                    }
                </div>
                <div className="text-align-center eoi-btn">
                    {this.state.showLiveRate &&
                    <Fragment>
                        <input type="submit"
                               className={this.state.rolloverAmount == 0 ? 'disabled rollover-btn' : "rollover-btn"}
                               onClick={event => this.submitEOI(event)}
                               value= {this.state.rolloverBank ? ('Rollover') : ('Switch')} />
                        <div className="disable-eoi-message">Please enter an amount</div>
                    </Fragment>
                    }
                    {!this.state.showLiveRate && this.state.rateRequestTerms.length > 0 &&
                    <Fragment>
                        <input type="submit"
                               className={(this.state.rateRequestTerms.length === 0) ? 'disabled rollover-btn' : "rollover-btn"}
                               onClick={event => this.requestRates(event)}
                               value="Submit"/>
                        <div className="disable-eoi-message">Please select a term</div>
                    </Fragment>
                    }

                </div>
            </Fragment>
        )
        }

    render() {
        return (
            <div className="curves-eoi" style={{overflow: this.state.overflow}}>
                {this.renderSelectTerm()}
                <div className="eoi-container">
                    <div className="eoi-row">
                        {this.renderMaturityForm()}
                    </div>
                </div>
            </div>
        )
    }

}

const hackTermDays = days => {
    const transforms = [
        {x: 360, y: 365},
        {x: 360 * 2, y: 365 * 2},
        {x: 360 * 3, y: 365 * 3},
        {x: 360 * 4, y: 365 * 4},
        {x: 360 * 5, y: 365 * 5}
    ];

    const transform = transforms.find(it => it.x.toString() === days);

    return transform ? transform.y.toString() : days;
};


export default MaturityEOI;
