import React, {Component} from 'react';
import EOIForm from "./EOIForm";
import {withRouter} from "react-router-dom";
import {abbreviateNumber, formatRate} from "../../common/formatUtil";
import {
    getBankRatingTooltipMessage,
    getBankRating
} from "../../common/bankRatingUtil";
import {getBankImageUrl} from "../../common/bankImageUtil";
import SemiCircleProgress from "../common/SemiCircleProgress";
import DateUtil from "../../common/DateUtil";
import BankImage from "./BankImage";
import {CSSTransition} from "react-transition-group";
import {SPECIAL_STATUS_TYPES} from "../../common/types/specialStatusTypes";
import CountUp from "react-countup/build/index";
import MaturityDateUtil from "../../common/MaturityDateUtil";

class SpecialCard extends Component {


    constructor(props) {
        super(props);

        this.selectTerm = this.selectTerm.bind(this);
        this.timeLeft = this.timeLeft.bind(this);
        this.timeLeftAngle = this.timeLeftAngle.bind(this);
        this.attributesForSpecial = this.attributesForSpecial.bind(this);
        this.selectMaturityDate = this.selectMaturityDate.bind(this);
        this.closeEOIForm = this.closeEOIForm.bind(this);
        this.state = {
            open: this.props.product.id === this.props.openProductId,
            maturityDate: this.attributesForSpecial().length === 1 ?
                            MaturityDateUtil.closestMoment(this.attributesForSpecial()[0].name).toDate()
                            : null
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.openProductId !== prevProps.openProductId) {
            this.setState({open: this.props.product.id === this.props.openProductId });
        }
    }

    selectTerm(productId) {
        if (this.props.openProductId === productId) {
            this.props.expressInterest(null);
        } else {
            this.props.expressInterest(productId);
        }
    }

    isEOIOpened() {
        return this.state.open;
    }

    timeLeft() {
        const minutesLeft = DateUtil.diffInMinutes(this.props.product.special_info.end_datetime, new Date());

        return Math.floor(minutesLeft / 60) + 'h ' +
            (Math.floor(minutesLeft % 60) > 0 ?
            Math.floor(minutesLeft % 60) + 'm' : '')

    }

    timeLeftAngle() {
        const minutesLeft = DateUtil.diffInMinutes(this.props.product.special_info.end_datetime, new Date());

        return (minutesLeft > 0 ?
            (1 - minutesLeft / 720)
            : 1)
            * 180;
    }

    attributesForSpecial() {
        if (!this.props.product.attributes) {
            return [];
        }

        return this.props.product.attributes.filter(it => it.value !== null).filter(it => it.value.trim())
            .sort((a, b) => parseInt(a.name) - parseInt(b.name));
    }

    renderDropDownRates() {
        return (
            <svg>
                <use xlinkHref={this.isEOIOpened() ? '#curves-svg-triangle-up' : '#curves-svg-triangle-down'}></use>
            </svg>
        )
    }

    /**
     * Select maturity date in date picker.
     *
     * @param maturityDate
     */
    selectMaturityDate(maturityDate) {
        const term = this.attributesForSpecial().find(it => MaturityDateUtil.getPossibleMaturityMoments(it.name)
                                                    .some(mom => mom.isSame(maturityDate, 'day')));

        if (!term) {
            return;
        }

        this.setState({maturityDate: maturityDate.toDate()});
    }

    closeEOIForm() {
        this.props.expressInterest(null);
    }

    render() {
        const ratesToShow = this.attributesForSpecial();
        const bankImageUrl = this.props.product.bank && getBankImageUrl(this.props.product.bank.name, this.props.product.bank.logo);

        return (
            <div id={"product-" + this.props.product.id} className={'curves-product-card special live-rate ' +
            (this.state.open ? ' open-product' : '') +
            (ratesToShow.length > 9 ? ' small-font' : '')}
            >
                {this.props.product.special_info.status === SPECIAL_STATUS_TYPES.CLOSED &&
                    <div className="card-overlay">
                        <div className="hover-text">
                            <div><h2>Offer closed</h2> </div>
                        </div>
                    </div>
                }
                <div className="main" onClick={() => this.selectTerm(this.props.product.id)}>
                    <div className="bank-column">
                        <div className={"img" + (this.props.product.special_info.show_name ? '' : ' hide')}>
                            <BankImage bankImageUrl={bankImageUrl} bankName={this.props.product.bank.name}/>
                        </div>
                        {this.props.product.bank.short_rating && this.props.product.bank.long_rating &&
                            <div className="rating" tooltip={getBankRatingTooltipMessage()} flow="right">
                                {getBankRating(this.props.product.bank)}
                            </div>
                        }
                    </div>

                    <div className="rates-column">
                        <div className="best-rate rates">
                            <div className={this.isEOIOpened() ? "tab-item opened" : "tab-item"}>
                                <p className="rate">
                                    {formatRate(this.attributesForSpecial().map(it => it.value).reduce(((it, acc) => it > acc ? it : acc), 0))}
                                    <span className="special-arrow">{this.renderDropDownRates()}</span>
                                </p>

                            </div>
                        </div>
                    </div>

                        {this.props.product.special_info.status === SPECIAL_STATUS_TYPES.CLOSED && (this.props.product.special_info.filled_in_hours > 0 ||
                        this.props.product.special_info.filled_in_minutes > 0 ) &&
                        <div className="filled-in">
                            <div>
                                <h2>Offer Filled in:</h2>
                            </div>
                            <div>
                                <CountUp
                                    end={this.props.product.special_info.filled_in_hours}
                                    separator=","
                                    suffix="h "
                                />
                                <CountUp
                                    end={this.props.product.special_info.filled_in_minutes}
                                    separator=","
                                    suffix="m"
                                />
                            </div>
                        </div>
                        }
                        {this.props.product.special_info.status === SPECIAL_STATUS_TYPES.ACTIVE &&
                        <div className="time-left">
                            <SemiCircleProgress fillAngle={this.timeLeftAngle()}>
                                <div>{this.timeLeft()}</div>
                                <div className="small-font">LEFT</div>
                            </SemiCircleProgress>
                        </div>
                        }


                    <div className="amount-raised">
                        <SemiCircleProgress fillAngle={this.props.product.special_info.raised_amount / this.props.product.special_info.target_amount * 180}>
                            <div className="small-font">AVAILABLE</div>
                            <div>
                                {abbreviateNumber(this.props.product.special_info.target_amount-this.props.product.special_info.raised_amount)}
                                <span className="small-font"> OF </span>
                                {abbreviateNumber(this.props.product.special_info.target_amount)}
                            </div>
                        </SemiCircleProgress>
                    </div>

                </div>
                {this.props.product.special_info.status === SPECIAL_STATUS_TYPES.ACTIVE &&
                <CSSTransition in={this.state.open} timeout={1000} classNames="eoi-node" unmountOnExit>
                    <EOIForm product={this.props.product}
                             term={{attributes: this.attributesForSpecial()}}
                             productCardTerms={this.attributesForSpecial()}
                             maturityDate={this.state.maturityDate}
                             selectMaturityDate={this.selectMaturityDate}
                             closeEOIForm={this.closeEOIForm}/>
                </CSSTransition>
                }
            </div>
        )
    }
}

export default withRouter(SpecialCard);
