import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {bestRates, groupTerms, groupGroupedTerms, groupTermsForListView} from "../../common/rateUtil";
import {groupBankBillSwaps} from "../../common/bankBillSwapUtil";
import GlobalStore from "../store/GlobalStore";
import QueryParams from "../../common/QueryParams";
import {PRODUCT_TYPES} from "../../common/types/productTypes";
import termLabel, {formatTermName} from "../../common/termLabel";
import {formatRate, formatRate4} from "../../common/formatUtil";

const displayBBSWRate = (bbsw, termName, productType) => {
    const element = bbsw.find(function(element) {
        return element.name === termName;
    });
    if (element === undefined) {
        return <p className="rate">-</p>;
    }
    let rates = "";
    if (element.attributes.length > 1) {
        rates = <Fragment>
            {element.attributes.map((element, idx) => <p key={idx} >{formatTermName(element.benchmarkTerm)}: {productType === 'ncd' ? formatRate4(element.value) : formatRate(element.value)}</p>)}
        </Fragment>;
    }
    return <div className="rate">
        {rates !== "" ? <div className="tooltip">{rates}</div> : null}
        {productType === 'ncd' ? formatRate4(element.value) : formatRate(element.value)}
    </div>
};

class MaxYieldsForListView extends Component  {

    constructor(props) {
        super(props);
        this.listenScrollEvent = this.listenScrollEvent.bind(this);
        this.renderAvailabilitySorter = this.renderAvailabilitySorter.bind(this);
    }

    listenScrollEvent(){
        //console.log('Scroll event MAXYIELDS 2 detected! Scroll: '+this.props.globalStore.productScroll);
        //console.log('Scroll value: '+this.refs.maxYields.scrollLeft);
        if(this.refs.maxYields.scrollLeft!=this.props.globalStore.productScroll){
            this.props.handleScroll(this.refs.maxYields.scrollLeft);
        }
    }

    componentDidUpdate(){
        this.refs.maxYields.scrollLeft = this.props.globalStore.productScroll;
        //console.log("max yields scroll left "+this.refs.maxYields.scrollLeft);
    }

    renderAvailabilitySorter(isNCD, cardView) {
        if (isNCD && !cardView) {
            const isSortedByAvailability = QueryParams.get(this.props, 'sortAvailability') !== undefined;
            return (
                <div className="availability-sort" tooltip="Show NCDs currently available first" flow="right">
                    <div className={isSortedByAvailability ? "traffic-light-box active" : "traffic-light-box"} onClick={() => {
                        if (!isSortedByAvailability) {
                            const sortAvailability = "true";
                            QueryParams.change(this.props, { sortAvailability, order: undefined, sort: undefined });
                        } else {
                            QueryParams.change(this.props, {sortAvailability: undefined});
                        }
                    }}>
                        <div className="traffic-light green-light"></div>
                        <div className="traffic-light yellow-light"></div>
                        <div className="traffic-light red-light"></div>
                    </div>
                </div>
            )
        }
    }
    // Functions that are in MaxYieldCell needed to show the end cells
    sortedColumnClassName(name) {
        return QueryParams.get(this.props, 'sort') === name ? ('sorted ' +
            QueryParams.get(this.props, 'order')) : 'unsorted';
    }

    render() {
        let maxYields = groupTermsForListView(
            bestRates(this.props.products, this.props.includeStringTerms, this.props.location, false, false)
        );
        if(this.props.location.search.includes("filterTerm")){
            // if filtering by term, we remove the expansion capabilities
            // Filter out the 6-8 and 9-11
            maxYields = maxYields.filter(term => !term.dontShowOnCardView);
        }
        let bbsw = groupBankBillSwaps(this.props.bankBillSwaps, this.props.location, false);
        const isNCD = this.props.products[0]?(this.props.products[0].product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT):false;
        const className = ("curves-max-yields "+"table-view ")+(isNCD?"ncd":"");
        var filler1stColumn = <div className="rate-group-for-list-view" style={{width: '109px', minWidth: '100px'}}></div>;
        var filler2ndColumn = <div className="rate-group-for-list-view"></div>;
        return (
            <div className={className} onScroll={this.listenScrollEvent} ref="maxYields" >
                <div className="curves-max-yields-inner-container">
                    <div className="title">
                        <div className="term">
                            DURATION
                        </div>
                        <div className="rate">
                            BENCHMARK
                        </div>
                        <div className="rate">
                            MAX YIELD
                        </div>
                    </div>
                    <div className="rates">
                        {filler1stColumn}
                        {filler2ndColumn}
                        {this.renderAvailabilitySorter(isNCD,false)}
                        {maxYields && maxYields.length > 0 && maxYields.map((it, idx) => {
                            return (
                                <div className={'rate-group-for-list-view '}>
                                    <div onClick={() => this.changeOrder(it.name)}
                                         className={'group ' + this.sortedColumnClassName(it.name)}>
                                        <p className="term">
                                            {termLabel(it.name)}
                                        </p>
                                        {displayBBSWRate(bbsw, it.name, QueryParams.getUrlChunk(this.props, -1))}
                                        <p className="rate">{formatRate(it.name)}</p>
                                    </div>
                                </div>
                            )})
                        }
                        {/* Hack for Last update column */}
                        <div className="rate-group"></div>
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(GlobalStore.connect(MaxYieldsForListView), MaxYieldsForListView);
