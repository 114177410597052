import React from 'react';

const SvgImages = () => {

    return (
        <div style={{display: 'none'}}>
            <svg id="curves-svg-close" version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 100">
                <line stroke="currentColor" strokeWidth="20" x1="10" y1="10" x2="90" y2="90" strokeLinecap="round"/>
                <line stroke="currentColor" strokeWidth="20" x1="10" y1="90" x2="90" y2="10" strokeLinecap="round"/>
            </svg>
            <svg id="curves-svg-info" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 32 32" style={{enableBackground: "new 0 0 32 32"}} xmlSpace="preserve">
                <path d="M17.962,24.725l1.806,0.096v2.531h-7.534v-2.406l1.045-0.094c0.568-0.063,0.916-0.254,0.916-1.014v-8.801
                    c0-0.699-0.188-0.92-0.791-0.92l-1.106-0.062v-2.626h5.666L17.962,24.725L17.962,24.725z M15.747,4.648
                    c1.394,0,2.405,1.047,2.405,2.374c0,1.331-1.014,2.313-2.438,2.313c-1.454,0-2.404-0.982-2.404-2.313
                    C13.31,5.695,14.26,4.648,15.747,4.648z M16,32C7.178,32,0,24.822,0,16S7.178,0,16,0c8.82,0,16,7.178,16,16S24.82,32,16,32z M16,3
                    C8.832,3,3,8.832,3,16s5.832,13,13,13s13-5.832,13-13S23.168,3,16,3z"/>
            </svg>
            <svg id="curves-svg-minus" version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50">
                <line stroke="currentColor" strokeWidth="10" x1="10" y1="25" x2="40" y2="25" strokeLinecap="round"/>
            </svg>
            <svg id="curves-svg-plus" version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50">
                <line stroke="currentColor" strokeWidth="10" x1="10" y1="25" x2="40" y2="25" strokeLinecap="round"/>
                <line stroke="currentColor" strokeWidth="10" x1="25" y1="10" x2="25" y2="40" strokeLinecap="round"/>
            </svg>
            <svg id="curves-svg-arrow-down" version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 80 50">
                <line stroke="currentColor" strokeWidth="20" x1="10" y1="10" x2="40" y2="40" strokeLinecap="round"/>
                <line stroke="currentColor" strokeWidth="20" x1="40" y1="40" x2="70" y2="10" strokeLinecap="round"/>
            </svg>
            <svg id="curves-svg-arrow-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.05 28.32">
            	<g id="Layer_2" data-name="Layer 2">
            	<g id="Layer_1-2" data-name="Layer 1"><g id="Milestone">
            		<polyline fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" points="34.55 26.82 18.03 1.5 1.5 26.82"/>
            	</g></g></g>
            </svg>
            <svg id="curves-svg-triangle-down" version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 10.27 5.13">
            	<g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
            	<g id="Milestone"><polyline points="10.27 0 5.13 5.13 0 0"/></g></g></g>
            </svg>
            <svg id="curves-svg-triangle-up" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.27 5.13">
            	<g id="Layer_1-2" data-name="Layer 1">
            	<g id="Milestone"><polyline points="0 5.13 5.13 0 10.27 5.13"/></g></g>
            </svg>

            <svg id="curves-svg-filters" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.05 32.8">
            	<g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Milestone">
            		<polygon fill="none" stroke="#dd5447" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" points="1.5 1.5 34.55 1.5 18.03 17.63 1.5 1.5"/>
            		<line fill="none" stroke="#dd5447" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="18.03" y1="17.63" x2="18.03" y2="31.3"/>
            	</g></g></g>
            </svg>

            <svg id="curves-svg-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.1 29.34">
            	<g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Milestone">
            		<line fill="none" stroke="#54855a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="29.6" y1="1.5" x2="1.5" y2="1.5"/>
            		<line fill="none" stroke="#54855a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="29.6" y1="14.67" x2="1.5" y2="14.67"/>
            		<line fill="none" stroke="#54855a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="29.6" y1="27.84" x2="1.5" y2="27.84"/>
            	</g></g></g>
            </svg>

            <svg id="curves-svg-user" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.89 33.42">
            	<g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Milestone"><g className="cls-1" opacity="0.31" >
            		<circle fill="none" stroke="#28393f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" cx="15.94" cy="11.5" r="10"/>
            		<path fill="none" stroke="#28393f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M30.39,31.92c0-5.17-6.47-9.35-14.45-9.35S1.5,26.75,1.5,31.92"/>
            	</g></g></g></g>
            </svg>

            <svg id="curves-svg-long-arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.61 10.73">
            	<g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Milestone">
            		<line className="cls-1" fill="none" stroke="#2c8656" strokeMiterlimit="10" x1="3.61" x2="3.61" y2="6"/>
            		<polygon className="cls-2" fill="#2c8656" points="0 5 3.31 10.73 6.61 5 0 5"/>
            	</g></g></g>
            </svg>


            <svg id="curves-svg-clock" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 612 612" style={{enableBackground: "new 0 0 612 612"}}>
              <g>
                <path d="M587.572,186.881c-32.266-75.225-87.096-129.934-162.949-162.285C386.711,8.427,346.992,0.168,305.497,0.168
                    c-41.488,0-80.914,8.181-118.784,24.428C111.488,56.861,56.415,111.535,24.092,186.881C7.895,224.629,0,264.176,0,305.664
                    c0,41.496,7.895,81.371,24.092,119.127c32.323,75.346,87.403,130.348,162.621,162.621c37.877,16.247,77.295,24.42,118.784,24.42
                    c41.489,0,81.214-8.259,119.12-24.42c75.853-32.352,130.683-87.403,162.956-162.621C603.819,386.914,612,347.16,612,305.664
                    C612,264.176,603.826,224.757,587.572,186.881z M538.724,440.853c-24.021,41.195-56.929,73.876-98.375,98.039
                    c-41.195,24.021-86.332,36.135-134.845,36.135c-36.47,0-71.27-7.024-104.4-21.415c-33.129-14.384-61.733-33.294-85.661-57.215
                    c-23.928-23.928-42.973-52.811-57.214-85.997c-14.199-33.065-21.08-68.258-21.08-104.735c0-48.52,11.921-93.428,35.807-134.509
                    c23.971-41.231,56.886-73.947,98.039-98.04c41.146-24.092,85.99-36.142,134.502-36.142c48.52,0,93.649,12.121,134.845,36.142
                    c41.446,24.164,74.283,56.879,98.375,98.039c24.092,41.153,36.135,85.99,36.135,134.509
                    C574.852,354.185,562.888,399.399,538.724,440.853z"/>
                <path d="M324.906,302.988V129.659c0-10.372-9.037-18.738-19.41-18.738c-9.701,0-18.403,8.366-18.403,18.738v176.005
                    c0,0.336,0.671,1.678,0.671,2.678c-0.671,6.024,1.007,11.043,5.019,15.062l100.053,100.046c6.695,6.695,19.073,6.695,25.763,0
                    c7.694-7.695,7.188-18.86,0-26.099L324.906,302.988z"/>
              </g>
            </svg>
            <svg id="curves-svg-chat-offline" version="1.1"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 96 96" style={{fill:'#ffffff'}}>
                <g>
                    <path className="st0" d="M50.3,28.7H21.8c-3.5,0-6.3,2.8-6.3,6.3v28.3c0,3.5,2.8,6.3,6.3,6.3h3.1v12.6l16.7-12.6h20.9
                        c3.5,0,6.3-2.8,6.3-6.3V44.4C59.5,44.4,51.8,37.6,50.3,28.7z"/>
                    <polygon id="XMLID_4_" className="st0" points="80.5,18.4 76.1,13.9 68.9,21.1 61.7,13.9 57.2,18.4 64.4,25.6 57.2,32.8 61.7,37.2
                        68.9,30 76.1,37.2 80.5,32.8 73.3,25.6 	"/>
                </g>
            </svg>

            <svg id="curves-svg-chat-online" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 96 96" style={{fill:'#ffffff'}}>
                <g id="XMLID_7_">
                    <path id="XMLID_13_" className="st0" d="M71.3,18.8H24.7c-3.2,0-5.8,2.6-5.8,5.8l0,52.5l11.7-11.7h40.8c3.2,0,5.8-2.6,5.8-5.8v-35
                        C77.2,21.4,74.6,18.8,71.3,18.8z M30.5,39.3h35v5.8h-35V39.3z M53.8,53.8H30.5V48h23.3V53.8z M65.5,36.3h-35v-5.8h35V36.3z"/>
                </g>
            </svg>
        </div>
    );

};

export default SvgImages;
