const TERM_LABELS = [
    {term: 30, label: '1 mth'},
    {term: 30 * 2, label: '2 mth'},
    {term: 30 * 3, label: '3 mth'},
    {term: 30 * 4, label: '4 mth'},
    {term: 30 * 5, label: '5 mth'},
    {term: 30 * 6, label: '6 mth'},
    {term: 30 * 7, label: '7 mth'},
    {term: 30 * 8, label: '8 mth'},
    {term: 30 * 9, label: '9 mth'},
    {term: 30 * 10, label: '10 mth'},
    {term: 30 * 11, label: '11 mth'},
    {term: 30 * 12, label: '1 yr'},
    {term: 30 * 18, label: '18 mth'},
    {term: 30 * 12 * 2, label: '2 yr'},
    {term: 30 * 12 * 3, label: '3 yr'},
    {term: 30 * 12 * 4, label: '4 yr'},
    {term: 30 * 12 * 5, label: '5 yr'}
];

export default term => {
    const termFound = TERM_LABELS.find(it => it.term === parseInt(term));

    return termFound ? termFound.label : term;
};

export const formatTermName = (termName,longName = false) => {
    if (isNaN(termName))
        return termName;
    const termDays = parseInt(termName);
    if (termDays >= 360) {
        if (termDays % 360 === 0) {
            const year = (termDays / 360);
            return year + (longName?(year>1?' Years':' Year'):' yr');
        }
    }
    if (termDays % 30 === 0) {
        const month = (termDays / 30);
        return month + (longName?(month>1?' Months':' Month'):' mth');
    }
    return termName + " d";
};

