import React from 'react';

const changeRange = (valueType, newValue, currentValues, min, max, onChangeRange) => {

    const MIN_DELTA = 1;

    let lowerValue = valueType === 'UPPER' ? parseInt(currentValues[0]) : parseInt(newValue);
    let upperValue = valueType === 'UPPER' ? parseInt(newValue) : parseInt(currentValues[1]);

    lowerValue = lowerValue < (parseInt(max) - MIN_DELTA) ? lowerValue : (parseInt(max) - MIN_DELTA);
    upperValue = upperValue > (parseInt(min) + MIN_DELTA) ? upperValue : (parseInt(min) + MIN_DELTA);

    if (lowerValue + MIN_DELTA > upperValue) {
        if (valueType === 'UPPER') {
            lowerValue = upperValue - MIN_DELTA;
        }
        else { // valueType === 'LOWER'
            upperValue = lowerValue + MIN_DELTA;
        }
    }

    onChangeRange(lowerValue, upperValue);
};

const DualRange = ({min, max, values, onChangeRange, onSelectRange}) =>(
    <div className="curves-dual-range">
        <input type="range" min={min} max={max}
               onChange={event => changeRange('LOWER', event.target.value, values, min, max, onChangeRange)}
               onMouseUp={() => onSelectRange(values[0], values[1])}
               value={values[0]}
               className="minimum"/>

        <input type="range" min={min} max={max}
               onChange={event => changeRange('UPPER', event.target.value, values, min, max, onChangeRange)}
               onMouseUp={() => onSelectRange(values[0], values[1])}
               value={values[1]}
               className="maximum"/>
    </div>
);

export default DualRange;
