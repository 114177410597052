import axios from "axios";

class UserStatService {

    /**
     * Crate page load user stat.
     *
     * @param page
     */
    static createPageLoadUserStat(page) {
        return UserStatService.create('PAGE_LOAD',
                                        page, // attrA
                                        null); // attrB
    }

    /**
     * Crate EOI form open user stat.
     *
     * @param productId
     * @param term
     */
    static createEOIFormOpenUserStat(productId, term) {
        return UserStatService.create('EOI_FORM_OPEN',
                                        productId, // attrA
                                        term); // attrB
    }

    /**
     * Crate view switch user stat.
     *
     * @param view
     * @param productType
     */
    static createViewSwitchUserStat(productType, view) {
        return UserStatService.create('VIEW_SWITCH',
                                        productType, // attrA
                                        view); // attrB
    }

    /**
     * Crate PDF click user stat.
     *
     * @param page
     */
    static createPdfClickUserStat(page) {
        return UserStatService.create('PDF_CLICK',
                                        page, // attrA
                                        null); // attrB
    }

    /**
     * Crate chart click user stat.
     *
     * @param productId
     * @param term
     */
    static createChartClickUserStat(productId, term) {
        return UserStatService.create('CHART_CLICK',
                                        productId, // attrA
                                        term); // attrB
    }

    /**
     * Crate product filter load user stat.
     *
     * @param userFilterId
     */
    static createProductFilterLoadUserStat(userFilterId) {
        return UserStatService.create('PRODUCT_FILTER_LOAD',
                                        userFilterId, // attrA
                                        null); // attrB
    }

    /**
     * Create product filter add user stat.
     *
     * @param filter
     * @param value
     */
    static createProductFilterAddUserStat(filter, value) {
        return UserStatService.create('PRODUCT_FILTER_ADD',
                                        filter, // attrA
                                        value); // attrB
    }

    /**
     * Create product filter remove user stat.
     *
     * @param filter
     * @param value
     */
    static createProductFilterRemoveUserStat(filter, value) {
        return UserStatService.create('PRODUCT_FILTER_REMOVE',
                                        filter, // attrA
                                        value); // attrB
    }

    /**
     * Crate Maturity EOI form open user stat.
     *
     * @param productId
     * @param term
     */
    static createMaturityEOIFormOpenUserStat(contractId, term) {
        return UserStatService.create('MATURITY_EOI_FORM_OPEN',
            contractId, // attrA
            term); // attrB
    }

    /**
     * Crate Expand Collapse Terms switch user stat.
     *
     * @param value: String expand or collapse
     * @param productType
     */
    static createTermExpandCollpseSwitchUserStat(productType, value) {
        return UserStatService.create('EXPAND_COLLAPSE_TERMS',
            productType, // attrA
            value); // attrB
    }

    /**
     * Create user stat.
     *
     * @param type
     * @param attr_a
     * @param attr_b
     * @returns {Promise<any>}
     */
    static create(type, attr_a, attr_b) {
        const userStat = { type, attr_a, attr_b };

        return new Promise(resolve => {
            axios.post('/api/client/user_stats', userStat).then(() => {
                resolve();
            });
        });
    }

    static postActivity(date) {
        return new Promise((resolve, reject) => {
            axios.post('/api/client/user_stats/activity', date).then(() => {
                resolve();
            });
        });
    }
}

export default UserStatService;
