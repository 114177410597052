import React, {Fragment, Component} from 'react';
import termLabel, {formatTermName} from "../../common/termLabel";
import QueryParams from "../../common/QueryParams";
import {formatRate, formatRate4} from "../../common/formatUtil";
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";

const displayBBSWRate = (bbsw, termName, productType) => {
    const element = bbsw.find(function(element) {
        return element.name === termName;
    });
    if (element === undefined) {
        return <p className="rate">-</p>;
    }
    let rates = "";
    if (element.attributes.length > 1) {
        rates = <Fragment>
            {element.attributes.map((element, idx) => <p key={idx} >{formatTermName(element.benchmarkTerm)}: {productType === 'ncd' ? formatRate4(element.value) : formatRate(element.value)}</p>)}
        </Fragment>;
    }
    return <div className="rate">
        {rates !== "" ? <div className="tooltip">{rates}</div> : null}
        {productType === 'ncd' ? formatRate4(element.value) : formatRate(element.value)}
    </div>
};

class MaxYieldCell extends Component {

    constructor(props) {
        super(props);
        this.changeOrder = this.changeOrder.bind(this);
        this.sortedColumnClassName = this.sortedColumnClassName.bind(this);
    }

    changeOrder(sort) {
        const currentSort = QueryParams.get(this.props, 'sort');
        const currentOrder = QueryParams.get(this.props, 'order');
        if (currentSort === sort) {
            QueryParams.change(this.props, {order: undefined, sort: undefined});
        }
        else {
            QueryParams.change(this.props, {sort, order: 'DESC', sortAvailability: undefined});
        }
    }

    sortedColumnClassName(name) {
        return QueryParams.get(this.props, 'sort') === name ? ('sorted ' +
            QueryParams.get(this.props, 'order')) : 'unsorted';
    }

    render() {
            let arrowDirectionClass;
            if (this.props.isParentExpanded === true) {
                arrowDirectionClass = 'contract';
            } else {
                arrowDirectionClass = 'expand';
            }
            var elemStyle;
            if (this.props.isChildTerm && this.props.isChildTerm === true) {
                //#A8C1C7
                if (this.props.show) {
                    elemStyle = {backgroundColor: "#C1D3D7"};
                } else {
                    elemStyle = {display: 'none'}
                }
            }
            let expanderStyle = this.props.isParentExpanded === true ? {fontSize: "50px"} : {
                fontSize: "36px",
                fontWeight: 500
            };
            return (
                <div className={'rate-group '} style={elemStyle}>
                    <div onClick={() => this.changeOrder(this.props.term.name)}
                         className={'group ' + this.sortedColumnClassName(this.props.term.name)}>
                        <p className="term">
                            {termLabel(this.props.term.name)}
                        </p>
                        {displayBBSWRate(this.props.bbsw, this.props.term.name, QueryParams.getUrlChunk(this.props, -1))}
                        <p className="rate">{formatRate(this.props.term.name)}</p>
                    </div>
                    {(this.props.showArrow === true && this.props.term.childTerms && this.props.term.childTerms.length > 0) &&
                    <div className={'expand-column'}
                         onClick={() => this.props.toggleExpandMaxYieldGroup(this.props.term.name)}
                         style={expanderStyle}>
                        {this.props.isParentExpanded === true ? '-' : '+'}
                    </div>}

                </div>
            )
    }
} export default withRouter(GlobalStore.connect(MaxYieldCell), MaxYieldCell);