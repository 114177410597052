import React, {Component} from 'react';

import * as Highcharts from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import HistoricalService from "../../service/HistoricalService";
import moment from "moment";

const terms = [
    {value: 't30', label: '1 Month' },
    {value: 't90', label: '3 Months' },
    {value: 't180', label: '6 Months' },
    {value: 't1yr', label: '1 Year' },
    {value: 't2yr', label: '2 Years' },
    {value: 't3yr', label: '3 Years' },
    {value: 't4yr', label: '4 Years' },
    {value: 't5yr', label: '5 Years' },
];

const ranges = [
    {value: 'all_time', label: 'All Time' },
    {value: 'ytd', label: 'YTD' },
    {value: 'current_fy', label: 'Current FY' },
    {value: 'previous_fy', label: 'Previous FY' },
];

class HistoricalRateTrendsChart extends Component {

    constructor(props) {
        super(props);
        this.handleClickBank = this.handleClickBank.bind(this);
        this.changeChartParameter = this.changeChartParameter.bind(this);
        this.handleChartSetExtremes = this.handleChartSetExtremes.bind(this);
        this.state = {
            chartInstance: undefined,
            chartParameters: {
                term: 't90',
                termToCompare: undefined,
                baseLine: 'ZERO',
                dateFrom: moment(),
                dateTo: moment(),
                banks: ['AMP BANK', 'AUSWIDE BANK', 'NAB'],
                range: 'ytd'
            }
        };
    }

    buildChartTitle(params) {
        const term = terms.find(element => element.value === params.term);
        const baseLine = params.baseLine.toLowerCase();
        return `Historical ${term.label} Term Deposit trends over time (${baseLine} base line)`;
    }


    getChartDefaultOptions () {
        return {
            chart: {
                renderTo: 'container',
                type: 'line',
                borderRadius: 7,
                style:{
                    fontFamily:'Lato',
                }
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: ''
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                borderWidth: 0
            },
            tooltip: {
                split: false,
                shared: true,
                formatter: function () {
                    return this.points.reduce(function (s, point) {
                        return s + '<br/><span style="color:'+ point.series.color +' ">'+ point.series.name + '</span>' ;
                    }, '<b>' + moment.utc(this.x).format('DD-MM-YYYY') + '</b>');
                },
            },
            xAxis: {
                events: {
                    afterSetExtremes: (xAxis, event) => this.handleChartSetExtremes(xAxis.min, xAxis.max),
                }
            },
            yAxis: {
                opposite:false
            },
            series: []
        };
    }

    componentDidMount() {
        exporting(Highcharts);
        let options = this.getChartDefaultOptions();
        options.title.text = this.buildChartTitle(this.state.chartParameters);
        this.state.chartInstance = Highcharts.stockChart('container', options);
        this.state.chartInstance.showLoading();
        HistoricalService.find(this.state.chartParameters)
        .then(data => {
            data.forEach((serie) => {
                const bank = this.props.banks.find(element => element.name === serie.name);
                serie.color = bank.color;
                serie.name = bank.label;
                this.state.chartInstance.addSeries(serie, false);
            });
            this.state.chartInstance.hideLoading();
            this.state.chartInstance.redraw();
            this.state.chartInstance.reflow();
            this.state.chartInstance.xAxis[0].renderLine();
            this.handleChartSetExtremes(this.state.chartInstance.xAxis[0].dataMin, this.state.chartInstance.xAxis[0].dataMax);
        });
    }

    handleChartSetExtremes(min, max) {
        this.setState(prevState => ({
                chartParameters: {
                    ...prevState.chartParameters,
                    dateFrom: moment(min),
                    dateTo: moment(max)
                }
            })
        );
    }

    changeChartParameter(name, value) {
        if (this.state.chartParameters[name] === value) {
            return;
        }
        this.setState(prevState => ({
                chartParameters: {
                    ...prevState.chartParameters,
                    [name]: value
                }
            }), () => {
                this.refreshChart()
            }
        );
    }

    handleClickBank(bank) {
        let banks = this.state.chartParameters.banks;
        const index = banks.indexOf(bank);
        index < 0 ? banks.push(bank) : banks.splice(index, 1);
        this.setState(prevState => ({
                chartParameters: {
                    ...prevState.chartParameters,
                    banks: banks
                }
            }),
            () => {
                this.refreshChart()
            }
        );
    }

    refreshChart() {
        this.state.chartInstance.showLoading();
        HistoricalService.find(this.state.chartParameters)
        .then(data => {
            while(this.state.chartInstance.series.length > 0) {
                this.state.chartInstance.series[0].remove(false);
            }
            data.forEach((serie) => {
                const bank = this.props.banks.find(element => element.name === serie.name);
                serie.color = bank.color;
                serie.name = bank.label;
                this.state.chartInstance.addSeries(serie, false);
            });
            this.state.chartInstance.setTitle({text: this.buildChartTitle(this.state.chartParameters)});
            this.state.chartInstance.hideLoading();
            this.state.chartInstance.redraw();
            this.state.chartInstance.zoomOut();
            this.handleChartSetExtremes(this.state.chartInstance.xAxis[0].dataMin, this.state.chartInstance.xAxis[0].dataMax);
        });
    }


    render() {
        return (
            <div className="rate-trends-chart historical-chart-container">
                <h2>Term Deposit Yield Trends</h2>
                <div className="chart-parameters-row">
                    <div className="left-column">
                        <div className="display-flex flex-1 ">
                            <label className="curves-form-label">Term:</label>
                            <select value={this.state.chartParameters.term}
                                    onChange={e => {this.changeChartParameter('term', e.target.value)}}
                            >
                                {terms.map(term => {
                                    return (
                                        <option key={`term-${term.value}`} value={term.value}>{term.label}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="display-flex flex-1">
                            <label className="curves-form-label">Base Line:</label>
                            <select value={this.state.chartParameters.baseLine}
                                    onChange={e => {this.changeChartParameter('baseLine', e.target.value)}}
                            >
                                <option value="BBSW">Benchmark</option>
                                <option value="ZERO">Zero</option>
                            </select>
                        </div>
                    </div>
                    <div className="right-column">
                        <div className="chart-date">
                            <label className="curves-form-label">From:</label>
                            <p>{this.state.chartParameters.dateFrom.format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="chart-date">
                            <label className="curves-form-label">To:</label>
                            <p>{this.state.chartParameters.dateTo.format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </div>
                <div className="chart-row">

                    <div className="chart-column">

                        <div className="chart-container">
                            <div id="container" />
                        </div>
                    </div>
                    <div className="left-column">
                        <label>Range</label>
                        {ranges.map(range => {
                            return (
                                <button key={`range_${range.value}`}
                                        className={range.value === this.state.chartParameters.range ? 'range-button selected' : 'range-button'}
                                        onClick={()=> this.changeChartParameter('range', range.value)}
                                >

                                    {range.label}
                                </button>
                            )
                        })}
                    </div>

                </div>
                <div className="chart-banks">
                    {this.props.banks.map(bank => {
                        return (
                            <button
                                key={'chart-bank-button'+bank.value}
                                className={'button-bank '+ bank.value + (this.state.chartParameters.banks.indexOf(bank.name) < 0 ? ' disable': '')}
                                onClick={()=> this.handleClickBank(bank.name)}

                            >{bank.label}</button>
                        )
                    })}
                </div>
            </div>
        )
    }

}

export default HistoricalRateTrendsChart;
