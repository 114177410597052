import React, { Component } from 'react';
import Spinner from "../common/Spinner";
import {withRouter} from "react-router-dom";
import ProductService from "../../service/ProductService";
import {cleanProductAttributes} from "../../common/productUtil";
import {PRODUCT_TYPES} from "../../common/types/productTypes";
import UserStatService from "../../service/UserStatService";
import GlobalStore from "../store/GlobalStore";
import FlexProductTable from "../table/FlexProductTable";
import moment from "moment";
import ProductsExportCSV from "../common/ProductsExportCSV";

class FloatingDepositPage extends Component {

    constructor(props) {
        super(props);

        this.changeFilterUrl = this.changeFilterUrl.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.renderLoading = this.renderLoading.bind(this);
        this.printProducts = this.printProducts.bind(this);
        this.state = {
            status: 'LOADING'
        };
    }

    /**
     * Example.
     * Remove this function in the future.
     *
     */
    changeFilterUrl() {
        this.props.history.push('?offset=10&limit=10&orderAttribute=institution&orderSort=asc&orderAttribute=baseRate&orderSort=desc');
    }

    /**
     * Executed after rendered first time. Fetch data here.
     *
     */
    componentDidMount() {
        this.fetchData();

        UserStatService.createPageLoadUserStat('FLOATING_TERM_DEPOSIT');
    }

    /**
     * Executed after rendered only if props or state have changed.
     * Fetch data here only if query params have changed from previous time the component was rendered.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        /**
         * If query parameters have changed, refresh list data.
         */
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({status: 'LOADING'});
            this.fetchData();
        }

        if (this.props.globalStore.productUpdated !== undefined) {
            const productUpdated = this.props.globalStore.productUpdated;
            if (prevProps.globalStore.productUpdated === undefined || productUpdated.date !== prevProps.globalStore.productUpdated.date) {
                this.fetchData();
            }
        }
    }

    /**
     * Fetch data here.
     *
     */
    fetchData() {
        Promise.all([
            ProductService.getProducts({product_type: PRODUCT_TYPES.FLOATING_TERM_DEPOSIT,product_category:'PRODUCT'}),
            ProductService.getProducts({product_type: PRODUCT_TYPES.FLOATING_NCD,product_category:'PRODUCT'}),

        ]).then((values) => {
            const permissions = (values[0].data && values[0].data.length>0)?values[0].data[0].permission_dto:{};
            const floatingTermDeposits = values[0].data;
            const floatingNCDs = values[1].data;
            this.setState({
                floatingTermDeposits:floatingTermDeposits,
                floatingNCDs: floatingNCDs,
                permissions:permissions,
                status: 'LOADED'
            });
        })
        .catch((error)=>{
            console.error(error.message);
            this.setState({floatingTermDeposits:[], floatingNCDs: [], status: 'LOADED'});
        });
    }


    printProducts() {
        UserStatService.createPdfClickUserStat('FLOATING_TERM_DEPOSIT');

    	//get layouts parts & variables
        const user = this.props.globalStore.user;
        const date = moment(new Date()).format("Do MMMM YYYY");
        const title = user.title !== null && user.title !=='null'? user.title : '';
        const chart = ( document.getElementById('curves-rate-chart') )? document.getElementById('curves-rate-chart').innerHTML : "";
        const tableItems = ( document.getElementsByClassName('product-list')[0] )? document.getElementsByClassName('product-list')[0].innerHTML : "";
        const tableItems1 = ( document.getElementsByClassName('product-list')[1] )? document.getElementsByClassName('product-list')[1].innerHTML : "";



        // Open the print window
    	const WinPrint = window.open('', '', 'left=0,top=0,width=1500,height=1800,toolbar=1,scrollbars=1,status=0');

    	WinPrint.document.write(`<!DOCTYPE html>
    	<html>
    	  <head>
              <link rel="stylesheet" type="text/css" href="/css/print.css?v=1">
              <title>Yieldhub - Floating deposits as at ${date} </title>
          </head>
    	  <body id="print-view">
    	  
    	      <div class="curves-header">
    	      	  <div class="first-row">
    	      	  	  <div class="first-column">
    	      	  		  <img src="/img/Yieldhub_Primary_Logo_Trimmed.svg" />
    	      	  	  </div>
    	      	  	  <div class="second-column">
    	      	  		  <div class="header-title"><span class="strong">YH</span> <span class="normal">YIELD HUB</span></div>
                              <div class="header-info">
                                <p>Floating deposits as at ${date}</p>
                                <p>${title} ${user.first_name} ${user.last_name} (${user.email})</p>
                              </div>
                      </div>
                  </div>    
              </div>
              
    	  	  <div id="curves-rate-chart" >
	  	  	  	  ${chart}
	  	  	  </div>
               <div class="main-title">
                <h2>Floating term deposits</h2>
              </div>
    	  	  <div class="curves-table" >
    	  	      ${tableItems}
    	      </div>
    	      <div class="main-title">
                <h2>Floating NCD's</h2>
              </div>
    	  	  <div class="curves-table" >
    	  	      ${tableItems1}
    	      </div>
    	      <footer>
    	        <p>Rates are indicative at the time of publishing and are subject to change. Deals are subject to confirmation by a Curve representative.</p>
                
                <div class="disclaimer">
                    <p>DISCLAIMER</p>
                    <p>
                        This website is not intended to imply a recommendation or otherwise constitute advice in relation to financial products. It does not take into account your investment objectives, financial situation or particular needs. Before acting on any information you obtain from this website you need to consider the appropriateness of the information in lieu of your investment objectives, financial situation or needs.
                    </p>
                    <p>
                        Curve Securities or Yieldhub may receive a platform fee / commission from an institution receiving funds as a result of opportunities being sourced through the Yieldhub platform or Curve Securities.
                    </p>
                    <p>Subject to Yieldhub's Terms and Conditions</p>             
                </div>
                <p>Powered by curve</p>
                <img src="/img/Curve_Mono_Logo_White_RGB.svg" style="height: 60px; filter: invert(100%)"/>
    	      </footer>
    	  </body>
    	</html>`);

    	WinPrint.document.close();

    	// Timeout needed because chrome had an error because write was not finished and a white page is shown
		// instead. With this timeout it gives the needed time to render contents
        setTimeout(function () {
			WinPrint.focus();
			WinPrint.print();
        }, 1500);

    }


    render() {

        if (this.state.status === 'LOADING') {
            return this.renderLoading();
        }


        return (
            <div className="curves-floating-deposit-page">
                <div className="product-type">
                    Floating term deposits
                    <div className="options-container">
                        {this.state.permissions && this.state.permissions.allow_export &&
                            <button className="print-btn" onClick={() => this.printProducts()}>
                                PDF
                                <svg>
                                    <use xlinkHref='#curves-svg-long-arrow-down'></use>
                                </svg>
                            </button>
                        }
                        <ProductsExportCSV
                            products={cleanProductAttributes(this.state.floatingTermDeposits, true)}
                            productType={PRODUCT_TYPES.FLOATING_TERM_DEPOSIT}
                            groupedTerms={true}
                        />
                    </div>
                </div>
                <div className="product-list">
                    <FlexProductTable
                        products={cleanProductAttributes(this.state.floatingTermDeposits, true)}
                    />
                </div>
                {this.state.floatingNCDs.length > 0 &&
                    <div className="floating-ncds">
                        <div className="product-type">
                            Floating NCD's
                            <div className="options-container">
                                <ProductsExportCSV
                                    products={cleanProductAttributes(this.state.floatingNCDs, true)}
                                    productType={PRODUCT_TYPES.FLOATING_NCD}
                                    groupedTerms={true}
                                />
                            </div>
                        </div>
                        <div className="product-list">
                            <FlexProductTable
                                products={cleanProductAttributes(this.state.floatingNCDs, true)}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderLoading() {
        return (
            <Spinner className="middle-screen"/>
        )
    }

}

export default withRouter(GlobalStore.connect(FloatingDepositPage), FloatingDepositPage);
