import React, {Component, Fragment} from 'react';
import MaxYieldCell from "./MaxYieldCell";
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";


class MaxYieldGroup extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        let parentTerm =  this.props.parentTerm;
        if (parentTerm.value !== 0) {
            let showChildren = (this.props.groupedTerms === true && this.props.isParentExpanded === true) || (this.props.groupedTerms === false);
            let showArrow = (parentTerm.isExpandable && this.props.groupedTerms === true);
            // expandMaxYieldGroup is has the keyword "this" binded to MaxYieldGroup.js component, so I can change the
            // state of the columns when clicking the arrow on the child component (MaxYieldCell)
            return (
                <Fragment>
                <MaxYieldCell
                        term={parentTerm}
                        bbsw={this.props.bbsw}
                        toggleExpandMaxYieldGroup={this.props.toggleExpandMaxYieldGroup}
                        showArrow={showArrow}
                        isParentExpanded={this.props.isParentExpanded}
                        show={true}
                        isChildTerm={false}
                />
                {parentTerm && (parentTerm.childTerms) && (parentTerm.childTerms.length > 0) && parentTerm.childTerms.map((childTerm, index) => {
                        return <MaxYieldCell
                                        term={childTerm}
                                        bbsw={this.props.bbsw}
                                        showArrow={false}
                                        isParentExpanded={this.props.isParentExpanded}
                                        show={showChildren}
                                        isChildTerm={true}
                                />
                })
                }
                </Fragment>
            )
        } else {
            return (
                <div className={'rate-group '}></div>
            );
        }
    }
} export default withRouter(GlobalStore.connect(MaxYieldGroup), MaxYieldGroup);