import React, {Component} from 'react';
import GlobalStore from "../store/GlobalStore";
import ProductCard from "../card/ProductCard";
import ProductTable from "../table/ProductTable"
import Filters from "../layout/Filters";
import FilterTags from "../layout/FilterTags";
import queryString from "query-string";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import {withRouter} from "react-router-dom";
import Spinner from "../common/Spinner";
import RateChart from "../layout/RateChart";
import {cleanProductAttributes} from "../../common/productUtil";
import ProductService from "../../service/ProductService";
import QueryParams from "../../common/QueryParams";
import SpecialCard from "../card/SpecialCard";
import SpecialService from "../../service/SpecialService";
import {groupTerms} from "../../common/rateUtil";
import {PRODUCT_VISIBILITY} from "../../common/types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "../../common/types/productAttributeTypes";
import {PRODUCT_TYPES, productLabels} from "../../common/types/productTypes";
import MessageDialog from "../dialog/MessageDialog";
import UserService from "../../service/UserService";
import UserStatService from "../../service/UserStatService";


class AboutPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: '',
            validationErrors: {},
            questionSubmitted: false
        };


    }

    componentDidMount() {
        UserStatService.createPageLoadUserStat('ABOUT');
    }

    render() {
        return (
            <div className="curves-about-page curves-simple-page">

                <h1>
                    About Us
                </h1>
                <p>
                    Hi – Thanks for using YieldHub!
                </p>

                <p>
                    YieldHub is designed to streamline your investment process by simply highlighting the best
                    opportunities available to you on any given day. It aggregates rates from over 80 banking
                    institutions (Authorised Deposit-Taking Institutions or ADI) ensuring there's always at least
                    one stand out rate to secure. All investments are placed directly with the banking institution
                    and deposits are always in your name. The rates advertised are the rates you invest at, there
                    are no additional transaction fees or charges.
                </p>

                <p>
                    All products and rates are clickable, filterable and sortable, and expressions of interest
                    (EOIs) in an opportunity are sent in just a few clicks. To submit an EOI simply Select, Enter
                    and Submit. Select the term and ADI you would like to invest with by clicking the applicable
                    rate. Enter the amount you wish to invest, the maturity date, and accept the terms and
                    conditions before clicking 'Submit EOI'. You will receive a confirmation email of your EOI, and
                    your Curve Representative will liaise with the ADI in question to ensure the rate is available
                    and when confirmed with you and the ADI will send you a final confirmation of the deal via
                    email. This conformation will include account details for the bank in questions for you to
                    transfer the funds directly to.
                </p>

                <p>
                    Keep an eye out for YieldHub platform updates along the way too! We strive to elevate our
                    clients, not only in accessing the best available yield, but also doing so in the most simple,
                    streamlined, and compliant way. We understand that some organisations don't just need to access
                    the best rate, but also require a multi-person approval process and the ability to look back
                    historically to prove compliance. We're building in these functionalities, so you have access to
                    not only an unrivalled list of ADIs, but also a system that is always evolving to meet your
                    changing needs. Please let us know what you would like to see added to Yieldhub
                </p>


                <h1>
                    FAQs
                </h1>

                <h2>
                    Are Other Terms Available?
                </h2>
                <p>
                    The ADIs we deal with tend to provide rates for standard terms (1 month is 30 days, 1 year is
                    365 for example). Normally when you want to place a deposit +/- a few days of this term there
                    shouldn't be an issue. If you have an odd term you are looking for (i.e. 105 days or 3.5 months)
                    we can certainly enquire for you! Simply add the dates/term you require and your Curve
                    Representative will get back to you with the interpolated rate that will apply. We aim to get
                    back to you with an answer within 30 minutes.
                </p>

                <h2>
                    When Are Rates Live?
                </h2>

                <p>
                    Rates are updated throughout the morning. The clock symbol to the right indicates when they were
                    last updated. Most rates are usually live following BBSW being set at 10.30am. All rates are
                    indicative and we will confirm, through the EOI process, if a rate is actionable or if we need
                    to wait for the update to come through from the bank.
                </p>

                <h2>
                    I’ve placed an Expression of Interest, am I locked in?
                </h2>

                <p>
                    No. When you submit an expression of interest for a specific rate or a general EOI there is no
                    binding agreement or obligation to invest. Deals aren’t confirmed until agreed with your Curve
                    Representative, an expression of interest will simply prompt your Curve Representative to
                    confirm.
                </p>

                <h2>
                    I’ve placed an EOI, what happens now?
                </h2>

                <p>
                    Your Curve Representative will be in touch. Initially they will confirm the rate you’ve seen is
                    accurate and the bank is able to take the funds As soon as this has been completed your
                    Representative will contact you. Our usual dealing process will then take place, contract notes
                    will be issued, and you transfer funds directly to the bank.
                </p>

                <h2>
                    What are Credit Ratings?
                </h2>

                <p>
                    Credit ratings can be provided as a paid service by a number of different agencies (including
                    S&P, Moody's and Fitch). Some ADIs have simply not requested a rating and are listed as Unrated
                    or Not Rated (NR). Credit Rating filters are available to enable you to drill down to the
                    specific banks your investments policy allows for. Further information on credit ratings can be
                    found <a href="https://curvesecurities.com.au/creditratings/" target="_blank">here</a>.
                </p>

                <h2>
                    What is a Market Forces Classification?
                </h2>

                <p>
                    Market Forces collects and presents information as to which ADIs do and don't fund (directly or
                    indirectly) fossil fuels projects. Curve Securities and YieldHub make no warranties as to the
                    accuracy of this information. You can find further information on how they rate ADIs <a
                    href="https://www.marketforces.org.au/info/compare-bank-table/" target="_blank">here</a>.
                </p>

                <h2>
                    What is a Branch of Foreign Bank?
                </h2>

                <p>
                    'Branch of A Foreign Bank' is an Australian Prudential Regulation Authority classification for
                    an ADI that operates in the name of its foreign parent. It is obligated to follow the
                    regulations of both the home and host countries. Branches of Foreign Banks are regulated a
                    little differently to full Australian Incorporated ADIs, for example deposits are not covered by
                    the Financial Claims Scheme. You can find further information about foreign branch banks <a
                    href="https://curvesecurities.com.au/branchbanks/" target="_blank">here</a>.
                </p>

                <h2>
                    Have another question? We're always happy to help!
                </h2>
                {!this.state.questionSubmitted ?
                    <div className="contact-form">
                        <textarea onChange={e => this.setState({question: e.target.value})}
                                  value={this.state.question}/>
                        <div className="validation-error"><p>{this.state.validationErrors.question}</p></div>
                        <div className="button-container">
                            <button onClick={() => {
                                const validationErrors = {};

                                if (this.state.question.trim().length < 10) {
                                    validationErrors.question = 'Please, write a question.';
                                }

                                this.setState({validationErrors});

                                if (Object.keys(validationErrors).length > 0) {
                                    return;
                                }

                                this.setState({questionSubmitted: true});

                                UserService.submitQuestion(this.state.question);
                            }}>Submit question
                            </button>
                        </div>
                    </div>
                    :
                    <div className="question-submitted">
                        <p>
                            Thank you for submitting your question. We'll get back to you as soon as possible.
                        </p>
                    </div>
                }

                <h1>
                    Contact us
                </h1>

                <div className="contact-section">
                    <p>
                        <span>P: (02) 9690 2188</span>
                        <span>F: (02) 9012 0854</span>
                        <br/>
                        Suite 1801, L18, 1 Bligh Street, Sydney NSW 2000
                        <br/>
                        <span>W:  <a href="https://curvesecurities.com.au/">curve.com.au</a></span>
                        <span>1300-1-CURVE (28783)</span>
                        <br/>
                        ABN 94 143 558 598 |            AFS Licence 405751
                    </p>
                </div>
            </div>
        )
    }

}

export default AboutPage;
