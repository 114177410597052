import queryString from "query-string";

class QueryParams {

    static change(props, changes) {
        const urlQueryParams = queryString.parse(props.location.search);
        const newUrlQueryParams = { ...urlQueryParams, ...changes};
        props.history.push('?' + queryString.stringify(newUrlQueryParams));
    }

    static get(props, param) {
        const urlQueryParams = queryString.parse(props.location.search);
        return urlQueryParams[param];
    }

    static remove(props, param) {
        const urlQueryParams = queryString.parse(props.location.search);
        delete urlQueryParams[param];
        props.history.push('?' + queryString.stringify(urlQueryParams));
    }

    static getUrlChunk(props, n) {
        const urlChunks = props.location.pathname.split('/');
        return urlChunks[n >= 0 ? (n + 1) : (urlChunks.length + n)];
    }

}

export default QueryParams;
