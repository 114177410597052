import React, {Component} from 'react';

import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import HistoricalService from "../../service/HistoricalService";
import moment from "moment";
import HistoricalChartDateNavigator from "./HistoricalChartDateNavigator";
import MonthPickerInput from 'react-month-picker-input';


class HistoricalTermRatesChart extends Component {

    constructor(props) {
        super(props);
        this.handleClickBank = this.handleClickBank.bind(this);
        this.handleChangeNavigatorDate = this.handleChangeNavigatorDate.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.processChartData = this.processChartData.bind(this);

        this.state = {
            chartInstance: undefined,
            chartParameters: {
                year: 2014,
                month: 1,
                banks: ['NAB', 'AMP BANK']
            },
            minDate: [0,2014], //0 means January
            selectedDateIdx: 0,
            chartData: undefined
        };
    }

    buildChartTitle(dates, dateIndex) {
        const date = moment.utc(dates[dateIndex]);
        return `Yield Curve for ${date.format('MMM YYYY')} `;
    }

    getChartDefaultOptions () {
        return {
            chart: {
                renderTo: 'term-rate-chart-container',
                type: 'spline',
                borderRadius: 7,
                style:{
                    fontFamily:'Lato',
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: ['1 month', '3 months', '6 months', '12 months', '2 years', '3 years', '4 years', '5 years'],
                crosshair: {
                    snap: true,
                    width: 2,
                    color: '#cccccc'
                }
            },
            yAxis: {
                title: {
                    text: 'Yield'
                }
            },
            tooltip: {
                shared: true,
                formatter: function () {
                    return this.points.reduce(function (s, point) {
                        return s + '<br/><span style="color:'+ point.series.color +' ">'+ point.series.name + '</span>' ;
                    }, '<b>' + this.x + '</b>');
                },
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            series: []
        };
    }

    componentDidMount() {
        exporting(Highcharts);
        let options = this.getChartDefaultOptions();
        this.state.chartInstance = Highcharts.chart('term-rate-chart-container', options);
        this.state.chartInstance.showLoading();
        HistoricalService.findTermRates(this.state.chartParameters)
            .then(data => {
                this.processChartData(data);
            });
    }

    processChartData(data) {
        let minValue = undefined;
        let maxValue = undefined;
        data.series.forEach((serieData) => {

            const tmpMin = Math.min(...serieData.data.flat(2));
            const tmpMax = Math.max(...serieData.data.flat(2));
            minValue = !minValue || minValue > tmpMin ? tmpMin : minValue;
            maxValue = !maxValue || maxValue < tmpMax ? tmpMax : maxValue;

            let serie = {};
            const bank = this.props.banks.find(element => element.name === serieData.name);
            serie.color = bank.color;
            serie.name = bank.label;
            serie.data = serieData.data[0];
            this.state.chartInstance.addSeries(serie, false);
        });
        this.setState({
            chartData: data,
            selectedDateIdx: 0
        }, () => {
            this.state.chartInstance.setTitle({text: this.buildChartTitle(this.state.chartData.dates, this.state.selectedDateIdx)});
        });
        if (minValue !== undefined && maxValue != undefined) {
            this.state.chartInstance.yAxis[0].setExtremes(minValue, maxValue, false);
        }
        this.state.chartInstance.hideLoading();
        this.state.chartInstance.redraw();
        this.state.chartInstance.reflow();

    }

    handleChangeNavigatorDate(dateIndex) {
        for (let index = 0; index < this.state.chartInstance.series.length; index++) {
            const serieName = this.state.chartInstance.series[index].name;
            const bankName = this.props.banks.find(e => e.label === serieName).name;
            const serieData = this.state.chartData.series.find(e => e.name === bankName).data[dateIndex];
            this.state.chartInstance.series[index].setData(serieData);
        }
        this.state.chartInstance.redraw();
        this.setState({
            selectedDateIdx: dateIndex
        }, () => {
            this.state.chartInstance.setTitle({text: this.buildChartTitle(this.state.chartData.dates, this.state.selectedDateIdx)});
        });
    }

    handleClickBank(bank) {
        let banks = this.state.chartParameters.banks;
        const index = banks.indexOf(bank);
        index < 0 ? banks.push(bank) : banks.splice(index, 1);
        this.setState(prevState => ({
                chartParameters: {
                    ...prevState.chartParameters,
                    banks: banks
                }
            }),
            () => {
                this.refreshChart()
            }
        );
    }

    refreshChart() {
        this.state.chartInstance.showLoading();
        HistoricalService.findTermRates(this.state.chartParameters)
            .then(data => {
                while(this.state.chartInstance.series.length > 0) {
                    this.state.chartInstance.series[0].remove(false);
                }
                this.processChartData(data)
            });
    }

    handleDateChange(year, month) {
        this.setState(prevState => ({
                chartParameters: {
                    ...prevState.chartParameters,
                    year: year,
                    month: month
                }
            }),
            () => {
                this.refreshChart()
            }
        );
    }


    render() {
        return (
            <div className="term-rates-chart historical-chart-container">
                <h2>Yield Curves</h2>
                <div className="chart-parameters-row">
                    <div className="left-column">
                        <div className="display-flex flex-1 ">
                            <label className="curves-form-label">Time Period:</label>
                            <MonthPickerInput
                                year={this.state.chartParameters.year}
                                month={this.state.chartParameters.month - 1}
                                minDate={this.state.minDate}
                                maxDate={[new Date().getMonth() - 1, new Date().getFullYear()]}
                                onChange={(maskedValue, selectedYear, selectedMonth) =>
                                    this.handleDateChange(selectedYear, selectedMonth+1)
                                }
                            />

                        </div>
                    </div>
                </div>
                <div className="chart-row">
                    <div className="chart-column">
                        <div className="chart-container">
                            <div id="term-rate-chart-container" />
                        </div>
                    </div>
                </div>

                {this.state.chartData &&
                    <HistoricalChartDateNavigator
                        dates={this.state.chartData.dates}
                        onChange={this.handleChangeNavigatorDate}
                        selectedValue={this.state.selectedDateIdx}
                    />
                }

                <div className="chart-banks">
                    {this.props.banks.map(bank => {
                        return (
                            <button
                                key={'term-rates-chart-bank-button'+bank.value}
                                className={this.state.chartParameters.banks.indexOf(bank.name) < 0 ? 'button-bank disable' : 'button-bank '+bank.value}
                                onClick={()=> this.handleClickBank(bank.name)}

                            >{bank.label}</button>
                        )
                    })}
                </div>
            </div>
        )
    }

}

export default HistoricalTermRatesChart;
