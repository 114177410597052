import axios from "axios";


class UserService {

    static getUserProfile(hostName, sessionId,loginAsUser) {
        const params = loginAsUser ? {loginAsUser:loginAsUser} : {};
        const headers = sessionId ? {cookie: "JSESSIONID=" + sessionId} : {};
        return axios.get(hostName + '/api/client/me', {params:params, headers:headers});
    }

    static isSessionValid(hostName, sessionId, loginAsUser) {
        const params = loginAsUser ? {loginAsUser:loginAsUser} : {};
        const headers = sessionId ? {cookie: "JSESSIONID=" + sessionId} : {};
        return axios.get(hostName + '/api/client/isSessionValid', {params:params, headers:headers});
    }

    static submitQuestion(question) {
        return new Promise((resolve, reject) => {
            axios.post('/api/users/contact-message', question, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                resolve();
            });
        });
    }

    static doLogin(loginForm, redirectUrl) {
        return axios.post('/api/client/login'+ (redirectUrl !== undefined ? `?redirect_uri=${redirectUrl}` : ''), loginForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

    }

    static forgotPassword(forgotPasswordForm) {
        return axios.post('/api/client/login/forgotPassword', forgotPasswordForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

    }

    static validatePasswordCode(hostName,code) {
        return axios.get(hostName + '/api/client/login/validateForgotPasswordCode/'+code);
    }

    static resetPassword(resetPasswordForm) {
        return axios.post('/api/client/login/setPassword', resetPasswordForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

    }


    static getEois(){

        return new Promise((resolve, reject) => {
            axios.get('/api/client/expression_of_interest/').then(eois => {
                resolve(eois.data);
            })
            .catch(error=>{
                reject(error)
            });
        });

    }

    static getAccessCode(hostName, sessionId, redirect_uri) {
        const params = {};
        const headers = sessionId ? {cookie: "JSESSIONID=" + sessionId} : {};
        return axios.get(`${hostName}/api/client/login/getAccessCode?redirect_uri=${redirect_uri}`, {params:params,headers:headers});
    }
}

export default UserService;
