import React, { Component } from 'react';

import {withRouter} from 'react-router-dom';
import queryString from "query-string";
import DualRange from "../common/DualRange";
import CheckboxGroup from "../common/CheckboxGroup";
import {RATING_LONG_TERM, RATING_SHORT_TERM} from "../../common/bankRatingUtil";
import Checkbox from "../common/Checkbox";
import {FOSSIL_FUEL_FREE} from "../../common/types/fossilFuelFreeTypes";
import DialogUtil from "../../common/DialogUtil";
import SaveFilterDialog from "../dialog/SaveFilterDialog";
import UserFilterService from "../../service/UserFilterService";
import ConfirmDialog from "../dialog/ConfirmDialog";
import SavedFiltersDialog from "../dialog/SavedFiltersDialog";
import QueryParams from "../../common/QueryParams";
import {FINANCIAL_SCHEME_LABEL} from "../../common/types/financialSchemeType";
import FilterUtil, {FILTER_ALL, FILTER_DEPOSIT_RANGE} from "../../common/FilterUtil";
import {formatCapitalize} from "../../common/textFormatUtl";
import UserStatService from "../../service/UserStatService";
import {format_num, strip_num} from "../../common/formatUtil";
import SavedFilters from "./SavedFilters";

const CHKBOX_OPT_TERM_30_TO_180 = [
    { label: '1 mth', value: '30' },
    { label: '2 mth', value: '60' },
    { label: '3 mth', value: '90' },
    { label: '4 mth', value: '120' },
    { label: '5 mth', value: '150' },
    { label: '6 mth', value: '180' }
];

const CHKBOX_OPT_TERM_210_TO_330 = [
    { label: '7 mth', value: '210' },
    { label: '8 mth', value: '240' },
    { label: '9 mth', value: '270' },
    { label: '10 mth', value: '300' },
    { label: '11 mth', value: '330' }
];


const CHKBOX_OPT_TERM_360_TO_1800 = [
    { label: '1 yr',  value: '360' },
    { label: '18 mth', value: (30 * 18).toString() },
    { label: '2 yr',  value: (360 * 2).toString() },
    { label: '3 yr',  value: (360 * 3).toString() },
    { label: '4 yr',  value: (360 * 4).toString() },
    { label: '5 yr',  value: (360 * 5).toString() }];


const CHKBOX_OPT_FOSSIL_FUEL_FREE = [
    { label: 'Fossil Fuel Free',  value: FOSSIL_FUEL_FREE.FOSSIL_FUEL_FREE },
    { label: 'Not Stated', value: FOSSIL_FUEL_FREE.NOT_STATED}];

const CHKBOX_FINANCIAL_SCHEME = FINANCIAL_SCHEME_LABEL;

class Filters extends Component {

    constructor(props) {

        super(props);

        this.changeCheckbox = this.changeCheckbox.bind(this);
        this.changeDepositRange = this.changeDepositRange.bind(this);
        this.changeDepositInput = this.changeDepositInput.bind(this);
        this.selectDepositRange = this.selectDepositRange.bind(this);
        this.search = this.search.bind(this);
        this.saveSearch = this.saveSearch.bind(this);

        const filters = FilterUtil.getFiltersFromQueryParams(this.props.location);

        this.state = {
            filters,
            filtersDatetime: (new Date()).getTime(),
            lastFilterDepositMin: filters.filterDepositMin || FILTER_DEPOSIT_RANGE.min,
            lastFilterDepositMax: filters.filterDepositMax || FILTER_DEPOSIT_RANGE.max
        };

    }

    /**
     * Executed after rendered only if props or state have changed.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        /**
         * If query parameters have changed, refresh list data.
         */
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({ filters: FilterUtil.getFiltersFromQueryParams(this.props.location) });
        }

    }

    /**
     * Executed when user click filter checkboxes.
     *
     * @param queryParam
     * @param event
     */
    changeCheckbox(queryParam, event) {

        const filters = this.state.filters;

        // if checkbox checked
        if (event.target.checked) {
            // add value to url query params
            filters[queryParam].push(event.target.value);

            // create user stat
            UserStatService.createProductFilterAddUserStat(queryParam /* filter */, event.target.value /* value */);
        }
        else { // checkbox unchecked
            // filter terms in order to remove unchecked value
            filters[queryParam] = filters[queryParam].filter(value => value !== event.target.value);

            // create user stat
            UserStatService.createProductFilterRemoveUserStat(queryParam /* filter */, event.target.value /* value */);
        }

        this.setState({ filters });

        this.search();
    }

    /**
     * Executed when user changes deposit min/max input range.
     *
     * @param lowValue
     * @param highValue
     */
    changeDepositRange(lowValue, highValue) {
        const filters = this.state.filters;
        filters.filterDepositMin = lowValue;
        filters.filterDepositMax = highValue;
        this.setState({ filters });
    }

    changeDepositInput(lowValue, highValue) {
        const filters = this.state.filters;
        filters.filterDepositMin = lowValue;
        filters.filterDepositMax = highValue;
        this.setState({ filters });
    }

    /**
     * Executed when user finish selection of deposit min/max input range.
     *
     * @param lowValue
     * @param highValue
     */
    selectDepositRange(lowValue, highValue) {
        if (this.state.lastFilterDepositMin !== lowValue) {
            if (lowValue > FILTER_DEPOSIT_RANGE.min) {
                UserStatService.createProductFilterAddUserStat('filterDepositMin', // filter
                                                                Filters.formatDepositLimit(lowValue)); // value
            }
            else {
                UserStatService.createProductFilterRemoveUserStat('filterDepositMin', // filter
                                                                    Filters.formatDepositLimit(this.state.lastFilterDepositMin)); // value
            }
        }

        if (this.state.lastFilterDepositMax !== highValue) {
            if (highValue < FILTER_DEPOSIT_RANGE.max) {
                UserStatService.createProductFilterAddUserStat('filterDepositMax', // filter
                                                                Filters.formatDepositLimit(highValue)); // value
            }
            else {
                UserStatService.createProductFilterRemoveUserStat('filterDepositMax', // filter
                                                                    Filters.formatDepositLimit(this.state.lastFilterDepositMax)); // value
            }
        }

        this.setState({
            lastFilterDepositMin: lowValue,
            lastFilterDepositMax: highValue
        });

        this.search();
    }



    /**
     * Convert filters into url query parameters.
     *
     */
    search() {
        const urlQueryParams = queryString.parse(this.props.location.search);

        // Make a copy of filters object. Needed to remove attributes without any problem.
        const filters = JSON.parse(JSON.stringify(this.state.filters));
        /**
         * Remove parameter filter min/max deposit if is 0/1000.
         *
         */
        if (filters.filterDepositMin === FILTER_DEPOSIT_RANGE.min) {
            delete filters.filterDepositMin;
            delete urlQueryParams.filterDepositMin;
        }

        if (filters.filterDepositMax === FILTER_DEPOSIT_RANGE.max) {
            delete filters.filterDepositMax;
            delete urlQueryParams.filterDepositMax;
        }

        if(filters.filterBankName === null) {
            delete filters.filterBankName;
            delete urlQueryParams.filterBankName;
        }

        this.props.history.push('?' + queryString.stringify({ ...urlQueryParams, ...filters }));
    }

    /**
     * Open dialog to save current search.
     *
     */
    saveSearch() {

        const save = filterName => {
            const urlQueryParams = queryString.parse(location.search);

            const filters = JSON.stringify({
                filterTerm: urlQueryParams.filterTerm,
                filterRatingShortTerm: urlQueryParams.filterRatingShortTerm,
                filterRatingLongTerm: urlQueryParams.filterRatingLongTerm,
                filterDepositMin: urlQueryParams.filterDepositMin,
                filterDepositMax: urlQueryParams.filterDepositMax,
                filterFossilFuelFree: urlQueryParams.filterFossilFuelFree,
                filterInstitutionType: urlQueryParams.filterInstitutionType
            });

            const userFilter = {
                name: filterName.trim(),
                filters
            };

            UserFilterService.createOrUpdate(userFilter).then(() => {
                DialogUtil.close();
            });
        };

        DialogUtil.open(<SaveFilterDialog />).then(filterName => {

            UserFilterService.findAll().then(userFilters => {
                const userFilter = userFilters.find(it => it.name.toLowerCase() === filterName.toLowerCase());

                if (userFilter) {
                    DialogUtil.open(<ConfirmDialog message="Saved filter already exists. Overwrite it?" />).then(() => {
                        save(filterName);
                    });
                }
                else {
                    save(filterName);
                }
            });

        });
    }

    /**
     * Format amount: 1000 => 1K, 1000000 => 1M
     *
     * @param inputRangeValue
     * @returns {string}
     */
    static formatDepositLimit(inputRangeValue) {
        if (inputRangeValue === 0) {
            return '0';
        }
        else if (inputRangeValue < 1000000) {
            return (inputRangeValue/1000) + 'K';
        }
        else if (inputRangeValue === 10000000) {
            return 'No limit'
        }
        else {
            return (inputRangeValue/1000000) + 'M';
        }
    }

    render() {

        return (
            <div className={"curves-filters"} key={this.state.filtersDatetime}>

                <div className="sidebar-btn" onClick={this.props.handleSidebarBtnClick} >
                </div>

                <div className="filters-body">
                    <div className="title">
                        <h1>Filters</h1>
                        <SavedFilters/>
                    </div>
                    <div className="term-filter filter-group">
                        <h2>Term</h2>
                        <CheckboxGroup title="1 mth - 6 mth"
                                       options={CHKBOX_OPT_TERM_30_TO_180}
                                       checkedValues={this.state.filters.filterTerm}
                                       onChange={event => this.changeCheckbox('filterTerm', event)} />

                        <CheckboxGroup title="7 mth - 11 mth"
                                       options={CHKBOX_OPT_TERM_210_TO_330}
                                       checkedValues={this.state.filters.filterTerm}
                                       onChange={event => this.changeCheckbox('filterTerm', event)} />

                        <CheckboxGroup title="1 yr - 5 yr"
                                       options={CHKBOX_OPT_TERM_360_TO_1800}
                                       checkedValues={this.state.filters.filterTerm}
                                       onChange={event => this.changeCheckbox('filterTerm', event)} />

                    </div>
                    <div className="rating-filter filter-group">
                        <h2>Rating</h2>
                        <CheckboxGroup title="Short term"
                                       options={RATING_SHORT_TERM}
                                       checkedValues={this.state.filters.filterRatingShortTerm}
                                       onChange={event => this.changeCheckbox('filterRatingShortTerm', event)} />

                        <CheckboxGroup title="Long term"
                                       options={RATING_LONG_TERM}
                                       checkedValues={this.state.filters.filterRatingLongTerm}
                                       onChange={event => this.changeCheckbox('filterRatingLongTerm', event)} />
                    </div>
                    <div className="deposit-filter filter-group">
                        <h2>Deposit</h2>
                        <div className="values">
                            <h4>Min</h4>
                            <input type="text" value={format_num(this.state.filters.filterDepositMin)}
                                   maxLength={10}
                                   onChange={(e) => this.changeDepositInput(strip_num(e.target.value),this.state.filters.filterDepositMax)}
                                   onBlur={() => this.selectDepositRange(this.state.filters.filterDepositMin,this.state.filters.filterDepositMax)}
                            />
                            <h4>Max</h4>
                            <input type="text" value={format_num(this.state.filters.filterDepositMax)}
                                   maxLength={10}
                                   onChange={(e) => this.changeDepositInput(this.state.filters.filterDepositMin,strip_num(e.target.value))}
                                   onBlur={() => this.selectDepositRange(this.state.filters.filterDepositMin,this.state.filters.filterDepositMax)}
                            />
                        </div>
                    </div>
                    <div className="fossil-fuel-free-filter filter-group">
                        <h2>Market Forces Classification</h2>

                        <div className="checkboxes">
                            {CHKBOX_OPT_FOSSIL_FUEL_FREE.map((option, idx) =>
                                <div key={idx}>
                                    <Checkbox label={option.label}
                                              value={option.value}
                                              onChange={event => this.changeCheckbox('filterFossilFuelFree', event)}
                                              checked={this.state.filters.filterFossilFuelFree.indexOf(option.value) !== -1} />
                                </div>)}
                        </div>
                    </div>
                    <div className="adi-types-filter filter-group">
                        <h2>AUTHORISED DEPOSIT-TAKING INSTITUTIONS TYPES</h2>

                        <div className="checkboxes">
                            {CHKBOX_FINANCIAL_SCHEME.map((option, idx) =>
                                <div key={idx}>
                                    <Checkbox label={option.label}
                                              value={option.value}
                                              onChange={event => this.changeCheckbox('filterInstitutionType', event)}
                                              checked={this.state.filters.filterInstitutionType.indexOf(option.value) !== -1} />
                                </div>)}
                        </div>
                    </div>
                    <div className="save-search">
                        <button onClick={this.saveSearch}>SAVE THIS SEARCH</button>
                    </div>
                    {FilterUtil.haveAnyFilterApplied(this.props.location) &&
                        <div className="save-search">
                            <button onClick={() => FilterUtil.removeFilter(FILTER_ALL, null, this.props.location, this.props.history)}>CLEAR ALL FILTERS</button>
                        </div>
                    }
                </div>{/** filters-body **/}

            </div>
        )

    }

}

export default withRouter(Filters);
