import React, {Component} from 'react';
import GlobalStore from "../store/GlobalStore";
import ProductCard from "../card/ProductCard";
import ProductTable from "../table/ProductTable"
import Filters from "../layout/Filters";
import FilterTags from "../layout/FilterTags";
import queryString from "query-string";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import {withRouter} from "react-router-dom";
import Spinner from "../common/Spinner";
import RateChart from "../layout/RateChart";
import {cleanProductAttributes} from "../../common/productUtil";
import ProductService from "../../service/ProductService";
import QueryParams from "../../common/QueryParams";
import SpecialCard from "../card/SpecialCard";
import SpecialService from "../../service/SpecialService";
import {groupTerms} from "../../common/rateUtil";
import {PRODUCT_VISIBILITY} from "../../common/types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "../../common/types/productAttributeTypes";
import {PRODUCT_TYPES, productLabels} from "../../common/types/productTypes";
import MessageDialog from "../dialog/MessageDialog";
import UserService from "../../service/UserService";
import UserStatService from "../../service/UserStatService";


class VideoPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: '',
            validationErrors: {},
            questionSubmitted: false
        };


    }

    componentDidMount() {
        UserStatService.createPageLoadUserStat('VIDEO');
    }

    render() {
        return (
            <div className="curves-about-page curves-simple-page">
                
            </div>
        )
    }

}

export default VideoPage;
