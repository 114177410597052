import React, {Fragment} from 'react';
import GlobalStore from "../store/GlobalStore";
import Spinner from "../common/Spinner";
import DialogUtil from '../../common/DialogUtil';

const SimpleDialogInquiry = ({title, children, onClickOutside, className, globalStore}) => (

        <div className={"curves-simple-dialog " + className}>
            <div className="disable-all" onClick={globalStore.dialogStatus === 'INITIAL' ? onClickOutside : null}></div>
            <div className="main-inquiry">
                <div className="close-inquiry">
                        <a  onClick={DialogUtil.close} className="close-inquiry-icon"></a>
                </div>
              <div className="title"><h5>{title}</h5></div>
                <div className="body">
                    {globalStore.dialogStatus === 'LOADING' || globalStore.dialogStatus === 'RESOLVING' ?
                        <Spinner className="text-align-center" />
                    :
                        <Fragment>{children}</Fragment>
                    }
                </div>
            </div>
        </div>

);

export default GlobalStore.connect(SimpleDialogInquiry);
