import React, {Component, Fragment} from 'react';
import moment from "moment";
import EOIService from "../../service/EOIService";

class NonStandardTermForm extends Component {

    /**
     * Constructor.
     *
     * @param props
     */
    constructor(props) {

        super(props);

        this.confirm = this.confirm.bind(this);

        this.textAreaRef = React.createRef();

        this.state = {
            additionalNotes: '',
            overflowVisible: false
        };

    }

    /**
     * Component props updated.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.show !== prevProps.show) {
            if (this.props.show === true) {
                this.setState({
                    additionalNotes: '',
                    status: undefined
                });

                // not working... why???
                setTimeout(() => this.textAreaRef.current.focus(), 400);
                setTimeout(() => this.setState({overflowVisible: true}), 400);
            }
            else {
                this.setState({overflowVisible: false});
            }
        }

    }

    /**
     * Confirm.
     *
     */
    confirm() {
        EOIService.expressInterestWithNonStandardTerm(this.props.productId,
                                                      this.props.amount,
                                                      this.props.maturityDate,
                                                      this.state.additionalNotes);

        this.setState({status: 'CONFIRMED'});

    }

    /**
     * Render.
     *
     * @returns {*}
     */
    render() {

        return (
            <div className={"transition-all-400ms-ease-in-out white-space-nowrap "
                            + "position-absolute right-0 top-0 width-100per height-100per background-color-grey6 "
                            + (this.props.show ? 'max-width-100per' : 'max-width-0')
                            + (this.state.overflowVisible ? '' : ' overflow-hidden')}
                 ref={this.textAreaRef}>
                <div className="display-flex margin-bottom-10px margin-left-50px margin-right-50px margin-top-25px">
                    <div className="flex-1 font-size-3 font-weight-600">Non standard term</div>
                    <div className="flex-1 font-size-6 font-weight-300 text-align-right">
                        {moment(this.props.maturityDate).diff(moment(),'day')} days
                    </div>
                </div>
                <div className="margin-bottom-10px margin-left-50px margin-right-50px">
                    {this.state.status !== 'CONFIRMED' ?
                        <Fragment>
                            You have selected a period outside the 'standard rates'... we will need to confirm the rate
                            with the bank, and we will get back to you.
                        </Fragment>
                        :
                        <Fragment>
                            Thank you - A Curve team member will be in touch with you shortly.
                        </Fragment>
                    }
                </div>
                {this.state.status !== 'CONFIRMED' &&
                    <textarea className="width-100per-minus-100px margin-left-50px margin-right-50px margin-bottom-10px height-60px background-color-grey6"
                              onChange={e => this.setState({additionalNotes: e.target.value})}
                              value={this.state.additionalNotes} />
                }
                <div className="margin-left-50px margin-right-50px text-align-center">
                    {this.state.status !== 'CONFIRMED' ?
                        <Fragment>
                            <button className="cancel-btn margin-right-10px"
                                    onClick={this.props.close}>Cancel
                            </button>
                            <span className="hoverable position-relative">
                                <input className="accept-btn margin-left-10px"
                                       type="submit"
                                       value="Confirm"
                                       onClick={this.confirm} />
                            </span>
                        </Fragment>
                        :
                        <Fragment>
                            <input className="accept-btn"
                                   type="submit"
                                   value="Done"
                                   onClick={this.props.close} />
                        </Fragment>
                    }
                </div>
            </div>
        );

    }

}

export default NonStandardTermForm;
