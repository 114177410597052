import React from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";

const MessageDialog = ({title, message}) => (

    <SimpleDialog className="session-expired-dialog" title={title || "Message"} onClickOutside={DialogUtil.resolve}>
        <div className="message">
            <p>{message}</p>
        </div>
        <div className="options">
            <a className="confirm-btn" onClick={DialogUtil.resolve}>Accept</a>
        </div>
    </SimpleDialog>

);

export default MessageDialog;
