import axios from "axios";

class NotificationsService {

    static subscribe(token) {
        return new Promise((resolve, reject) => {
            axios.post('/api/client/fcm/subscribe', token).then(() => {
                resolve();
            });
        });
    }

    static unsubscribe(token) {
        return new Promise((resolve, reject) => {
            axios.post('/api/client/fcm/unsubscribe', token).then(() => {
                resolve();
            });
        });
    }
}

export default NotificationsService;

