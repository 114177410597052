import React, {Component, Fragment} from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";
import Spinner from "../common/Spinner";
import MaturityService from "../../service/MaturityService";

class MaturityRedeemDialog extends Component {

    constructor(props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.accept = this.accept.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.renderSuccessSubmission = this.renderSuccessSubmission.bind(this);
        this.state = {
            sending: false,
            submitOk: null,
            note: '',
            validationErrors: {},
            contract: props.contract
        };
    }

    validate() {
        let validationErrors = {};
        if (this.state.note.trim().length > 50) {
            validationErrors.note = 'Maximum length is 50 characters';
        }
        this.setState({
            validationErrors
        });

        return validationErrors;
    }

    isSubmitFail() {
        return this.state.submitOk !== null && this.state.submitOk === false;
    }

    accept() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const data = {
            note: this.state.note
        };

        this.setState({sending: true});

        MaturityService.sendRedeem(this.state.contract.id, data)
            .then((response) => {
                this.setState({submitOk: true, sending: false});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false});
            });
    }


    renderForm() {
        return (
            <Fragment>
                <div className="maturity-redeem">
                    <div className="form-section">
                        {this.isSubmitFail() &&
                        <div className="error-message">An error occurs, please try again later</div>
                        }
                        <div className="form-group">
                            <label>Comments (For Internal Purposes/Curve Portfolio Manager)</label>
                            <textarea type="text" value={this.state.note} onChange={e => this.setState({note: e.target.value})}/>
                            <div className="validation-errors">{this.state.validationErrors.note}</div>
                        </div>

                        {this.state.sending && <div className="general-inquiry-sending"><Spinner/></div>}
                        <div className="validation-errors">{this.state.validationErrors.error}</div>
                    </div>
                </div>
                <div className="options">
                    <a className="confirm-btn" onClick={DialogUtil.close}>Cancel</a>
                    <a className="confirm-btn" onClick={this.accept}>Submit</a>
                </div>
            </Fragment>
        )
    }

    renderSuccessSubmission() {
        return (
            <div>
                <div className="success-message-container">
                    <h3>Sent message</h3>
                    <p>Thank you - A Curve team member will be in touch with you shortly.</p>
                    <div className="button-container">
                        <button className="accept-btn" onClick={DialogUtil.resolve}>DONE</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <SimpleDialog className="modal-lg redeem-dialog" title="Your Curve Representative will process your redemption request immediately" onClickOutside={DialogUtil.close}>
                {this.state.submitOk ? this.renderSuccessSubmission() : this.renderForm()}
            </SimpleDialog>
        );
    }

}

export default MaturityRedeemDialog;
