import React, { Component } from 'react';

import Header from "./Header";
import GlobalStore from "../store/GlobalStore";
import SessionExpiredDialog from "./SessionExpiredDialog";
import Footer from "./Footer";
import Body from "./Body";
import {withRouter} from "react-router-dom";
import SvgImages from "./SvgImages";
import {currentRoute} from "../config/routes";
import UserStatService from "../../service/UserStatService";

class AppContainer extends Component {


    constructor(props) {
        super(props);
        this.listenScrollEvent = this.listenScrollEvent.bind(this);

        this.state = {
            headerClass: "h-scrolling-false",
            scrollPosition: 0,
        };

    }

	listenScrollEvent(e) {
		var isChromium = window.chrome;
		var winNav = window.navigator;
		var vendorName = winNav.vendor;
		var isOpera = typeof window.opr !== "undefined";
		var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
		var isIOSChrome = winNav.userAgent.match("CriOS");

		var isChromeCondition = isChromium !== null &&
		  typeof isChromium !== "undefined" &&
		  vendorName === "Google Inc." &&
		  isOpera === false &&
		  isIEedge === false;

		//if( isChromeCondition ){
			if (  this.state.scrollPosition !== window.scrollY){
				const productContainer = document.getElementsByClassName('product-list')[0];
		        const productContainerOffset = productContainer && productContainer.offsetTop;
		        const baseCorrection = 150;
		        const gap = 30;//this avoid error.
				if ( window.scrollY >= (productContainerOffset - baseCorrection) && this.state.headerClass !== 'h-scrolling-true' ) {
					this.setState({headerClass: 'h-scrolling-true', scrollPosition: window.scrollY})
				} else if (window.scrollY < (productContainerOffset - (baseCorrection + gap)) && this.state.headerClass !== 'h-scrolling-false') {
					this.setState({headerClass: 'h-scrolling-false', scrollPosition: window.scrollY })
		    	}
			}
		//}

	}

	trackUserActivity()  {
        localStorage.setItem('lastActivity', new Date().getTime().toString());
    };

	componentDidMount() {
    	window.addEventListener('scroll', this.trackUserActivity, false);
    	window.addEventListener('mousemove', this.trackUserActivity, false);
		window.addEventListener('scroll', this.listenScrollEvent);

        setInterval(() => {
            if((new Date()).getTime() - Number(localStorage.getItem('lastActivity')) < 60000) {
                UserStatService.postActivity(new Date(Number(localStorage.getItem('lastActivity'))));
            }
        }, 60000);
	}

    render() {
        const activeRoute = currentRoute(this.props.location.pathname);
        const fullWidthPage =  Array.isArray(activeRoute) && activeRoute.length>0 && activeRoute[0].fullWidth;
        const preview = Array.isArray(activeRoute) && activeRoute.length>0 && activeRoute[0].preview;
    	if(fullWidthPage){
    		return (
                <div className={"curves-full-width-page "+(preview?"preview-page":"")} >
                    <Body fullWidth={fullWidthPage}/>
                    <SvgImages />
				</div>
			)
		}
        return (
            <div className={"curves-app-container "+this.state.headerClass} >
                <Header/>

                <Body/>

                <Footer/>

                {this.props.globalStore.sessionExpired && <SessionExpiredDialog />}

                {this.props.globalStore.openDialog}

                <SvgImages />
            </div>
        )
    }

}

export default withRouter(GlobalStore.connect(AppContainer));
