import axios from "axios";


class MaturityService {

    static getMaturities(productType) {
        return new Promise((resolve, reject) => {
            axios.get('/api/client/maturities/' + productType).then(response => {
                resolve(response.data);
            });
        });
    }

    static sendRequestRedeem(data) {
        return axios.post('/api/client/maturities/request_redeem', data);
    }

    static sendRedeem(contractId, data) {
        return axios.post('/api/client/maturities/' + contractId + '/redeem', data);
    }

    static sendInquiry(inquiry) {
        return axios.post('/api/client/maturities/inquiry', inquiry);
    }

    static requestRate(eoi) {
        return axios.post('/api/client/maturities/request_rate', eoi);
    }

    /**
     * Get Maturity by Contract id for Preview
     * This function only is called from admin side to show preview of maturities.
     * @param contractId
     * @returns {Promise<unknown>}
     */
    static getMaturityByContractIdForPreview(contractId){
        return new Promise((resolve, reject) => {
            axios.get('/api/admin/maturities/contract/' + contractId).then(response => {
                resolve(response.data);
            });
        });
    }
}

export default MaturityService;
