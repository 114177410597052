import React, {Component, Fragment} from "react";
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";

import {cleanProductAttributes} from "../../common/productUtil";
import Spinner from "../common/Spinner";
import FlexProductTable from "../table/FlexProductTable";

import {TERM_GROUP_WITH_RANGES, TERM_GROUP_WITH_RANGES_FOR_LIST_VIEW} from "../../common/rateUtil";
import MaxYieldsForListView from "./MaxYieldsForListView";

class ProductsListView extends Component {

    constructor(props) {
        super(props);
        this.listenProductListScrollEvent = this.listenProductListScrollEvent.bind(this);
        this.listenMaxYieldScrollEvent = this.listenMaxYieldScrollEvent.bind(this);
        this.toggleExpandMaxYieldGroup = this.toggleExpandMaxYieldGroup.bind(this);
    }

    componentDidMount() {
        GlobalStore.setState({
            productScroll: 0,
        });
    }

    toggleExpandMaxYieldGroup(termGroup){
        let newstate = {...this.state.expandedGroupTerms};
        newstate[termGroup] = !(newstate[termGroup]);
        this.setState({expandedGroupTerms: (newstate) });
    }


    listenMaxYieldScrollEvent(scrollVal){
        //console.log('Scroll event MAXYIELD detected! Scroll: '+scrollVal);
        GlobalStore.setState({
            productScroll: scrollVal,
        });
        this.refs.productList.scrollLeft = scrollVal;
    }

    listenProductListScrollEvent(){
        //console.log('Scroll event PRODUCT detected! Scroll: '+this.refs.productList.scrollLeft);
        if(this.refs.productList.scrollLeft!=this.props.globalStore.productScroll){
            GlobalStore.setState({
                productScroll: this.refs.productList.scrollLeft,
            });
        }
    }

    render() {
        return (
            <Fragment>
                <MaxYieldsForListView key={this.props.productsHash}
                                      productScroll={this.props.globalStore.productScroll}
                                      handleScroll={this.listenMaxYieldScrollEvent}
                                      products={this.props.products}
                                      bankBillSwaps={this.props.globalStore.bankBillSwaps}
                                      includeStringTerms={true}
                                      groupedTerms={this.props.groupedTerms}
                                      toggleExpandMaxYieldGroup={this.toggleExpandMaxYieldGroup}
                />
                <div className="product-list" onScroll={this.listenProductListScrollEvent} ref="productList">
                    <div className="product-list-inner-container">
                        {this.props.status === 'LOADED' &&
                        <Fragment>
                            <FlexProductTable
                                termDeposits={true}
                                products={cleanProductAttributes(this.props.sortedProducts(), true)}
                                groupedTerms={this.props.groupedTerms}
                            />
                        </Fragment>
                        }
                        {this.props.status === 'LOADING' &&
                            <Spinner/>
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
} export default withRouter(GlobalStore.connect(ProductsListView), ProductsListView);