import {getEnvParam} from "./getEnvParam";

const bankImage = [
    {
        bank_name: "Adelaide Bank",
        image_name: ""
    },
    {
        bank_name: "Agricultural Bank of China",
        image_name: ""
    },
    {
        bank_name: "AMP Bank Limited (NCD)",
        image_name: "amp_bank.png"
    },
    {
        bank_name: "AMP Bank Ltd",
        image_name: "amp_bank.png"
    },
    {
        bank_name: "ANZ Banking Group Ltd",
        image_name: "anz.png"
    },
    {
        bank_name: "Arab Bank Australia",
        image_name: "arab_bank_australia.png"
    },
    {
        bank_name: "Armidale DDF",
        image_name: ""
    },
    {
        bank_name: "Australian Central Credit Union",
        image_name: ""
    },
    {
        bank_name: "Australian Military Bank",
        image_name: "australian_military_bank.png"
    },
    {
        bank_name: "Australian Unity Bank",
        image_name: ""
    },
    {
        bank_name: "Auswide Bank Ltd",
        image_name: "auswide_bank.png"
    },
    {
        bank_name: "Bananacoast Community Credit Union",
        image_name: "BCU.png"
    },
    {
        bank_name: "Bank Australia Limited",
        image_name: "bank_australia.png"
    },
    {
        bank_name: "Bank First",
        image_name: "bankfirst.png"
    },
    {
        bank_name: "Bank of China (RTGS ONLY)",
        image_name: ""
    },
    {
        bank_name: "Bank of China Limited",
        image_name: ""
    },
    {
        bank_name: "Bank of Cyprus Australia",
        image_name: ""
    },
    {
        bank_name: "Bank of Melbourne",
        image_name: ""
    },
    {
        bank_name: "Bank of Queensland",
        image_name: "boq.png"
    },
    {
        bank_name: "Bank of Sydney Ltd",
        image_name: "bank_of_sydney.png"
    },
    {
        bank_name: "Bank of Us",
        image_name: "bank_of_us.png"
    },
    {
        bank_name: "Bank SA",
        image_name: ""
    },
    {
        bank_name: "BankVic",
        image_name: "bankvic.png"
    },
    {
        bank_name: "BankVic RTGS",
        image_name: "bankvic.png"
    },
    {
        bank_name: "Bankwest",
        image_name: ""
    },
    {
        bank_name: "Bankwest (CFS)",
        image_name: ""
    },
    {
        bank_name: "Bankwest WA",
        image_name: ""
    },
    {
        bank_name: "Bendigo and Adelaide Bank",
        image_name: "bendingo_and_abelaide_bank.png"
    },
    {
        bank_name: "Beyond Bank Australia",
        image_name: "beyond_bank.png"
    },
    {
        bank_name: "BNP Paribas",
        image_name: ""
    },
    {
        bank_name: "BOQ Specialist Bank Limited",
        image_name: "boq.png"
    },
    {
        bank_name: "BT Panorama",
        image_name: ""
    },
    {
        bank_name: "Central Coast Credit Union",
        image_name: ""
    },
    {
        bank_name: "Central West Credit Union Limited",
        image_name: ""
    },
    {
        bank_name: "Citigroup Pty Ltd",
        image_name: ""
    },
    {
        bank_name: "Coastline Credit Union Limited",
        image_name: "Coastline.png"
    },
    {
        bank_name: "Commonwealth Bank of Australia",
        image_name: ""
    },
    {
        bank_name: "Commonwealth Bank of Australia - Deposit Plus",
        image_name: ""
    },
    {
        bank_name: "Credit Union Australia",
        image_name: "cua.png"
    },
    {
        bank_name: "Credit Union SA Ltd",
        image_name: "cua.png"
    },
    {
        bank_name: "Defence Bank",
        image_name: "defence_bank.png"
    },
    {
        bank_name: "E. SUN Commercial Bank Ltd",
        image_name: ""
    },
    {
        bank_name: "ECU Australia Ltd",
        image_name: ""
    },
    {
        bank_name: "EECU Ltd",
        image_name: ""
    },
    {
        bank_name: "Encompass Credit Union",
        image_name: ""
    },
    {
        bank_name: "Family First Credit Union",
        image_name: ""
    },
    {
        bank_name: "First Commercial Bank",
        image_name: ""
    },
    {
        bank_name: "First Option Bank Limited",
        image_name: ""
    },
    {
        bank_name: "G & C Mutual Bank",
        image_name: ""
    },
    {
        bank_name: "Gateway Bank Limited",
        image_name: "gateway_bank.png"
    },
    {
        bank_name: "Goldfields Money Limited",
        image_name: "goldfields_money.png"
    },
    {
        bank_name: "Greater Bank",
        image_name: ""
    },
    {
        bank_name: "Heritage Bank",
        image_name: "heritage_bank.png"
    },
    {
        bank_name: "Heritage Bank RTGS",
        image_name: "heritage_bank.png"
    },
    {
        bank_name: "Holiday Coast Credit Union",
        image_name: ""
    },
    {
        bank_name: "Hume Bank",
        image_name: ""
    },
    {
        bank_name: "Hunter United Employees Credit Union",
        image_name: "hunter_united.png"
    },
    {
        bank_name: "Illawarra Credit Union Limited",
        image_name: ""
    },
    {
        bank_name: "IMB",
        image_name: ""
    },
    {
        bank_name: "Industrial and Commercial Bank of China Ltd",
        image_name: ""
    },
    {
        bank_name: "ING Bank (Australia)",
        image_name: "ing.png"
    },
    {
        bank_name: "ING Bank N.V., Sydney Branch",
        image_name: "ing.png"
    },
    {
        bank_name: "ING Bank Treasury (Australia)",
        image_name: "ing.png"
    },
    {
        bank_name: "ING Direct Advisers",
        image_name: "ing.png"
    },
    {
        bank_name: "Intech Bank Ltd",
        image_name: ""
    },
    {
        bank_name: "Judo Bank",
        image_name: "judobank.png"
    },
    {
        bank_name: "Macquarie Bank - Melb - Closed",
        image_name: "macquarie.png"
    },
    {
        bank_name: "Macquarie Bank Ltd (BFS Group)",
        image_name: "macquarie.png"
    },
    {
        bank_name: "Macquarie Bank Treasury",
        image_name: "macquarie.png"
    },
    {
        bank_name: "Manly Warringah Credit Union Limited",
        image_name: ""
    },
    {
        bank_name: "ME Bank",
        image_name: "me.png"
    },
    {
        bank_name: "Mega International Commercial Bank Co., Ltd",
        image_name: ""
    },
    {
        bank_name: "MUFG Bank",
        image_name: ""
    },
    {
        bank_name: "MyMove",
        image_name: ""
    },
    {
        bank_name: "MyState Bank Limited",
        image_name: "mystate_bank.png"
    },
    {
        bank_name: "NAB",
        image_name: "nab.png"
    },
    {
        bank_name: "NAB Flexi",
        image_name: "nab.png"
    },
    {
        bank_name: "NAB NCD",
        image_name: "nab.png"
    },
    {
        bank_name: "Newcastle Permanent Building Society",
        image_name: ""
    },
    {
        bank_name: "P&N Bank",
        image_name: "pnn_bank.png"
    },
    {
        bank_name: "People's Choice Credit Union",
        image_name: "peoples_choice.png"
    },
    {
        bank_name: "Police Bank Ltd",
        image_name: "police_bank.png"
    },
    {
        bank_name: "Police Credit Union Limited (SA)",
        image_name: "police_credit_union_limited.png"
    },
    {
        bank_name: "QBANK",
        image_name: ""
    },
    {
        bank_name: "Qudos Bank",
        image_name: "qudos_bank.png"
    },
    {
        bank_name: "Queensland Country Credit Union Limited",
        image_name: ""
    },
    {
        bank_name: "Queensland Teachers Mutual Bank",
        image_name: "Teachers_Mutual_Bank_logo.png"
    },
    {
        bank_name: "Rabo Bank Australia Sydney Branch",
        image_name: ""
    },
    {
        bank_name: "Rabobank Australia",
        image_name: ""
    },
    {
        bank_name: "RaboDirect",
        image_name: ""
    },
    {
        bank_name: "Regional Australia Bank",
        image_name: ""
    },
    {
        bank_name: "Rural Bank",
        image_name: "rural_bank.png"
    },
    {
        bank_name: "Sample ADI",
        image_name: ""
    },
    {
        bank_name: "Shinhan Bank Sydney Branch",
        image_name: ""
    },
    {
        bank_name: "South-West Credit Union Co-operative Limited",
        image_name: ""
    },
    {
        bank_name: "St George Bank",
        image_name: ""
    },
    {
        bank_name: "St George Bank",
        image_name: ""
    },
    {
        bank_name: "Summerland Financial Services Limited",
        image_name: ""
    },
    {
        bank_name: "Suncorp",
        image_name: "suncorp.png"
    },
    {
        bank_name: "Taiwan Business Bank",
        image_name: ""
    },
    {
        bank_name: "Taiwan Cooperative Bank, Ltd",
        image_name: ""
    },
    {
        bank_name: "Teachers Mutual Bank Limited",
        image_name: "Teachers_Mutual_Bank_logo.png"
    },
    {
        bank_name: "Teachers Mutual Bank RTGS Ltd",
        image_name: "Teachers_Mutual_Bank_logo.png"
    },
    {
        bank_name: "Territory Insurance Office",
        image_name: ""
    },
    {
        bank_name: "The Capricornian",
        image_name: "the_capricornian.png"
    },
    {
        bank_name: "The Mutual Bank",
        image_name: "The Mutual Bank.png"
    },
    {
        bank_name: "The Rock Building Society",
        image_name: ""
    },
    {
        bank_name: "The Rock Building Society NCD",
        image_name: ""
    },
    {
        bank_name: "The University Credit Society Limited",
        image_name: ""
    },
    {
        bank_name: "Unity Bank Limited",
        image_name: ""
    },
    {
        bank_name: "Volt Bank Limited",
        image_name: ""
    },
    {
        bank_name: "Warwick Credit Union Ltd",
        image_name: ""
    },
    {
        bank_name: "WAW Credit Union Co-operative Limited",
        image_name: ""
    },
    {
        bank_name: "Westpac",
        image_name: ""
    },
    {
        bank_name: "Westpac (Tailored)",
        image_name: ""
    }
];

// Fist check if logo is present from admin settings otherwise try to resolve logo from local images
export const getBankImageUrl = (bankName, bankLogo) => {
    if (bankLogo) {
        return `/getImage/${bankLogo}/` + window.location.search;
    } else {
        const fileName = bankImage.find(image => image.bank_name === bankName);
        //return fileName!==undefined?window.location.protocol+'//'+window.location.hostname+':'+window.location.port+'/public/images/banks/'+fileName.image_name:null;
        return fileName && fileName.image_name !== '' ? ('/img/banks/' + fileName.image_name) : null;
    }
};

