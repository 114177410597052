import React, { Component } from 'react';

import Checkbox from '../common/Checkbox';

class CheckboxGroup extends Component {

    constructor(props) {

        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = { open: this.props.options.some(option => this.props.checkedValues.indexOf(option.value) !== -1)};

    }

    /**
     * Executed after rendered only if props or state have changed.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    /*componentDidUpdate(prevProps, prevState, snapshot) {

        /**
         * If query parameters have changed, refresh list data.
         */
        /*if (this.props.location.search !== prevProps.location.search) {
            const filters = this.getFiltersFromQueryParams();
            this.setState({ filters, checkboxGroupOpen: Filters.checkboxGroupOpen(filters) });
        }

    }*/

    toggle() {
        this.setState({
            open: !this.state.open,
            animate: true
        });
    }


    render() {

        return (
            <div className={"curves-checkbox-group" + (this.state.open ? " open " : " closed ") + (this.state.animate ? " animate " : " ")}>
                <div className="title" onClick={this.toggle}>
                    <svg><use xlinkHref={this.state.open ? '#curves-svg-triangle-up' : '#curves-svg-triangle-down'}></use></svg>
                    {this.props.title}
                </div>
                <div className="checkboxes">
                    {this.props.options.map((option, idx) =>
                        <div key={idx}>
                            <Checkbox label={option.label}
                                      value={option.value}
                                      onChange={this.props.onChange}
                                      checked={this.props.checkedValues.indexOf(option.value) !== -1} />
                        </div>)}
                </div>
            </div>
        )

    }

}

export default CheckboxGroup;
