import axios from "axios";


class ProductVariableService {

    static getBankBillSwaps() {
        return axios.get('/api/client/product_variables/bank_build_swaps');
    }

}

export default ProductVariableService;
