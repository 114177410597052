import {Component, Fragment} from "react";
import {formatProductsCSVView, getProductsAttributesNames} from "../../common/productUtil";
import {hasTermDepositBehavior, PRODUCT_TYPES, productLabels} from "../../common/types/productTypes";
import queryString from "query-string";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import termLabel from "../../common/termLabel";
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";
import React from "react";
import {PRODUCT_ATTRIBUTE} from "../../common/types/productAttributeTypes";
import moment from "moment";

class ProductsExportCSV extends Component {

    constructor(props) {
        super(props);
        this.exportData = this.exportData.bind(this);
    }

    exportData() {
        const filteredProducts = this.props.products;
        const termDepositBehaviour = filteredProducts && filteredProducts[0] && hasTermDepositBehavior(filteredProducts[0]);
        if(termDepositBehaviour) {
            const urlQueryParams = queryString.parse(this.props.location.search);
            const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');
            filteredProducts.forEach((product) => {
                product.attributes = product.attributes.filter(it => (filterTerms.length === 0 || filterTerms.indexOf(it.name) !== -1));
            });
        }
        const isNCD = this.props.productType === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT;

        const attributeNames = getProductsAttributesNames(filteredProducts, false);

        let titles = ["Institution"];
        if (isNCD) {
            titles.push("Availability")
        }
        attributeNames.forEach(title => {titles.push ((title.value_type === PRODUCT_ATTRIBUTE.TERM ? termLabel(title.name) : title.name))});
        //Replace title Bonus to Margin
        titles = titles.map(function(item) { return item === 'Bonus' ? 'Margin' : item; });

        const rows = formatProductsCSVView(filteredProducts, attributeNames);

        let csvContent = "data:text/csv;charset=utf-8,"
            + "Yieldhub Rates - "+productLabels(this.props.productType) + ",".repeat(titles.length - 1) + "\n"
            + "Date:,"+ moment().format('YYYY-MM-DD') + ",".repeat(titles.length - 2) + "\n"
            + ",".repeat(titles.length - 1) + "\n"
            + titles.join(",") + "\n"
            + rows.map(e => e.join(",")).join("\n") + "\n"
            + "Private and Confidential Information - Not for distribution." +",".repeat(titles.length - 1)+"\n"
            + "Copyright © "+ moment().format("YYYY") + " YieldHub Pty Ltd. All rights reserved." + ",".repeat(titles.length - 1)+"\n";

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "YieldHub "+productLabels(this.props.productType)+" "+moment().format('DD-MM-YYYY')+".csv");
        document.body.appendChild(link);

        link.click();
    }

    render() {
        return (
            <Fragment>
                {this.props.globalStore.user.yieldhub_data_export &&
                <button className="export-data-btn" onClick={() => this.exportData()}>Export</button>
                }
            </Fragment>
        )
    }
}



export default withRouter(GlobalStore.connect(ProductsExportCSV), ProductsExportCSV);
