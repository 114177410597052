import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import GlobalStore from "../store/GlobalStore";
import Menu from "./Menu";
import moment from 'moment'
import {Link} from "react-router-dom";
import LiveChatButton from "./LiveChatButton";


class Header extends Component {

    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

	componentDidMount(){
	    GlobalStore.setState({
	    	menuOpen: false,
	    	filtersMenuOpen: false,
	    	userMenuOpen: false,
	    });
	}

    toggleMenu(stateName) {
    	GlobalStore.setState({
    		menuOpen: (stateName == "menuOpen")? !this.props.globalStore[stateName] : false,
	    	filtersMenuOpen: (stateName == "filtersMenuOpen")? !this.props.globalStore[stateName] : false,
	    	userMenuOpen: (stateName == "userMenuOpen")? !this.props.globalStore[stateName] : false,
	    });
    }

    currentDate(){
        return moment(new Date()).format("Do MMMM YYYY");
    }

    userProfileHTML(){
        return (
			<div className="user-datetime-now">{this.currentDate()}</div>
        );
    }

    render() {
	    return (
	        <div className="curves-header">
	            <div className="first-row">
	                <div className="first-column">
						<Link to={'/'}><img src="/img/Yieldhub_Primary_Logo_Trimmed.svg" /></Link>
	                </div>
	                <div className="second-column">
	                	<Menu />
	                </div>
	                <div className={"third-column view-open-"+this.props.globalStore.userMenuOpen}>
						{this.props.globalStore.user && this.userProfileHTML()}
	                </div>
	            </div>
	            <div className="mobile-row">
	            	 <div className="menu-btn mobile-btn" onClick={() => this.toggleMenu("menuOpen")} >
	            	 	<svg><use xlinkHref={this.props.globalStore.menuOpen ? '#curves-svg-arrow-up' : '#curves-svg-menu'}></use></svg>
	            	 </div>
	            	 <div className="filters-btn mobile-btn" onClick={() => this.toggleMenu("filtersMenuOpen")} >
	            	 	<svg><use xlinkHref={this.props.globalStore.filtersMenuOpen ? '#curves-svg-arrow-up' : '#curves-svg-filters'}></use></svg>
	            	 </div>
	            	 <div className="user-btn mobile-btn" onClick={() => this.toggleMenu("userMenuOpen")} >
	            	 	<svg><use xlinkHref={this.props.globalStore.userMenuOpen ? '#curves-svg-arrow-up' : '#curves-svg-user'}></use></svg>
	            	 </div>
	            </div>
	        </div>
        )
    }

}

export default withRouter(GlobalStore.connect(Header));
