import {cleanProductAttributes} from "../../common/productUtil";
import ProductCard from "../card/ProductCard";
import React, {Component, Fragment} from "react";
import FlexProductTable from "../table/FlexProductTable";
import Spinner from "../common/Spinner";
import {withRouter} from "react-router-dom";
import {TERM_GROUP_WITH_RANGES, TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW} from "../../common/rateUtil";
import GlobalStore from "../store/GlobalStore";
import ProductsCardView from "./ProductsCardView";
import ProductsListView from "./ProductsListView";

class ProductAndYieldsView extends Component {

    constructor(props) {
        super(props);
        this.listenProductListScrollEvent = this.listenProductListScrollEvent.bind(this);
        this.listenMaxYieldScrollEvent = this.listenMaxYieldScrollEvent.bind(this);
        this.toggleExpandMaxYieldGroup = this.toggleExpandMaxYieldGroup.bind(this);
        let parentGroupTerms = TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW.filter(function (item){
            return item.show;
        });
        let expandedGroupTerms = {};
        parentGroupTerms.forEach(function (termGroup){
            expandedGroupTerms[termGroup.name] = false;
        });
        this.state = {
            expandedGroupTerms: expandedGroupTerms
        }
    }

    componentDidMount() {
        GlobalStore.setState({
            productScroll: 0,
        });
    }

    toggleExpandMaxYieldGroup(termGroup){
        let newstate = {...this.state.expandedGroupTerms};
        newstate[termGroup] = !(newstate[termGroup]);
        this.setState({expandedGroupTerms: (newstate) });
    }


    listenMaxYieldScrollEvent(scrollVal){
        //console.log('Scroll event MAXYIELD detected! Scroll: '+scrollVal);
        GlobalStore.setState({
            productScroll: scrollVal,
        });
        this.refs.productList.scrollLeft = scrollVal;
    }

    listenProductListScrollEvent(){
        //console.log('Scroll event PRODUCT detected! Scroll: '+this.refs.productList.scrollLeft);
        if(this.refs.productList.scrollLeft!=this.props.globalStore.productScroll){
            GlobalStore.setState({
                productScroll: this.refs.productList.scrollLeft,
            });
        }
    }

    render() {
            if(this.props.cardView){
                return(
                    <ProductsCardView
                        productsHash={this.props.productsHash}
                        products={this.props.products}
                        groupedTerms={this.props.groupedTerms}
                        sortedProducts={this.props.sortedProducts}
                        expressInterest={this.props.expressInterest}
                        openProductId={this.props.openProductId}
                        status={this.props.status}
                    />
                );
            } else {
                return(
                    <ProductsListView
                        productsHash={this.props.productsHash}
                        products={this.props.products}
                        groupedTerms={this.props.groupedTerms}
                        sortedProducts={this.props.sortedProducts}
                        expressInterest={this.props.expressInterest}
                        openProductId={this.props.openProductId}
                        status={this.props.status}
                    />
                );
            }
    }
} export default withRouter(GlobalStore.connect(ProductAndYieldsView), ProductAndYieldsView);