import axios from "axios";
import GlobalStore from "../client/store/GlobalStore";


class ProductService {

    static getProducts(filters) {
        return axios.get('/api/client/products',{params:filters});
    }

    static getLastUpdateDateTime() {
        return new Promise((resolve) => {
            axios.get('/api/client/products/last-update-datetime').then(response => {
                resolve(response.data);
            });
        });

    }
}

export default ProductService;
