import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";
import QueryParams from "../../common/QueryParams";

import MaxYieldGroup from "./MaxYieldGroup.js";

import {bestRates, groupTerms, groupGroupedTerms, groupTermsForCardView} from "../../common/rateUtil";
import {groupBankBillSwaps} from "../../common/bankBillSwapUtil";
import {PRODUCT_TYPES} from "../../common/types/productTypes";

class MaxYieldsForCardView extends Component {

    constructor(props) {
        super(props);
        this.listenScrollEvent = this.listenScrollEvent.bind(this);
        this.renderAvailabilitySorter = this.renderAvailabilitySorter.bind(this);
    }

    listenScrollEvent(){
        //console.log('Scroll event MAXYIELDS 2 detected! Scroll: '+this.props.globalStore.productScroll);
        //console.log('Scroll value: '+this.refs.maxYields.scrollLeft);
        if(this.refs.maxYields.scrollLeft!=this.props.globalStore.productScroll){
            this.props.handleScroll(this.refs.maxYields.scrollLeft);
        }
    }

    componentDidUpdate(){
        this.refs.maxYields.scrollLeft = this.props.globalStore.productScroll;
        //console.log("max yields scroll left "+this.refs.maxYields.scrollLeft);
    }


    renderAvailabilitySorter(isNCD, cardView) {
        if (isNCD && !cardView) {
            const isSortedByAvailability = QueryParams.get(this.props, 'sortAvailability') !== undefined;
            return (
                <div className="availability-sort" tooltip="Show NCDs currently available first" flow="right">
                    <div className={isSortedByAvailability ? "traffic-light-box active" : "traffic-light-box"} onClick={() => {
                        if (!isSortedByAvailability) {
                            const sortAvailability = "true";
                            QueryParams.change(this.props, { sortAvailability, order: undefined, sort: undefined });
                        } else {
                            QueryParams.change(this.props, {sortAvailability: undefined});
                        }
                    }}>
                        <div className="traffic-light green-light"></div>
                        <div className="traffic-light yellow-light"></div>
                        <div className="traffic-light red-light"></div>
                    </div>
                </div>
            )
        }
    }
    render() {
        let filterTermsActive = this.props.location.search.includes("filterTerm");
        let maxYields = groupTermsForCardView(
            bestRates(this.props.products, this.props.includeStringTerms, this.props.location, false, false),
            filterTermsActive,
            this.props.location.search
        );
        let groupedMaxYields = null;
        if(filterTermsActive){
            // if filtering by term, we remove the expansion capabilities
            // Filter out the 6-8 and 9-11
            groupedMaxYields = maxYields;
        } else {
            groupedMaxYields = groupGroupedTerms(maxYields, true);
        }
        let bbsw = groupBankBillSwaps(this.props.bankBillSwaps, this.props.location, true);
        const isNCD = this.props.products[0]?(this.props.products[0].product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT):false;
        const className = ("curves-max-yields "+"")+(isNCD?"ncd":"");
        var filler1stColumn = null;
        var filler2ndColumn = null;
        return (
            <div className={className} onScroll={this.listenScrollEvent} ref="maxYields" >
                <div className="curves-max-yields-inner-container">
                    <div className="title">
                        <div className="term">
                            DURATION
                        </div>
                        <div className="rate">
                            BENCHMARK
                        </div>
                        <div className="rate">
                            MAX YIELD
                        </div>
                    </div>
                    <div className="rates">
                        {filler1stColumn}
                        {filler2ndColumn}
                        {this.renderAvailabilitySorter(isNCD,true)}
                        {groupedMaxYields && groupedMaxYields.length > 0 && groupedMaxYields.map((it, idx) => {
                                return <MaxYieldGroup parentTerm={it}
                                                      bbsw={bbsw}
                                                      groupedTerms={this.props.groupedTerms}
                                                      isParentExpanded={this.props.expandedGroupTerms[it.name]}
                                                      toggleExpandMaxYieldGroup={this.props.toggleExpandMaxYieldGroup}
                                        />;
                            }
                        )}
                        {/* Hack for Last update column */}
                        <div className="rate-group"></div>
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(GlobalStore.connect(MaxYieldsForCardView), MaxYieldsForCardView);
