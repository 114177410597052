/**
 * Get query param when it's an array.
 *
 * @param urlQueryParams
 * @param param
 * @returns {*|boolean|*[]|Array}
 */
export default (urlQueryParams, param) =>
    urlQueryParams[param]
    && (urlQueryParams[param].constructor === Array && urlQueryParams[param] || [ urlQueryParams[param] ])
    || [];
