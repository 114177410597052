import axios from "axios";
import GlobalStore from "../store/GlobalStore";

class AxiosInterceptor {

    /**
     * Install axios interceptor.
     *
     * @returns {any}
     */
    static install() {

        console.debug('AxiosInterceptor.install');

        /**
         * This interceptor handles session expired failures.
         */
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response && error.response.status === 401) {
                GlobalStore.setState({sessionExpired: true});
            }

            return Promise.reject(error);
        });

    }
}

export default AxiosInterceptor;
