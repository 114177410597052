import React, {Component, Fragment} from "react";
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";

import {cleanProductAttributes} from "../../common/productUtil";
import ProductCard from "../card/ProductCard";
import Spinner from "../common/Spinner";
import MaxYieldsForCardView from "./MaxYieldsForCardView";

import {TERM_GROUP_WITH_RANGES, TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW} from "../../common/rateUtil";


class ProductsCardView extends Component {
    constructor(props) {
        super(props);
        this.listenProductListScrollEvent = this.listenProductListScrollEvent.bind(this);
        this.listenMaxYieldScrollEvent = this.listenMaxYieldScrollEvent.bind(this);
        this.toggleExpandMaxYieldGroup = this.toggleExpandMaxYieldGroup.bind(this);
        let parentGroupTerms = TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW.filter(function (item){
            return item.show;
        });
        let expandedGroupTerms = {};
        parentGroupTerms.forEach(function (termGroup){
            expandedGroupTerms[termGroup.name] = false;
        });
        this.state = {
            expandedGroupTerms: expandedGroupTerms
        }
    }
    componentDidMount() {
        GlobalStore.setState({
            productScroll: 0,
        });
    }

    toggleExpandMaxYieldGroup(termGroup){
        let newstate = {...this.state.expandedGroupTerms};
        newstate[termGroup] = !(newstate[termGroup]);
        this.setState({expandedGroupTerms: (newstate) });
    }


    listenMaxYieldScrollEvent(scrollVal){
        //console.log('Scroll event MAXYIELD detected! Scroll: '+scrollVal);
        GlobalStore.setState({
            productScroll: scrollVal,
        });
        this.refs.productList.scrollLeft = scrollVal;
    }

    listenProductListScrollEvent(){
        //console.log('Scroll event PRODUCT detected! Scroll: '+this.refs.productList.scrollLeft);
        if(this.refs.productList.scrollLeft!=this.props.globalStore.productScroll){
            GlobalStore.setState({
                productScroll: this.refs.productList.scrollLeft,
            });
        }
    }
    render() {
        var sortedProducts = this.props.sortedProducts();
        if (sortedProducts != undefined && sortedProducts.length >0) {
            return (
                <Fragment>
                    <MaxYieldsForCardView key={this.props.productsHash}
                                          productScroll={this.props.globalStore.productScroll}
                                          handleScroll={this.listenMaxYieldScrollEvent}
                                          products={this.props.products}
                                          bankBillSwaps={this.props.globalStore.bankBillSwaps}
                                          includeStringTerms={false}
                                          groupedTerms={this.props.groupedTerms}
                                          toggleExpandMaxYieldGroup={this.toggleExpandMaxYieldGroup}
                                          expandedGroupTerms={this.state.expandedGroupTerms}
                    />
                    <div className="product-list" onScroll={this.listenProductListScrollEvent} ref="productList">
                        <div className="product-list-inner-container">

                            {this.props.status === 'LOADED' &&
                            cleanProductAttributes(sortedProducts, true).map((product) =>
                                <ProductCard key={"product-card" + JSON.stringify(product)}
                                             product={product}
                                             products={this.props.products}
                                             expressInterest={this.props.expressInterest}
                                             openProductId={this.props.openProductId}
                                             groupedTerms={this.props.groupedTerms}
                                             expandedGroupTerms={this.state.expandedGroupTerms}
                                             showOnlyOnRequestLabel={product.show_product_display_status==="On Request"}
                                             showRolloverOnlyWording={product.show_product_display_status==="Rollovers Only"}
                                />)
                            }
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return(
                <div>
                    {this.state.status === 'LOADING' &&
                    <Spinner/>}
                </div>
            )
        }
    }
} export default withRouter(GlobalStore.connect(ProductsCardView), ProductsCardView);