import React, {Component, Fragment} from 'react';
import GlobalStore from "../store/GlobalStore";
import Filters from "../layout/Filters";
import FilterTags from "../layout/FilterTags";
import queryString from "query-string";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import {withRouter} from "react-router-dom";
import RateChart from "../layout/RateChart";
import {cleanProductAttributes} from "../../common/productUtil";
import ProductService from "../../service/ProductService";
import QueryParams from "../../common/QueryParams";
import SpecialCard from "../card/SpecialCard";
import SpecialService from "../../service/SpecialService";
import {groupTerms, groupTermsForCardView} from "../../common/rateUtil";
import {PRODUCT_VISIBILITY} from "../../common/types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "../../common/types/productAttributeTypes";
import {PRODUCT_TYPES, productLabels} from "../../common/types/productTypes";
import moment from 'moment';

import {
    getLongRatingIndex,
    getShortRatingIndex
} from "../../common/bankRatingUtil";
import UserStatService from "../../service/UserStatService";
import CryptoUtil from "../../common/CryptoUtil";
import {getAvailabilityIndex} from "../../common/types/ncdAvailabilityTypes";
import AskForPushNotifications from "../notifications/AskForPushNotifications";
import BankNameFilter from "../layout/BankNameFilter";
import ProductsExportCSV from "../common/ProductsExportCSV";
import ClientMaturities from "../layout/ClientMaturities";
import ProductAndYieldsView from "../layout/ProductAndYieldsView";


class ProductsPage extends Component {

    constructor(props) {
        super(props);

        this.expressInterest = this.expressInterest.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleSidebarBtnClick = this.handleSidebarBtnClick.bind(this);
        this.switchView = this.switchView.bind(this);
        this.switchTermsCollapse = this.switchTermsCollapse.bind(this);
        this.printProducts = this.printProducts.bind(this);
        this.sortedProducts = this.sortedProducts.bind(this);

        this.state = {
            open: true,
            products: [],
            productsHash: 0,
            printContent: null,
        }
    }

    componentDidMount() {
        this.setState({status: 'LOADING'});
        this.fetchData();

        UserStatService.createPageLoadUserStat(this.props.productType /* page */);
    }

    handleSidebarBtnClick() {
    	console.log("ProductsPage::handleSidebarBtnClick");
        this.setState({ open: !this.state.open });
    }

    /**
     * Executed after rendered only if props or state have changed.
     * Fetch data here only if query params have changed from previous time the component was rendered.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * If query parameters have changed, refresh list data.
         */
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({status: 'LOADING'});
            this.fetchData();
        }
        if(this.state.printView){
            this.printProducts();
            this.setState({printView:false});
        }
        if (this.props.globalStore.productUpdated !== undefined) {
            const productUpdated = this.props.globalStore.productUpdated;
            if (prevProps.globalStore.productUpdated === undefined || productUpdated.date !== prevProps.globalStore.productUpdated.date) {
                this.fetchData();
            }
        }
    }

    expressInterest(productId) {
        this.setState({openProductId: productId});
    }

    switchView(cardView, printView = false) {
        // Reload MaxYields.js
        if (!printView) {
            UserStatService.createViewSwitchUserStat(this.props.productType, cardView ? 'list' : 'card');
        }

        QueryParams.change(this.props, {view: cardView ? 'list' : 'card'});

        this.setState({printView:printView})
    }

    switchTermsCollapse(groupTerms, printView = false) {
        if (!printView) {
            UserStatService.createTermExpandCollpseSwitchUserStat(this.props.productType, groupTerms ? 'collapse' : 'expand');
        }
        QueryParams.change(this.props, {groupedTerms: groupTerms ? 'YES' : 'NO'});
        this.setState({printView:printView})
    }

    fetchData() {
        const filters = {
            product_type:this.props.productType,
            product_category:this.props.productCategory
        };
        ProductService.getProducts(filters).then(data => {
            const permissions = (data.data && data.data.length>0) ? data.data[0].permission_dto:{};
            this.setState({
                products: this.filterProducts(data.data),
                productsHash: CryptoUtil.hashJSON(data.data),
                permissions: permissions,
                status: 'LOADED'
            });
        });
        if ([PRODUCT_TYPES.TERM_DEPOSIT,
            PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT].indexOf(this.props.productType) !== -1) {
            SpecialService.findAllByProductType(this.props.productType).then(data => {
                this.setState({
                    specials: data
                });
            });
        }
    }

    filterProducts(products){
        return products.filter(product => {

            const urlQueryParams = queryString.parse(this.props.location.search);

            /**
             * Filter by short term rating.
             */
            const filterRatingShortTermArray = getArrayQueryParam(urlQueryParams, 'filterRatingShortTerm');

            if (filterRatingShortTermArray.length > 0 && filterRatingShortTermArray.indexOf(product.bank.short_rating) === -1) {
                return false;
            }

            /**
             * Filter by long term rating.
             */
            const filterRatingLongTermArray = getArrayQueryParam(urlQueryParams, 'filterRatingLongTerm');

            if (filterRatingLongTermArray.length > 0 && filterRatingLongTermArray.indexOf(product.bank.long_rating) === -1) {
                return false;
            }

            /**
             * Filter by fossil fuel free.
             */
            const filterFossilFuelFreeArray = getArrayQueryParam(urlQueryParams, 'filterFossilFuelFree');

            if (filterFossilFuelFreeArray.length > 0 && filterFossilFuelFreeArray.indexOf(product.bank.fossil_fuel_free ) === -1) {
                return false;
            }

            /**
             * Filter by Institution Type
             */
            const filterInstitutionTypeArray = getArrayQueryParam(urlQueryParams, 'filterInstitutionType');

            if (filterInstitutionTypeArray.length > 0 && filterInstitutionTypeArray.indexOf(product.bank.financial_scheme ) === -1) {
                return false;
            }

            /**
             * Filter by min and max deposit
             */
            const filterDepositMax = QueryParams.get(this.props, 'filterDepositMax');
            const filterDepositMin = QueryParams.get(this.props, 'filterDepositMin');
            if ((filterDepositMax !== undefined && product.min_deposit >= parseInt(filterDepositMax)) ||
                (filterDepositMin !== undefined && product.max_deposit <= parseInt(filterDepositMin))) {
                return false;
            }

            /**
             * Filter by Bank name
             */
            const filterBankName = QueryParams.get(this.props, 'filterBankName');
            if (filterBankName !== undefined) {
                const search = (product.bank.name != null ? product.bank.name : '') + (product.bank.acronym != null ? product.bank.acronym : '');
                if (search.match(new RegExp(filterBankName, 'gi')) === null) {
                    return false;
                }
            }

            /**
             * Filter by terms.
             */
            const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');

            const ratesToShow = product.attributes.filter(it => (filterTerms.length === 0 || filterTerms.indexOf(it.name) !== -1)
                && (it.visibility === PRODUCT_VISIBILITY.ON_REQUEST || it.value !== null));

            return ratesToShow.length > 0;

        });
    }

    sortedProducts() {
        if (!this.state.products || this.state.products.length===0) {
            return undefined;
        }
        const currentSort = QueryParams.get(this.props, 'sort');
        const currentOrder = QueryParams.get(this.props, 'order');
        const groupedTerms = QueryParams.get(this.props, 'groupedTerms') === undefined || QueryParams.get(this.props, 'groupedTerms') === 'YES';
        var theCmpFunction = function (a, b){
                let valueA = 0;
                let valueB = 0;
                if (groupedTerms) {
                    const groupsA = groupTermsForCardView(a.attributes.filter(it => it.value_type === PRODUCT_ATTRIBUTE.TERM), false);
                    const groupA = groupsA.find(group => group.name === currentSort);
                    valueA = groupA === undefined ? -1 : groupA.value === '' ? -1 : !isNaN(groupA.value) ? parseFloat(groupA.value) : 0;

                    const groupsB = groupTermsForCardView(b.attributes.filter(it => it.value_type === PRODUCT_ATTRIBUTE.TERM), false);
                    const groupB = groupsB.find(group => group.name === currentSort);
                    valueB = groupB === undefined ? -1 : groupB.value === '' ? -1 : !isNaN(groupB.value) ? parseFloat(groupB.value) : 0;
                } else {
                    const attrA = a.attributes.find(x => x.name === currentSort);
                    const attrB = b.attributes.find(x => x.name === currentSort);
                    valueA = attrA === undefined ? -1 : attrA.value === null ? -1 : attrA.value === '' ? -1 : !isNaN(attrA.value) ? parseFloat(attrA.value) : 0;
                    valueB = attrB === undefined ? -1 : attrB.value === null ? -1 : attrB.value === '' ? -1 : !isNaN(attrB.value) ? parseFloat(attrB.value) : 0;
                }
                return currentOrder && currentOrder === 'DESC' ? (valueB - valueA) : (valueA - valueB);
            }
        if (currentSort) {
            return this.state.products.sort(theCmpFunction);
        }
        if (QueryParams.get(this.props, 'sortAvailability') !== undefined) {
            return this.state.products.sort((a, b) => {
                const availabilityA = getAvailabilityIndex(a.availability);
                const availabilityB = getAvailabilityIndex(b.availability);
                const longRatingA = getLongRatingIndex(a.bank.long_rating);
                const longRatingB = getLongRatingIndex(b.bank.long_rating);
                const shortRatingA = getShortRatingIndex(a.bank.short_rating);
                const shortRatingB = getShortRatingIndex(b.bank.short_rating);
                return availabilityA - availabilityB || shortRatingA - shortRatingB || longRatingA - longRatingB;
            });
        }
        return this.state.products;
    }

    printProducts() {
        UserStatService.createPdfClickUserStat(this.props.productType /* page */);

    	//get layouts parts & variables
    	const maxYields = ( document.getElementsByClassName('curves-max-yields')[0] )? document.getElementsByClassName('curves-max-yields')[0].innerHTML : "";
        const filters = ( document.getElementsByClassName('curves-filter-tags')[0] )? document.getElementsByClassName('curves-filter-tags')[0].innerHTML : "";
    	const chart = ( document.getElementById('curves-rate-chart') )? document.getElementById('curves-rate-chart').innerHTML : "";
        let tableItems = ( document.getElementsByClassName('product-list')[0] )? document.getElementsByClassName('product-list')[0].innerHTML : "";


        const user = this.props.globalStore.user;
        const date = moment(new Date()).format("Do MMMM YYYY");
        const title = user.title !== null && user.title !=='null'? user.title : '';
        const productTypeClass = (this.props.productType === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT?'ncd':'');

    	// Open the print window
    	const WinPrint = window.open('', '', 'left=0,top=0,width=1500,height=1800,toolbar=1,scrollbars=1,status=0');

    	WinPrint.document.write(`<!DOCTYPE html>
    	<html>
          <head>
              <link rel="stylesheet" type="text/css" href="/css/print.css?v=1">
              <title>Yieldhub - ${productLabels(this.props.productType)} as at ${date} </title>
          </head>
    	  <body id="print-view">
    	  
    	      <div class="curves-header">
    	      	  <div class="first-row">
    	      	  	  <div class="first-column">
    	      	  		  <img src="/img/Yieldhub_Primary_Logo_Trimmed.svg" />
    	      	  	  </div>
    	      	  	  <div class="second-column">
    	      	  		  <div class="header-title"><span class="strong">YH</span> <span class="normal">YIELD HUB</span></div>
                              <div class="header-info">
                                <p>Term Deposits as at ${date}</p>
                                <p>${title} ${user.first_name} ${user.last_name} (${user.email})</p>
                              </div>
                      </div>
                  </div>    
              </div>
              
    	  	  <div class="curves-filter-tags" >
    	  	  	  Term Deposits ${filters}
	  	  	  </div>
    	  	  <div id="curves-rate-chart" >
	  	  	  	  ${chart}
              </div>
              <div class="max-yields-products ${productTypeClass}">
                <div class="curves-max-yields" >
                    ${maxYields}
                </div>
                <div class="product-list" >
                    ${tableItems}
                </div>
              </div>
              <footer>
                <p>Rates are indicative at the time of publishing and are subject to change. Deals are subject to confirmation by a Curve representative.</p>
                
                <div class="disclaimer">
                    <p>DISCLAIMER</p>
                    <p>
                        This website is not intended to imply a recommendation or otherwise constitute advice in relation to financial products. It does not take into account your investment objectives, financial situation or particular needs. Before acting on any information you obtain from this website you need to consider the appropriateness of the information in lieu of your investment objectives, financial situation or needs.
                    </p>
                    <p>
                        Curve Securities or Yieldhub may receive a platform fee / commission from an institution receiving funds as a result of opportunities being sourced through the Yieldhub platform or Curve Securities.
                    </p>
                    <p>Subject to Yieldhub's Terms and Conditions</p>             
                </div>
                <p>Powered by curve</p>
                <img src="/img/Curve_Mono_Logo_White_RGB.svg" style="height: 60px; filter: invert(100%)"/>
    	      </footer>
    	  </body>
    	</html>`);

    	WinPrint.document.close();

    	// Timeout needed because chrome had an error because write was not finished and a white page is shown
		// instead. With this timeout it gives the needed time to render contents
        setTimeout(function () {
			WinPrint.focus();
			WinPrint.print();
        }, 1500);

    }

    render() {
        const cardView = QueryParams.get(this.props, 'view') === undefined || QueryParams.get(this.props, 'view') === 'card';
        const groupedTerms = QueryParams.get(this.props, 'groupedTerms') === undefined || QueryParams.get(this.props, 'groupedTerms') === 'YES';
        return (
            <div className="curves-products-page">
                {this.state.printContent ? (
                    <div id="printWrapper" style={{ display: "none" }}>
                        <iframe id="print-frame">{this.state.printContent}</iframe>
                    </div>
                    ) : (undefined)
                }
                <div className="body">
                    <div className={"first-column content-open-"+this.state.open+" view-open-"+this.props.globalStore.filtersMenuOpen}>
                        <Filters handleSidebarBtnClick={this.handleSidebarBtnClick} />
                    </div>
                    <div className="second-column">
                        <AskForPushNotifications/>
                        <div className="second-column-body">
                            {/* <GeneralInquiryForm/> */}
	                        <div className="specials-container">
                                {this.state.specials && this.state.specials.length > 0 &&
                                    <h1>Live Special Offers</h1>
                                }
	                            {this.state.specials && this.state.specials.map((special, idx) =>
		                            <div className="specials" key={idx}>
		                                <div>
		                                    <SpecialCard key={"special"+JSON.stringify(special)} product={special}
		                                                 expressInterest={this.expressInterest}
		                                                 openProductId={this.state.openProductId}/>
		                                </div>
		                            </div>
	                            )}
	                        </div>

                            {this.state.permissions && this.state.permissions.show_graph &&
                                <RateChart
                                    key={"rate_chart"+this.state.productsHash}
                                    products={this.state.products}
                                    bankBillSwaps={this.props.globalStore.bankBillSwaps}
                                    scrollToProduct={this.scrollToProduct}
                                />
                            }

                            <ClientMaturities
                                productType={this.props.productType}
                                expressInterest={this.expressInterest}
                                openProductId={this.state.openProductId}
                            />

                            <FilterTags/>
	                        <div className="header-filters">
	                            <div className="product-type">
	                                {productLabels(this.props.productType)}
	                            </div>
	                            <div className="options-container">
                                    <BankNameFilter/>
	                                <input className="view-btn" type="button" value={cardView ? "List view" : "Card view"}
	                                       onClick={() => this.switchView(cardView)}>
	                                </input>
                                    <input className="view-btn" type="button" value={groupedTerms ? "Expand Terms" : "Collapse Terms"}
                                           onClick={() => this.switchTermsCollapse(!groupedTerms)}>
                                    </input>
                                    {this.state.permissions && this.state.permissions.allow_export &&
                                        <button className="print-btn" onClick={() => this.switchView(true, true)}>
                                            PDF
                                            <svg>
                                                <use xlinkHref='#curves-svg-long-arrow-down'></use>
                                            </svg>
                                        </button>
                                    }
                                    <ProductsExportCSV
                                        products={cleanProductAttributes(this.sortedProducts(), true)}
                                        productType={this.props.productType}
                                        groupedTerms={groupedTerms}
                                    />
	                           </div>
                           </div>
                           <ProductAndYieldsView
                               productsHash={this.state.productsHash}
                               cardView={cardView}
                               products={this.state.products}
                               groupedTerms={groupedTerms}
                               sortedProducts={this.sortedProducts}
                               expressInterest={this.expressInterest}
                               openProductId={this.state.openProductId}
                               status={this.state.status}
                           />

	                    </div>{/* .second-column-body */}

                    </div>{/* .second-column */}
                </div>
            </div>
        )
    }

}

export default withRouter(GlobalStore.connect(ProductsPage), ProductsPage);
