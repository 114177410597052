const NOT_RATED = 'NR';

export const RATING_SHORT_TERM = [
    { label: 'A1+', value: 'A_1_PLUS'},
    { label: 'A1',  value: 'A_1'     },
    { label: 'A2',  value: 'A_2'     },
    { label: 'A3',  value: 'A_3'     },
    { label: 'NR',  value: 'UNRATED' }];

export const RATING_LONG_TERM = [
    { label: 'AAA',  value: 'AAA'      },
    { label: 'AAA+', value: 'AAA_PLUS' },
    { label: 'AA+',  value: 'AA_PLUS'  },
    { label: 'AA',   value: 'AA'       },
    { label: 'AA-',  value: 'AA_'      },
    { label: 'A+',   value: 'A_PLUS'   },
    { label: 'A',    value: 'A'        },
    { label: 'A-',   value: 'A_'       },
    { label: 'A-3',  value: 'A_3'      },
    { label: 'BBB+', value: 'BBB_PLUS' },
    { label: 'BBB',  value: 'BBB'      },
    { label: 'BBB-', value: 'BBB_'     },
    { label: 'BB',   value: 'BB'       },
    { label: 'BB-',  value: 'BB_'      },
    { label: 'B+',   value: 'B+'       },
    { label: 'B',    value: 'B'        },
    { label: 'B-',   value: 'B_'       },
    { label: 'CCC+', value: 'CCC_PLUS' },
    { label: 'CCC',  value: 'CCC'      },
    { label: 'CCC-', value: 'CCC_'     },
    { label: 'CC',   value: 'CC'       },
    { label: 'C',    value: 'C'        },
    { label: 'D',    value: 'D'        },
    { label: 'NR',   value: 'UNRATED'  },
    { label: 'MF',   value: 'MF'       },
    { label: 'Baa2', value: 'Baa2'     },];

export const MOODYS_RATING_LONG_TERM = [
    { label: 'A1',  value: 'A1'      },
    { label: 'A2',  value: 'A2'  },
    { label: 'A2 *-',   value: 'A2_MINUS'},
    { label: 'A3',  value: 'A3'      },
    { label: 'Aa2',   value: 'Aa2'   },
    { label: 'Aa3',    value: 'Aa3'        },
    { label: 'Baa1',   value: 'Baa1'       },
    { label: 'Baa2', value: 'Baa2' },
    { label: 'NR',  value: 'NR'      },
    { label: 'WR', value: 'WR'     },
];

export const MOODYS_RATING_SHORT_TERM = [
    { label: 'P-1',  value: 'P_1'      },
    { label: 'P-1+',  value: 'P_1_PLUS'  },
    { label: 'P-1*-',   value: 'P_1_MINUS'},
    { label: 'P-2',  value: 'P_2'      },
    { label: 'NR',  value: 'NR'      },
    { label: 'WR', value: 'WR'     },
];


export const FITCH_RATING_LONG_TERM = [
    { label: 'AA+',  value: 'AA_PLUS'      },
    { label: 'A+',  value: 'A_PLUS'      },
    { label: 'A',  value: 'A'      },
    { label: 'A-',  value: 'A_'      },
    { label: 'AA-',  value: 'AA_'  },
    { label: 'BBB',   value: 'BBB'},
    { label: 'BBB+',  value: 'BBB_PLUS'      },
    { label: 'NA',   value: 'NA'   },
    { label: 'NR',  value: 'NR'      },
    { label: 'WD', value: 'WD'     },
];

export const FITCH_RATING_SHORT_TERM = [
    { label: 'F-1',  value: 'F1'      },
    { label: 'F-1+',  value: 'F1_PLUS'  },
    { label: 'F-2',   value: 'F2'},
    { label: 'NA',   value: 'NA'   },
    { label: 'NR',  value: 'NR'      },
    { label: 'WD', value: 'WD'     },
];

export const formatShortRating = (rating) => {
    return formatRatingByType(rating,RATING_SHORT_TERM);
};

export const formatLongRating = (rating) => {
    return formatRatingByType(rating,RATING_LONG_TERM);
};

const formatSpShortRating = (rating) => {
    return formatRatingByType(rating,RATING_SHORT_TERM);
};

const formatSpLongRating = (rating) => {
    return formatRatingByType(rating,RATING_LONG_TERM);
};

const formatMoodysLongRating = (rating) => {
    return formatRatingByType(rating,MOODYS_RATING_LONG_TERM);
};

const formatMoodysShortRating = (rating) => {
    return formatRatingByType(rating,MOODYS_RATING_SHORT_TERM);
};

const formatFitchLongRating = (rating) => {
    return formatRatingByType(rating,FITCH_RATING_LONG_TERM);
};

const formatFitchShortRating = (rating) => {
    return formatRatingByType(rating,FITCH_RATING_SHORT_TERM);
};

const formatRatingByType = (rating, ratingType) => {
    const formattedRating = ratingType.find((element) => element.value === rating);
    return (formattedRating !== undefined) ? formattedRating.label : NOT_RATED;
};

const formatBankRatingPair = (short,long) => {
    if(short === NOT_RATED &&  long === NOT_RATED ){
        return NOT_RATED;
    }else{
        return (short+"/"+long);
    }
};


export const getShortRatingIndex = (rating) => {
    return RATING_SHORT_TERM.findIndex(element => element.value === rating);
};

export const getLongRatingIndex = (rating) => {
    return RATING_LONG_TERM.findIndex(element => element.value === rating);
};

/**
 * Returns full description of bank rating. Ex: A1+/AA- | P-1/Aa3 | F-1+/AA-
 * @param bank
 * @returns {string}
 */
export const getFullBankRating = (bank) => {
    let fullBankRating = [];
    if(bank) {
        fullBankRating.push(formatBankRatingPair(formatSpShortRating(bank.sp_short_rating), formatSpLongRating(bank.sp_long_rating)));
        fullBankRating.push(formatBankRatingPair(formatMoodysShortRating(bank.moodys_short_rating), formatMoodysLongRating(bank.moodys_long_rating)));
        fullBankRating.push(formatBankRatingPair(formatFitchShortRating(bank.fitch_short_rating),formatFitchLongRating(bank.fitch_long_rating)));
        if (fullBankRating.filter(it => it !== NOT_RATED).length > 0) {
            return fullBankRating.join(" | ");
        }
    }
    return NOT_RATED
};

/**
 * Returns short description of bank rating. Ex: A1/B+
 * @param bank
 * @returns {string}
 */

export const getBankRating = (bank) => {
    if(bank){
        return formatBankRatingPair(formatShortRating(bank.short_rating), formatLongRating(bank.long_rating))
    }
    return NOT_RATED;
};


export const getBankRatingTooltipMessage = () =>{
    return "S&P or Equiv. rating, for full ratings expand below - or head to About Us";
};


