import React, {Component, Fragment} from 'react';
import GlobalStore from "../store/GlobalStore";
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import NotificationsService from "../../service/NotificationsService";


class AskForPushNotifications extends Component {

    constructor(props) {
        super(props);

        this.doLater = this.doLater.bind(this);
        this.acceptFirstStep = this.acceptFirstStep.bind(this);

        this.state = {
            askForNotifications: false,
        };
    }

    doLater() {
        this.setState({askForNotifications: false});
    }

    acceptFirstStep() {
        this.setState({askForNotifications: false});
        const messaging = this.getFCMMessaging();
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                messaging.getToken().then((token) => {
                    if (token) {
                        NotificationsService.subscribe(token);
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            } else {
                NotificationsService.unsubscribe();
            }
        });
    }

    getFCMMessaging() {
        // Firebase notifications
        debugger
        if (firebase.messaging.isSupported()) {
            if (!firebase.apps.length) {
                const firebaseConfig = {
                    apiKey: "AIzaSyDnPS9k3oj0bL4UgrFbdW4mkEqykt2ny5k",
                    authDomain: "yieldhub-dev.firebaseapp.com",
                    databaseURL: "https://yieldhub-dev.firebaseio.com",
                    projectId: "yieldhub-dev",
                    storageBucket: "",
                    messagingSenderId: "495476687382",
                    appId: "1:495476687382:web:f1cde12392a5e619"
                };

                const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
                let messaging =  initializedFirebaseApp.messaging();
                messaging.usePublicVapidKey("BBFq2N6bRBON4_-mpfu8ayZ1V9N9elaijI3GGv0eAEfC91XYJ2DLlOvmkTnzeIL4H9iIbM6dA-VuoYbgEVNxCOk");
                return messaging;
            } else {
                return firebase.messaging();
            }
        } else {
            return null;
        }
    }

    componentDidMount() {
        // Firebase notifications
        const messaging = this.getFCMMessaging();
        if (messaging !== null) {
            messaging.getToken().then((currentToken) => {
                if (!currentToken) {
                    this.setState({askForNotifications: true});
                }
            });
        }
    }

    render() {
        const userProfile = this.props.globalStore.user;
        return (
            <div className={this.state.askForNotifications ? "ask-for-push-notifications visible" : "ask-for-push-notifications"}>
                <div className="ask-push-container">
                    <div className="ask-push-content">
                        <a className="close" onClick={this.doLater}/>
                        <div className="content-layout">
                            <div className="logo">
                                <img src="/img/logo_new.png" />
                            </div>
                            <div className="message">
                                <p>Hi {userProfile.first_name},</p>
                                <p>YieldHub would like to send specials and maturities notifications.</p>
                                <p>Click Allow to subscribe.</p>
                            </div>
                        </div>
                        <div className="buttons-container">
                            <button onClick={this.doLater}>I'll do this later</button>
                            <button onClick={this.acceptFirstStep}>Allow</button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


}

export default GlobalStore.connect(AskForPushNotifications);
