import React, {Component, Fragment} from 'react';
import queryString from "query-string";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import EOIForm from "./EOIForm";
import {withRouter} from "react-router-dom";
import {
    groupOnRequestRates,
    groupTermsForCardView,
    isTermHighlighted,
    isTermRateOnRequest,
    ON_REQUEST_LABEL
} from "../../common/rateUtil";
import {calculateTimeDiff, formatRate} from "../../common/formatUtil";
import {
    getBankRatingTooltipMessage,
    getBankRating
} from "../../common/bankRatingUtil";
import {getBankImageUrl} from "../../common/bankImageUtil";
import BankImage from "./BankImage";
import moment from "moment";
import termLabel from "../../common/termLabel";
import {PRODUCT_TYPES} from "../../common/types/productTypes";
import {PRODUCT_VISIBILITY} from "../../common/types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "../../common/types/productAttributeTypes";
import {getAvailabilityLabel} from "../../common/types/ncdAvailabilityTypes";
import {CSSTransition} from "react-transition-group";
import {FINANCIAL_SCHEME} from "../../common/types/financialSchemeType";
import UserStatService from "../../service/UserStatService";
import {isRateLiveInTheLast60Seconds, isRateLiveToday, termWasRecentlyUpdated} from "../../common/productUtil";
import {FOSSIL_FUEL_FREE} from "../../common/types/fossilFuelFreeTypes";
import MaturityDateUtil from "../../common/MaturityDateUtil";
import DateUtil from "../../common/DateUtil";

class ProductCard extends Component {


    constructor(props) {
        super(props);

        this.selectTerm = this.selectTerm.bind(this);
        this.getRatesToShow = this.getRatesToShow.bind(this);
        this.findTermGroupByTerm = this.findTermGroupByTerm.bind(this);
        this.selectMaturityDate = this.selectMaturityDate.bind(this);
        this.getTermsFiltered = this.getTermsFiltered.bind(this);
        this.getTermsUnfiltered = this.getTermsUnfiltered.bind(this);
        this.closeEOIForm = this.closeEOIForm.bind(this);
        this.state = {
            selectedTerm: null,
            open: this.props.product.id === this.props.openProductId
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.openProductId !== prevProps.openProductId) {
            this.setState({open: this.props.product.id === this.props.openProductId });
        }
    }

    selectTerm(productId, term) {
        if (this.state.selectedTerm && term.name === this.state.selectedTerm.name) {
            this.setState({
                selectedTerm: null,
                maturityDate: null,
                open: false
            });
        } else {
            if (term.attributes && term.attributes.length === 1) {
                UserStatService.createEOIFormOpenUserStat(productId, term.attributes[0].name);
            }

            this.props.expressInterest(productId);

            term.attributes = term.attributes.filter(
                attribute => attribute.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attribute.value !== null
            );

            this.setState({
                selectedTerm: term,
                open: true,
                maturityDate: term.attributes.length === 1 && MaturityDateUtil.closestDate(new Date(),
                                                                                           MaturityDateUtil.hackTermDays(term.attributes[0].name))
            });
        }
    }

    /**
     * Select maturity date in date picker.
     *
     * @param maturityDate
     */
    selectMaturityDate(maturityDate) {

        const selectedTerm = this.getTermsFiltered().find(term => MaturityDateUtil.getPossibleMaturityMoments(term.name)
                                                    .some(mom => mom.isSame(maturityDate, 'day')));

        /**
         * Is not a standard term or the term is not being showed because of filters configuration.
         */
        if (selectedTerm === undefined || !this.findTermGroupByTerm(selectedTerm).value) {
            this.setState({
                selectedTerm: undefined,
                maturityDate: maturityDate.toDate()
            });
        }
        else {
            const selectedGroup = this.findTermGroupByTerm(selectedTerm);

            selectedGroup.attributes = selectedGroup.attributes.filter(attribute =>
                attribute.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attribute.value !== null);

            UserStatService.createEOIFormOpenUserStat(this.props.product.id, selectedTerm.name);

            this.setState({
                selectedTerm: selectedGroup,
                maturityDate: maturityDate.toDate()
            });
        }

    }

    tabClassName(it) {
    	let className = "position-relative hoverable tab-item";
    	if (it.hasOwnProperty("columns")) {
    	    className += " columns-"+it.columns;
        }
    	className += (it.value === "" || it.value === null) ? " disabled" : "";
        className += isTermHighlighted(this.props.products, this.props.product, it.attributes,
                                       this.props.location) ? ' highlight' : "";
        className += isTermRateOnRequest(it.attributes)?' on-request': "";
    	className += (termWasRecentlyUpdated(it) ? ' recently-updated' : "");

    	if(this.state.selectedTerm && this.state.selectedTerm.name == it.name && this.isEOIOpened()){
    		className += " selected-tab";
    	}
        return className;
    }

    isEOIOpened() {
        return this.state.open && this.state.selectedTerm !== null;
    }

    closeEOIForm() {
        this.setState({
            selectedTerm: null,
            maturityDate: null,
            open: false
        });
    }

    renderAvailability(product) {
        return(
            <div className="availability" tooltip={getAvailabilityLabel(product.availability)} flow="up">
                <div className={product.availability}></div>
            </div>
        );
    }

    static isDropDownRate(term) {
        return (term.value !== "" && term.value !== null || term.value === 'On Request') &&
            term.attributes.filter(item => item.value !== null || item.visibility === PRODUCT_VISIBILITY.ON_REQUEST).length > 1;
    }

    renderDropDownRates(term) {
        if (ProductCard.isDropDownRate(term)) {
            return (
                <svg>
                    <use xlinkHref={this.state.selectedTerm && this.state.selectedTerm.name == term.name && this.isEOIOpened() ? '#curves-svg-triangle-up' : '#curves-svg-triangle-down'}></use>
                </svg>
            )
        }
    }

    renderRecentlyUpdatedLabel(term) {
        const diff = term.updated_date !== null ? moment.utc().diff(moment.utc(term.updated_date), 'minutes') : null;
        if (diff !== null && diff <= 20) {
            return (
                <span className="recently-updated" tooltip={'Updated '+diff+" minutes ago"} flow="up">
                    <svg>
                        <use xlinkHref="#curves-svg-clock"></use>
                    </svg>
                </span>
            )
        }
    }

    renderBankInformation(product, clientType) {
        const bankImageUrl = getBankImageUrl(product.bank.name, product.bank.logo);
        return (
            <div className={'bank-column' + (isRateLiveToday(product) ? ' background-live-rate ' : '')}>
                <div className="img">
                    {product.bank.fossil_fuel_free === FOSSIL_FUEL_FREE.FOSSIL_FUEL_FREE &&
                    <div className="fossil-fuel-free" tooltip="Fossil Fuel Free – See 'About' for more info"
                         flow="right"/>
                    }
                    {product.bank.financial_scheme === FINANCIAL_SCHEME.ADI_BRANCH_OF_FOREIGN_BANK &&
                    <div className="branch-foreign-bank" tooltip="Branch of a Foreign Bank – See 'About' for more info"
                         flow="right"/>
                    }
                    <BankImage bankImageUrl={bankImageUrl} bankName={product.bank.name}/>
                </div>
                {product.type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT &&
                this.renderAvailability(product)
                }
                <div className="rating" tooltip={getBankRatingTooltipMessage()} flow="right">
                    {getBankRating(product.bank)}
                </div>
            </div>
        );
    }

    /**
     * Apply filters over attributes to get terms.
     *
     */
    getTermsFiltered() {
        const urlQueryParams = queryString.parse(this.props.location.search);
        const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');

        return this.props.product.attributes.filter(it =>
            (filterTerms.length === 0 || filterTerms.indexOf(it.name) !== -1)
            && (it.value_type === PRODUCT_ATTRIBUTE.TERM));
    }

    /**
     * Get terms unfiltered.
     *
     * @returns {*}
     */
    getTermsUnfiltered() {
        return this.props.product.attributes.filter(it => it.value_type === PRODUCT_ATTRIBUTE.TERM
                                                            && (it.value || it.visibility === PRODUCT_VISIBILITY.ON_REQUEST));
    }

    filterShownTerms(terms){
        return terms.filter((it,index) => {
            return (
                (it.show && it.show === true)
                || (it.parentTerm && this.props.expandedGroupTerms[it.parentTerm] === true)
                || (this.props.location.search.includes("filterTerm"))
                || (this.props.location.search.includes("groupedTerms=NO"))
            );
        });
    }

    /**
     * Get rates to show.
     *
     */
    getRatesToShow() {
        let shownTerms = this.filterShownTerms(
            groupTermsForCardView(
                this.getTermsFiltered(),
                this.props.location.search.includes("filterTerm"),
                this.props.location.search
            )
        );
        return groupOnRequestRates(shownTerms);

    }

    /**
     * Find term group by term.
     *
     * @param term
     * @returns {number | never | bigint | T | T | *}
     */
    findTermGroupByTerm(term) {
        const ratesToShow = this.getRatesToShow();

        return ratesToShow.find(it => it.name === term.name || it.attributes.some(attr => attr.name === term.name));
    }

    render() {
        const ratesToShow = this.getRatesToShow();
        return (
            <div id={"product-" + this.props.product.id} className={'curves-product-card' +
            (this.state.open ? ' open-product' : '') +
            (ratesToShow.length > 9 ? ' small-font' : '') +
            (isRateLiveInTheLast60Seconds(this.props.product) ? ' blink ' : '') +
            (isRateLiveToday(this.props.product) ? ' live-rate color-live-rate' : ' not-live-rate ')}
            >
                <div className="main">

                    {this.renderBankInformation(this.props.product)}

                    {this.props.product.type === 'NCD' &&
                    <div className="status">
                        CDS CURRENTLY AVAILABLE
                    </div>
                    }

                    <div className={'rates-column' + (isRateLiveToday(this.props.product) ? ' background-live-rate ' : '')}>
                        <div className="rates">
                            {ratesToShow.map((it, idx) =>
                                <div id={"product-" + this.props.product.id+"-"+termLabel(it.name)} className={this.tabClassName(it)} key={idx}
                                    onClick={ (it.value !== "" && it.value !== null && (ProductCard.isDropDownRate(it) || !it.attributes[0].maturity_has_better_rate)) ? () => this.selectTerm(this.props.product.id, it) : null}>
                                    <p className={"rate "+ (it.value === ON_REQUEST_LABEL || this.props.showOnlyOnRequestLabel?"on-request":"")}>
                                        <span className={ProductCard.isDropDownRate(it) ? 'grouped-rate' : ''}>{this.props.showOnlyOnRequestLabel ? "On request" : formatRate(it.value)}</span>
                                        {this.renderDropDownRates(it)}
                                        {this.renderRecentlyUpdatedLabel(it)}
                                    </p>
                                    {!ProductCard.isDropDownRate(it) && it.attributes[0].maturity_has_better_rate &&
                                        <Fragment>
                                            <div className="position-absolute left-0 top-0 width-100per height-100per background-white opacity-06 cursor-not-allowed-important"></div>
                                            <div className="curves-popover-message popover-top z-index-1 width-200px left-50per top-58px translate-minus-50per-0 white-space-normal">
                                                There are maturities with higher rates for this bank.
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            )}
                            <div className="tab-item" style={{flexDirection:"column"}}>
                                { this.props.showRolloverOnlyWording && this.props.showRolloverOnlyWording===true && <p className="rollovers-only-label">Rollovers Only</p>}
                                <div className="last-updated">
                                <p>Last update&nbsp;</p>
                                <p>{calculateTimeDiff(this.props.product.updated_date)}</p>
                                {this.props.product.information &&
                                    <p className="info-column" tooltip={this.props.product.information} flow="left">
                                        <span className="info-icon"></span>
                                    </p>
                                }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <CSSTransition in={this.isEOIOpened()} timeout={1000} classNames="eoi-node" unmountOnExit>
                    <EOIForm product={this.props.product}
                             term={this.state.selectedTerm}
                             productCardTerms={this.getTermsUnfiltered()}
                             maturityDate={this.state.maturityDate}
                             selectMaturityDate={this.selectMaturityDate}
                             closeEOIForm={this.closeEOIForm}
                             showOnlyOnRequestLabel={this.props.showOnlyOnRequestLabel}
                             showRolloverOnlyWording={this.props.showRolloverOnlyWording}
                    />
                </CSSTransition>
            </div>
        )
    }
}

export default withRouter(ProductCard);
