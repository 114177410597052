import React, {Component} from 'react';

import Highcharts from 'highcharts'
import drilldown from 'highcharts/modules/drilldown';

import {withRouter} from "react-router-dom";
import termLabel from "../../common/termLabel";
import {bestRates, formatTerms, getTermGroup, groupTerms, ungroupTerms} from "../../common/rateUtil";
import {formatRate, formatRate4, formatRateNumber, formatRateNumber4} from "../../common/formatUtil";
import {formatBankBillSwaps, getBBSWCash, groupBankBillSwaps} from "../../common/bankBillSwapUtil";
import scrollToElement from 'scroll-to-element';
import UserStatService from "../../service/UserStatService";
import QueryParams from "../../common/QueryParams";


class RateChart extends Component {

    constructor(props) {
        super(props);
        this.getChartDefaultOptions = this.getChartDefaultOptions.bind(this);
    }

    getChartDefaultOptions() {

        return {
            chart: {
                type: 'column',
                height: 300,
                style:{
                    fontFamily: 'Lato, sans-serif',
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Best Yield Available',

            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                min: 0.5,
                title: {
                    text: 'Yield'
                },
                labels:{
                    format: '{value}%'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{point.description}</span>: <b>{point.y}%</b><br/>',
                shared: false
            },
            plotOptions: {
                column: {},
                series: {
                    animation: false,
                    cursor:'pointer',
                    maxPointWidth: 20,
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        format: '{y} %',
                        crop: false,
                        overflow: 'none'
                    }
                },
            }
        };
    };

    getDrilldownChart(products,bbsw, location) {
        const maxYields = formatTerms(bestRates(products, false, location, true));
        const bankBillSwaps = formatBankBillSwaps(bbsw,location);
        const bbswCash = getBBSWCash(bbsw);

        bbswCash && bankBillSwaps.unshift(bbswCash);


        let minRate = {};
        let maxRate = {};

        const MAX_COLUMNS_BY_TERM = 4;

        let columnSeries = maxYields.map((it) => {

            minRate = minRate.value === undefined ? it : (minRate.value > it.value ? it : minRate);

            maxRate = maxRate.value === undefined ? it : (maxRate.value < it.value ? it : maxRate);


            return {
                name:termLabel(it.name),
                description: it.attributes.length>1?"Multiple banks":(it.attributes[0].bank.name?it.attributes[0].bank.name:"-"),
                y: formatRateNumber(it.value),
                drilldown: it.attributes.length > 1 ? it.name : null,
                cursor: 'pointer',
                events: {
                    click: it.attributes.length>1?null:(e) => this.scrollToProduct(it.attributes[0].id,it.name)
                }
            }
        });
        const drillDownSeries = maxYields.map((it) => {

            let columnByTerm = 0;

            const drillDownData = it.attributes.map((attr)=>{
                if(columnByTerm < MAX_COLUMNS_BY_TERM){
                    columnByTerm++;
                    return {
                        name:attr.bank.name?attr.bank.name:"-",
                        description: termLabel(it.name),
                        y:attr.value,
                        events: {
                            click: (e) => this.scrollToProduct(attr.id,it.name)
                        }
                    }
                }
            });

            return {
                name: termLabel(it.name),
                id: it.name,
                data:drillDownData
            }
        });

        let minBBSW={};

        const bbswSeries = bankBillSwaps.map((it) => {
            minBBSW = minBBSW.value === undefined ? it : (minBBSW.value > it.value ? it : minBBSW);
            return {
                name:termLabel(it.name),
                description: it.attributes[0].name,
                y: QueryParams.getUrlChunk(this.props, -1) === 'ncd' ? formatRateNumber4(it.value)
                                                                        : formatRateNumber(it.value)
            }
        });

        //Exclude column series that doesn't exists on bbswSeries
        columnSeries = columnSeries.filter(item => {
           return bbswSeries.some(bbsw => {
              return bbsw.name === item.name;
           });
        });

        const options = this.getChartDefaultOptions();

        options.yAxis.min=minRate.value<minBBSW.value?(formatRateNumber(minRate.value) - 0.5):(formatRateNumber(minBBSW.value) - 0.5);

        options.series = [
            {
                name:"Benchmark",
                type: 'line',
                color:'#9bb961',
                enableMouseTracking: false,
                data:bbswSeries,
                zIndex: '100'
            },
            {
                name:"Best Yield Available",
                color:"#47835a",
                data: columnSeries
            }
            ];
        options.drilldown ={series:drillDownSeries};

        return options;
    };

    componentDidMount() {
        const chartOptions = this.getDrilldownChart(this.props.products,this.props.bankBillSwaps, this.props.location);
        drilldown(Highcharts);
        Highcharts.chart('curves-rate-chart', chartOptions);
    }



    componentDidUpdate(prevProps, prevState, snapshot) {

        /**
         * If query parameters have changed, refresh chart data.
         */
        if (this.props !== prevProps) {
            const chartOptions = this.getDrilldownChart(this.props.products, this.props.bankBillSwaps,this.props.location);
            drilldown(Highcharts);
            Highcharts.chart('curves-rate-chart', chartOptions);
            //this.setState({chartOptions: chartOptions});
        }

    }


    scrollToProduct(productId,term) {
        const termGroup = termLabel(getTermGroup(term));
        const elementId = 'product-'+productId+"-"+termGroup;
        const productCard = document.getElementById(elementId);

        UserStatService.createChartClickUserStat(productId, term);
        productCard.click();


        const productCardOffset = document.getElementById('product-'+productId).offsetTop;

        const productCardHeader = document.getElementById('product-'+productId).getElementsByClassName('main')[0];
        const productCardHeaderHeight = productCardHeader?productCardHeader.offsetHeight:0;

        const productContainerOffset = document.getElementsByClassName('product-list')[0].offsetTop;

        const bbswBarHeight = document.getElementsByClassName('curves-max-yields-inner-container')[0].offsetHeight;


        //const relativeProductPosition = productCardOffset + (productContainerOffset) - bbswBarHeight - productCardHeaderHeight;
        //window.scrollTo(productContainerOffset, relativeProductPosition);

        const relativeProductPosition =  - bbswBarHeight - productCardHeaderHeight;


        scrollToElement(productCard,{
            offset: relativeProductPosition,
            duration: 1500
        });



    }


    render() {
        return (
            <div id='curves-rate-chart' className={'chart '+ (this.props.products.length === 0 ? 'hidden' : '')}>
            </div>
        );
    }
}


export default withRouter(RateChart);
