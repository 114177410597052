import {formatCapitalize} from "../textFormatUtl";

export const FOSSIL_FUEL_FREE = {
    FOSSIL_FUEL_FREE: 'FOSSIL_FUEL_FREE',
    NOT_FOSSIL_FUEL_FREE: 'NOT_FOSSIL_FUEL_FREE',
    NOT_STATED: 'NOT_STATED'
};

const FOSSIL_FUEL_FREE_LABEL = [
    { label: 'Fossil Fuel Free',  value: FOSSIL_FUEL_FREE.FOSSIL_FUEL_FREE },
    { label: 'Not Fossil Fuel Free',  value: FOSSIL_FUEL_FREE.NOT_FOSSIL_FUEL_FREE },
    { label: 'Not Stated', value: FOSSIL_FUEL_FREE.NOT_STATED}
];

export const fossilFuelFreeLabel =  fossil_fuel_free => {
    const fossil_fuel_free_found = FOSSIL_FUEL_FREE_LABEL.find(it => it.value === (fossil_fuel_free));

    return formatCapitalize(fossil_fuel_free_found ? fossil_fuel_free_found.label : fossil_fuel_free);
};


