import React from 'react';

const BankImage = ({bankImageUrl, bankName}) => {

    const style = bankImageUrl===null?{}:{backgroundImage:'url("' + bankImageUrl + '")'};
    const className = (bankImageUrl !== null?('bank-image'):(bankName!== null?"":"bank-image hide"));

    return(
        <div className={className} style={style}>
            {bankImageUrl === null && bankName === null &&
                <div className="lock-icon">
                    <div className="onhover-message">
                        Elevate Membership level to access.
                        <br />
                        Submit an EOI and you can find out more!
                    </div>
                </div>
            }
            {bankImageUrl === null && bankName !== null &&
               bankName
            }
        </div>
    );
};

export default BankImage;
