import axios from "axios";

class HistoricalService {

    static find(params) {
        return new Promise(resolve => {
            axios.get('/api/client/historical/', { params:
                {
                    term: params.term,
                    baseLine: params.baseLine,
                    range: params.range,
                    bank: params.banks.join()
                }
            }).then(response => {
                resolve(response.data);
            });
        });
    }

    static findTermRates(params) {
        return new Promise(resolve => {
            axios.get('/api/client/historical/term-rates', { params:
                {
                    year: params.year,
                    month: params.month,
                    bank: params.banks.join()
                }
            }).then(response => {
                resolve(response.data);
            });
        });
    }
}

export default HistoricalService;
