import React, {Component} from 'react';

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 180) * Math.PI / 180;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {

        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        const d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;
};

const easeInOutQuad = (t) => {
    return t<.5 ? 2*t*t : -1+(4-2*t)*t
};


class SemiCircleProgress extends Component {

    constructor(props) {
        super(props);

        this.animate = this.animate.bind(this);

        this.state = {
            fillAngle: 0
        };
    }

    componentDidMount() {
        const sequence = Array(40).fill(1).map((e, i) => easeInOutQuad(i / 40));
        this.animate(sequence.map(n => n * this.props.fillAngle));
    }

    animate(list) {
        if (list.length === 0)
            return;

        this.setState({fillAngle: list[0]});

        setTimeout(() => this.animate(list.slice(1, list.length)), 25);
    }


    render() {

        return (
            <span className="curves-semi-circle-progress">
                <svg width="150px" height="65px">
                    <g transform="scale(1.3, 0.9) translate(-90, -80)">
                        <path className="background-arc" fill="none" strokeWidth="10"
                              d={describeArc(150, 150, 50, 0, 180)}/>
                        <path className="main-arc" fill="none" strokeWidth="10"
                              d={describeArc(150, 150, 50, 0, this.state.fillAngle)}/>
                    </g>
                </svg>
                <div className="text-div">
                    {this.props.children}
                </div>
            </span>
        );
    }

}

export default SemiCircleProgress;
