import moment from 'moment-timezone';

class DateUtil {

    static formatISO_8601_1(string) {
        return string && moment.utc(string).local().format('DD/MM/YYYY HH:mm');
    }

    static formatISO_8601_2(string) {
        return string && moment.utc(string).local().format('Do MMMM YYYY');
    }

    static diffInMinutes(strA, strB) {
        return moment.duration(moment.utc(strA).diff(moment.utc(strB))).asMinutes();
    }

    /**
     * Datetime is in the last 60 secs.?
     *
     * @param strDateTime
     * @returns {*}
     */
    static isInTheLast60_Seconds(strDateTime) {
        return moment().diff(moment.utc(strDateTime).local(), 'second') < 60;
    }

    /**
     * Datetime is today in Sydney?
     *
     * @param strDateTime
     * @returns {*}
     */
    static isToday(strDateTime) {
        return moment.tz('Australia/Sydney').startOf('day').isSame(moment.utc(strDateTime).tz('Australia/Sydney').startOf('day'));
    }

    static formatDate(date, format) {
        return date && moment.utc(date).local().format(format);
    }

}

export default DateUtil;
