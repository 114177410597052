import React from 'react';
import {currentRoute, routes} from "../config/routes";
import {Route, Switch, withRouter} from "react-router-dom";

const Body = (props) => {
    const fullWidthPage =  props.fullWidth;
    if(fullWidthPage){
        return(
            <Switch>
                { routes.map( route => <Route key={ route.path } { ...route } /> ) }
            </Switch>
        );
    }else{
        return (
            <div className="curves-body">
                <Switch>
                    { routes.map( route => <Route key={ route.path } { ...route } /> ) }
                </Switch>
            </div>
        );
    }


};

export default withRouter(Body);
