import {RATING_LONG_TERM} from "../bankRatingUtil";

export const NCD_AVAILABILITY = {
    NCDS_CURRENTLY_AVAILABLE: 'NCDS_CURRENTLY_AVAILABLE',
    INDICATIVE_ONLY: 'INDICATIVE_ONLY',
    NOT_ISSUING: 'NOT_ISSUING'
};

const AVAILABILITY = [
    {type: NCD_AVAILABILITY.NCDS_CURRENTLY_AVAILABLE,  label: 'NCDs currently available'},
    {type: NCD_AVAILABILITY.INDICATIVE_ONLY,           label: 'Indicative only - Check for availability'},
    {type: NCD_AVAILABILITY.NOT_ISSUING,               label: 'No Issuing = Roll overs only'}
];

export const getAvailabilityLabel = (availability) => {
    const availabilityFound = AVAILABILITY.find(it => it.type === availability);
    return availabilityFound ? availabilityFound.label : availability;
};

export const getAvailabilityIndex = (availability) => {
    return AVAILABILITY.findIndex(element => element.type === availability);
};

