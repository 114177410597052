import React, {Component} from 'react';
import GlobalStore from "../store/GlobalStore";
import {Redirect, withRouter} from "react-router-dom";
import UserStatService from "../../service/UserStatService";
import HistoricalRateTrendsChart from "../layout/HistoricalRateTrendsChart";
import HistoricalTermRatesChart from "../layout/HistoricalTermRatesChart";



const banks = [
    { name: 'NAB', value: 'nab', color: '#D20000', label: 'NAB' },
    { name: 'AMP BANK', value: 'amp_bank', color: '#001E41', label: 'AMP' },
    { name: 'ING Direct - Adviser', value: 'ing', color: '#FF6600', label: 'ING Direct' },
    { name: 'BANK OF QLD', value: 'boq', color: '#287CC2', label: 'BoQ' },
    { name: 'BENDIGO&ADELAIDE BANK', value: "bendigo", color: '#990134', label: 'Bendigo' },
    { name: 'ME BANK', value: 'me_bank', color: '#060807', label: 'ME' },
    { name: 'AUSWIDE BANK', value: 'auswide_bank', color: '#F58A33', label: 'Auswide' },
    { name: 'UNRATED', value: 'unrated', color: '#C49102', label: 'Unrated' },
    { name: 'BANK BILLS / SWAPS', value: 'bbsw', color: '#BBBBBB', label: 'Benchmark' },
];

class HistoricalAnalytics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: '',
            validationErrors: {},
            questionSubmitted: false
        };


    }

    componentDidMount() {
        if (this.props.globalStore.user.yieldhub_analytics) {
            UserStatService.createPageLoadUserStat('ANALYTICS');
        }
    }

    render() {
        if (!this.props.globalStore.user.yieldhub_analytics) {
            return <Redirect to='/' />
        } else {
            return (
                <div className="historical-analytics curves-simple-page">
                    <h1>
                        Yieldhub Analytics
                    </h1>
                    <div className="explanation">
                        <p>
                            Welcome to Yieldhub Analytics. View interest rate movement over time with the Historical TD Rate
                            Trends graph by selecting the term, period and bank. Either set the base line as zero to view
                            the rate as a whole, or Benchmark to see it as a margin for that time period.
                        </p>
                        <p>
                            Yield curve shape is an important market indicator. See the curve movement over time for a range
                            of banks by selecting the ‘Start Time Period’ and using the slider or play the animation.
                        </p>
                        <p>
                            All rates are ‘carded’ wholesale rates from our partner banks and may be above or below actual
                            investment yields secured on any particular day.
                        </p>
                    </div>
                        <HistoricalRateTrendsChart banks={banks}/>
                    <div className="margin-top-25px"></div>
                    <HistoricalTermRatesChart banks={banks}/>
                </div>
            )
        }
    }

}

export default withRouter(GlobalStore.connect(HistoricalAnalytics));
