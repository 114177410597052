export const formatLowercase = (text) => {
    return (text.toLowerCase());
};


export const formatUppercase = (text) => {
    return (text.toUpperCase());
};

export const formatCapitalize = (text) => {
    return text.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
};

export const capitalizeFirstLetter = (text) => {
    if (text && text.length > 1){
        return text.charAt(0).toUpperCase() + text.slice(1);
    }else{
        if (text){
            return text.toUpperCase();
        }
    }
    return '';
};
