import React, {Component, Fragment} from 'react';
import Spinner from "../common/Spinner";
import UserService from "../../service/UserService";
import queryString from 'query-string'
import GlobalStore from "../store/GlobalStore";
import {withRouter} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

class LoginPage extends Component {

    constructor(props) {
        super(props);

        this.doLogin = this.doLogin.bind(this);
        this.submitForgotPasswordForm = this.submitForgotPasswordForm.bind(this);
        this.doLoginSalesforce = this.doLoginSalesforce.bind(this);
        this.validate = this.validate.bind(this);
        this.isSubmitFail = this.isSubmitFail.bind(this);
        this.switchView = this.switchView.bind(this);
        this.reCaptchaSolved = this.reCaptchaSolved.bind(this);
        this.renderLoginForm = this.renderLoginForm.bind(this);
        this.renderForgotPasswordForm = this.renderForgotPasswordForm.bind(this);
        this.renderRegisterForm = this.renderRegisterForm.bind(this);
        this.renderReCaptcha = this.renderReCaptcha.bind(this);

        this.togglePopup = this.togglePopup.bind(this);
        this.renderPopup = this.renderPopup.bind(this);

        this.state = {
            username: '',
            password: '',
            forgotPasswordEmail:'',
            validationErrors: { login:{}, forgotPassword:{}},
            sending: false,
            view: 'LOGIN',
            redirectUrl: queryString.parse(this.props.location.search).redirect_uri,
            loginAttempts: 0,
            reCaptchaSolved: false,
            showPopup: false 
        };
    }


    togglePopup() {
        this.setState({
          showPopup: !this.state.showPopup
        });
      };
     
      renderPopup() {
        return (
          <div className="popup">
            <div className="popup_inner">
            
            <div className="message">
                <iframe src='/pdf/YH_-_End_User_TCs_22.04.2021.pdf'  width="100%" height="500px"></iframe>
            </div>
            <div className="options">
                <a className="btn-popup" onClick={this.togglePopup}>Accept</a>
            </div>
            
            </div>

            
          </div>
        );
      };


    shouldSolveReCaptcha(loginAttempts) {
        return loginAttempts > 2;
    };

    validate() {
        let validationErrors = { login:{}, forgotPassword:{}};

        const emailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegularExpression.test(String(this.state.username).toLowerCase())) {
            validationErrors.login.username = 'Please enter a valid email address';
        }

        if (!emailRegularExpression.test(String(this.state.forgotPasswordEmail).toLowerCase())) {
            validationErrors.forgotPassword.forgotPasswordEmail = 'Please enter a valid email address';
        }

        if (this.state.password.trim() === '') {
            validationErrors.login.password = 'Please enter your password';
        }

        this.setState({
            validationErrors
        });

        return validationErrors;
    }


    doLogin(event) {
        event.preventDefault();
        const validationErrors = this.validate();

        if (Object.keys(validationErrors.login).length > 0) {
            return;
        }
        if (this.shouldSolveReCaptcha(this.state.loginAttempts) && !this.state.reCaptchaSolved) {
            this.setState({
                errorMessage: "Solve captcha before continue"
            });
            return;
        }

        this.setState({sending: true});

        const loginData = {
            username: this.state.username,
            password: this.state.password
        };

        UserService.doLogin(loginData, this.state.redirectUrl)

            .then((response) => {
                if ('location' in response.headers) {
                    window.location.href = response.headers['location'];
                } else {
                    window.location.href = "/";
                }
            })
            .catch((error) => {
                const errorMessage = (error && error.response.data.message)? error.response.data.message : "An error occurs, please try again later";
                console.log(errorMessage);
                this.setState({
                    errorMessage: errorMessage,
                    sending: false,
                    loginAttempts: this.state.loginAttempts + 1,
                    reCaptchaSolved: false
                });
            });
    }

    doLoginSalesforce() {
        this.setState({sending: true});
        window.location.href = '/api/salesforce_auth/authorize/customer';
    }

    submitForgotPasswordForm(){
        const validationErrors = this.validate();

        if (Object.keys(validationErrors.forgotPassword).length > 0) {
            return;
        }

        this.setState({sending: true, successMessage:'',errorMessage:''});

        const forgotPasswordData = {
            username: this.state.forgotPasswordEmail,
        };

        UserService.forgotPassword(forgotPasswordData)
            .then((response) => {
                const successMessage = "An email has been sent to that address to reset your password. Please also check your Junk Folder.";
                this.setState({sending: false, successMessage:successMessage, forgotPasswordEmail:''});
            })
            .catch((error) => {
                const errorMessage = (error && error.response.data.message)? error.response.data.message : "An error occurs, please try again later";
                console.log(errorMessage);
                this.setState({errorMessage: errorMessage, sending: false});
            });

    }

    isSubmitFail() {
        return this.state.errorMessage && this.state.errorMessage !== '';
    }

    reCaptchaSolved(value) {
        this.setState({
            reCaptchaSolved: value
        })
    }

    switchView(view){
        this.setState({
            view,
            errorMessage:"",
            successMessage:"",
            forgotPasswordEmail:'',
            username:'',
            password:'',
            validationErrors: { login:{}, forgotPassword:{}}
        })
    }

    renderForgotPasswordForm() {
        return (
	            <div className={this.state.view === 'FORGOT' ? 'open' : ''}>
	            	<h2>Forgot password</h2>
	                <p>Please enter your email to reset your password</p>
	                {this.isSubmitFail() &&
	                <div className="error-message">{this.state.errorMessage}</div>
	                }
	                {this.state.successMessage &&
	                <div className="success-message">{this.state.successMessage}</div>
	                }
                    <div className="input-form">
                        <label htmlFor="email" className="label usernamelabel">Email</label>
                        <input type="text" placeholder="email" value={this.state.forgotPasswordEmail}
                               onChange={e => this.setState({forgotPasswordEmail: e.target.value})}/>
                        <div className="validation-errors">{this.state.validationErrors.forgotPassword.forgotPasswordEmail}</div>
                    </div>
                    {this.state.sending === true && <div className="form-sending"><Spinner/></div>}
                    {this.state.sending === false &&
                        <Fragment>
                            <div className="submit-btn continue-option-cell">
                                <input type="submit"
                                       onClick={this.submitForgotPasswordForm}
                                       value="Submit"/>
                            </div>
                            <div className="submit-btn cancel-option-cell">
                                <input type="submit"
                                       onClick={() => this.switchView('LOGIN')}
                                       value="Cancel"/>
                            </div>
                        </Fragment>
                    }
                </div>
        );
    }

    renderRegisterForm() {
        return (
            <div className={this.state.view === 'REGISTER' ? 'open' : ''}>
                <h2>Register</h2>
                <p>
                    Yieldhub is our new Rates Platform where you are able to access over
                    80 of our banking partner's rates and products.
                </p>
                <p>
                    At the moment it is reserved for Wholesale Investors. If you qualify as a&nbsp;
                    <a target="_blank" href="http://www5.austlii.edu.au/au/legis/cth/consol_reg/cr2001281/s6d.2.03.html">Wholesale Investor</a>
                    , have a minimum deposit size of $500,000 and would like access -&nbsp;
                    <a href="mailto:yield@curve.com.au?subject=I am interested in registering to use Yieldhub">Let us know</a>.
                </p>
                <div className="submit-btn continue-option-cell">
                    <input type="submit"
                           onClick={() => {location.href='mailto:yield@curve.com.au?subject=I am interested in registering to use Yieldhub';}}
                           value="Register"/>
                </div>
                <div className="submit-btn cancel-option-cell">
	                        <input type="submit"
	                               onClick={() => this.switchView('LOGIN')}
	                               value="Cancel"/>
	            </div>
            </div>
        );
    }

    renderLoginForm() {
        return (
            <div className={this.state.view === 'LOGIN' ? 'open' : ''}>
                {this.isSubmitFail() &&
                <div className="error-message">{this.state.errorMessage}</div>
                }
                <form>
                    <div className="input-form">
                        <label htmlFor="email" className="label usernamelabel">Email</label>
                        <input type="text" placeholder="email" value={this.state.username}
                               onChange={e => this.setState({username: e.target.value})}/>
                        <div className="validation-errors">{this.state.validationErrors.login.username}</div>
                    </div>
                    <div className="input-form">
                        <label htmlFor="password" className="label passwordlabel">Password</label>
                        <input type="password" placeholder="password" value={this.state.password}
                               onChange={e => this.setState({password: e.target.value})}/>
                        <div className="validation-errors">{this.state.validationErrors.login.password}</div>
                    </div>
                    {this.state.sending === true && <div className="form-sending"><Spinner/></div>}
                    {this.state.sending === false &&
                        <Fragment>
                            <div className='recaptcha-container'>
                                {this.renderReCaptcha()}
                            </div>
                            <div className="submit-btn common-login-row">
                                <input type="submit"
                                       onClick={(event) => this.doLogin(event)}
                                       value="Log In"/>
                            </div>

                            <div className="forgot-row">
                                <div className="forgot-div">
                                    <a href="#" onClick={this.togglePopup}> By logging in you acknowledge you have read and accept our End User Terms & Conditions</a>
                                </div>    
                            </div>

                            <div className="forgot-row">
                                <div className="forgot-div">
                                    <a href="#" onClick={() => this.switchView('FORGOT')}>Forgot Password</a>
                                </div>
                                <div className="register-div">
                                    <a href="#" onClick={() => this.switchView('REGISTER')}>Register</a>
                                </div>
                            </div>
                        </Fragment>
                    }
                </form>
            </div>
        );
    }

    renderReCaptcha() {
        if (this.shouldSolveReCaptcha(this.state.loginAttempts)) {
           return (
               <ReCAPTCHA
                   sitekey="6LeXv-MUAAAAAFYch2XIkgDSNERcqLMufJemB1oN"
                   onChange={() => {this.reCaptchaSolved(true)}}
                   onExpired={() => {this.reCaptchaSolved(false)}}
               />
            );
        }
    }

    render() {
        return (
            <Fragment>
                <div className="curves-login-page">
                    <div className="curves-logo">
                        <img src="/img/Yieldhub_Reverse_Logo_RGB.svg"/>
                    </div>
                    <div className="login-text">
                        Yieldhub is a central platform where you can access rates and products from over 80 of our Banking Partners. You will not only be able to access market rates, but any specials available on the day, filtering, sorting and interacting with all aspects of the platform!
                        {this.state.showPopup ? this.renderPopup() : null}
                    </div>
                    <div className="form-container">
                        <div className="login-form">
                            {this.renderLoginForm()}
                            {this.renderForgotPasswordForm()}
                            {this.renderRegisterForm()}
                        </div>
                    </div>
                    <div className="login-browser-notice">
                        Optimised for Chrome, Safari and Firefox browsers on MAC and PC desktops and laptops.
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default  withRouter(GlobalStore.connect(LoginPage));
