import GlobalStore from "../client/store/GlobalStore";

class DialogUtil {

    static open(openDialog) {
        let resolveDialogPromise = null;
        const dialogPromise = new Promise(resolve => resolveDialogPromise = resolve);
        GlobalStore.setState({openDialog, resolveDialogPromise, dialogStatus: 'INITIAL'});
        return dialogPromise;
    }

    static startLoading() {
        GlobalStore.setState({dialogStatus: 'LOADING'});
    }

    static finishLoading() {
        GlobalStore.setState({dialogStatus: 'INITIAL'});
    }

    static startResolving() {
        GlobalStore.setState({dialogStatus: 'RESOLVING'});
    }

    static resolve(val) {
        GlobalStore.setState({dialogStatus: 'RESOLVING'});
        GlobalStore.getState().resolveDialogPromise(val);
    }

    static close() {
        GlobalStore.setState({openDialog: undefined});
    }

}

export default DialogUtil;