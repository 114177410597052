import React, {Component} from 'react';
import moment from "moment";

class HistoricalChartDateNavigator extends Component {

    constructor(props) {
        super(props);
        this.playChart = this.playChart.bind(this);
        this.stopChart = this.stopChart.bind(this);
        this.state = {
            playingChart: false,
            playingExecutions: [],
            speed: 250
        }
    }

    playChart(dates) {
        let executions = [];
        dates.forEach(function(item, index) {
            executions.push(setTimeout(() => {
                this.props.onChange(index);
            }, index * this.state.speed));
        }.bind(this));

        this.setState({
            playingChart: true,
            playingExecutions: executions
        });
        setTimeout(() => {
            this.setState({
                playingChart: false,
                playingExecutions: []
            });
        }, dates.length * this.state.speed);
    }

    stopChart() {
        this.state.playingExecutions.forEach(function(item) {
            clearTimeout(item);
        });

        this.setState({
            playingChart: false,
            playingExecutions: []
        });
    }

    render() {
        return (
            <div className="chart-date-navigator chart-row">
                <div className="date-selector chart-column">
                    <input type="range" min="0" max={this.props.dates.length - 1}
                           list="dates" className="chart-date-range"
                           value={this.props.selectedValue}
                           onChange={e => {this.props.onChange(e.target.value)}}
                    />
                    <datalist className="date-list" id="dates">

                        {this.props.dates.map((date, idx) => {
                            const momentDate = moment.utc(date);
                            return (
                                <option key={momentDate.valueOf()} className="range-opt" value={idx} onClick={() => {this.props.onChange(idx)}}>
                                    {momentDate.format('MMM \'YY')}
                                </option>
                            )
                        })}
                    </datalist>
                </div>
                <div className="left-column">

                    {this.state.playingChart ? (
                        <a className="stop-btn" onClick={() => this.stopChart()} title="Stop" />
                    ) : (
                        <a className="play-btn" onClick={() => this.playChart(this.props.dates)} title="Play"/>
                    )}
                    <div className="speed-selector">
                        <input type='range' min='250' list="speed-list"
                               max='1000' className="speed-range"
                               value={this.state.speed}
                               onChange={e=> {this.setState({speed: e.target.value})}}/>
                        <datalist id="speed-list" className="speed-list">
                            <option className="range-opt" value={250} onClick={() => {this.setState({speed: 250})}}>Fast</option>
                            <option className="range-opt" value={750} onClick={() => {this.setState({speed: 750})}}>Med</option>
                            <option className="range-opt" value={1000} onClick={() => {this.setState({speed: 1000})}}>Slow</option>
                        </datalist>
                    </div>
                </div>
            </div>
        )
    }
}

export default HistoricalChartDateNavigator;
