import {Component} from "react";
import {withRouter} from "react-router-dom";
import FilterUtil, {FILTER_DEPOSIT_RANGE} from "../../common/FilterUtil";
import React from "react";
import queryString from "query-string";


class BankNameFilter extends Component {

    constructor(props) {
        super(props);
        this.changeFilter = this.changeFilter.bind(this);
        this.search = this.search.bind(this);
        this.state = {
            filters: FilterUtil.getFiltersFromQueryParams(this.props.location)
        };
    }

    changeFilter(value) {
        const filters = this.state.filters;
        filters.filterBankName = value;
        this.setState({ filters });
        this.search();
    }

    search() {
        const urlQueryParams = queryString.parse(this.props.location.search);

        // Make a copy of filters object. Needed to remove attributes without any problem.
        const filters = JSON.parse(JSON.stringify(this.state.filters));
        /**
         * Remove parameter filter min/max deposit if is 0/1000.
         *
         */
        if (filters.filterDepositMin === FILTER_DEPOSIT_RANGE.min) {
            delete filters.filterDepositMin;
            delete urlQueryParams.filterDepositMin;
        }

        if (filters.filterDepositMax === FILTER_DEPOSIT_RANGE.max) {
            delete filters.filterDepositMax;
            delete urlQueryParams.filterDepositMax;
        }

        if(filters.filterBankName === null) {
            delete filters.filterBankName;
            delete urlQueryParams.filterBankName;
        }

        this.props.history.push('?' + queryString.stringify({ ...urlQueryParams, ...filters }));
    }

    render() {
        return (
            <div className="bank-name-filter">
                <input className="input-filter"
                        value={this.state.filters.filterBankName !== null ? this.state.filters.filterBankName : ''}
                        type="text"
                        placeholder="Bank search"
                        onChange={(event) => {this.changeFilter(event.target.value)}}
                />
            </div>
        )
    }
}

export default withRouter(BankNameFilter);
