import {getBankRating, getBankRatingTooltipMessage} from "./bankRatingUtil";
import {formatRate} from "./formatUtil";
import React, {Component, Fragment} from 'react';
import {TERM_GROUP} from "./rateUtil";
import {hasTermDepositBehavior, PRODUCT_TYPES} from "./types/productTypes";
import {PRODUCT_VISIBILITY} from "./types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "./types/productAttributeTypes";
import {getAvailabilityLabel, NCD_AVAILABILITY} from "./types/ncdAvailabilityTypes";
import {FINANCIAL_SCHEME} from "./types/financialSchemeType";
import {getBankImageUrl} from "./bankImageUtil";
import BankImage from "../client/card/BankImage";
import moment from "moment";
import DateUtil from "./DateUtil";

export const cleanProductAttributes = (products, allowStringValues) => {
    let termsWithValue = [];
    if(products && products.length>0) {
        products.forEach((product) => {
            product.attributes.forEach((attribute) => {
                const actualTerm = attribute.name;
                let actualRate = null;
                if (attribute.value !== null) {
                    actualRate = [PRODUCT_ATTRIBUTE.NUMBER, PRODUCT_ATTRIBUTE.TERM].includes(attribute.value_type) ? parseFloat(attribute.value) : allowStringValues ? attribute.value : null;
                }
                if (actualRate !== null || attribute.visibility === PRODUCT_VISIBILITY.ON_REQUEST &&
                    ((allowStringValues && attribute.value_type === PRODUCT_ATTRIBUTE.STRING) || (attribute.value_type === PRODUCT_ATTRIBUTE.TERM)
                        || attribute.value_type === PRODUCT_ATTRIBUTE.NUMBER)) {
                    if (!(termsWithValue.includes(actualTerm))) {
                        termsWithValue.push(actualTerm);
                    }
                }
            });
        });

        products.forEach((product) => {
            product.attributes = product.attributes.filter(
                it => (termsWithValue.includes(it.name)))
        });
        return products;
    } else {return undefined;}
};


export const getProductsAttributesNames = (products, displayGroupedTerms = true) => {
    let termsWithValue = [];
    let allTerms = [];
    let terms = [];
    products.forEach((product) => {``
        terms = [];
        product.attributes.forEach((attribute) => {
            const actualTerm = {name: attribute.name, value_type: attribute.value_type};
            const actualRate = (attribute.value === '' || attribute.value === undefined || attribute.value === null
                || isNaN(attribute.value.replace(/,/, '.'))) ? null : parseFloat(attribute.value.replace(/,/, '.'));
            if (actualRate !== null) {
                if (!(termsWithValue.includes(actualTerm)) ) {
                    termsWithValue.push(actualTerm);
                }
            }
            terms.push(actualTerm);
        });
        allTerms = terms;
    });
    allTerms.filter(value => termsWithValue.includes(value));
    if (displayGroupedTerms) {
        return groupAttributeNames(allTerms);
    } else {
        return allTerms;
    }
};


const groupAttributeNames = (terms) => {
    let grouped = [];
    terms.forEach(function (term) {
        const termName = term.name;
        const valueType = term.value_type;
        let group = null;
        if (valueType === PRODUCT_ATTRIBUTE.TERM) {
            const termDays = parseInt(termName);
            if (termDays >= 180 && termDays <= 240) {
                group = {name: TERM_GROUP._6_8M, value_type: PRODUCT_ATTRIBUTE.GROUP};
                if (grouped.find(g => (g.name === group.name && g.value_type === group.value_type)) === undefined)
                    grouped.push(group);
            } else if (termDays > 240 && termDays <= 359) {
                group = {name: TERM_GROUP._9_11M, value_type: PRODUCT_ATTRIBUTE.GROUP};
                if (grouped.find(g => (g.name === group.name && g.value_type === group.value_type)) === undefined)
                    grouped.push(group);
            } else if (termDays === 360) {
                group = {name: TERM_GROUP._1YR, value_type: PRODUCT_ATTRIBUTE.GROUP};
                if (grouped.find(g => (g.name === group.name && g.value_type === group.value_type)) === undefined)
                    grouped.push(group);
            } else if (termDays > 360) {
                group = {name: TERM_GROUP._1YR_PLUS, value_type: PRODUCT_ATTRIBUTE.GROUP};
                if (grouped.find(g => (g.name === group.name && g.value_type === group.value_type)) === undefined)
                    grouped.push(group);
            } else {
                grouped.push(term);
            }
        } else {
            grouped.push(term);
        }

    });
    return grouped;
};

/* It returns an array of arrays [["bank1", "rating", ...], ["bank2", "rating", ...]]
 * terms: [{name:30, value_type: 'TERM'}, {name:"1YR", value_type: 'GROUP'}, {name: 'My column', value_type: 'STRING'} ....]
 * */
export const formatProductsTableView = (products, terms, formatUpdate) => {
    let rows = [];
    let cellRate;
    products.forEach((product) => {
        let rowData = [
            <RenderBankName bank={product.bank}/>,
            <div tooltip={getBankRatingTooltipMessage()} flow="right">{getBankRating(product.bank)}</div>];
        if (product && product.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT) {
            rowData.push(<RenderAvailability product={product}/>);
        }
        terms.forEach((term) => {
            cellRate = getMaxValue(term, product.attributes);
            rowData.push(cellRate !== null ? (isNaN(cellRate) ? cellRate : formatRate(cellRate)) : "N/A");
        });
        if(hasTermDepositBehavior(product)){
            rowData.push(formatUpdate(product));
        }
        rows.push(rowData);
    });
    return rows;
};

export const formatProductsCSVView = (products, terms) => {
    let rows = [];
    let cellRate;
    products.forEach((product) => {
        let rowData = ["\""+product.bank.name+"\""];
        if (product && product.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT) {
            rowData.push("\"" + getAvailabilityLabel(product.availability)+ "\"");
        }
        terms.forEach((term) => {
            cellRate = getMaxValueForCSVFile(term, product.attributes);
            rowData.push(cellRate !== null ? (isNaN(cellRate) ? "\""+cellRate+"\"" : formatRate(cellRate)) : "N/A");
        });
        rows.push(rowData);
    });
    return rows;
};

export const getAttributesOnTerm = (term, attributes) => {
    if (term.value_type === PRODUCT_ATTRIBUTE.GROUP) {
        let attrs;
        if (term.name === TERM_GROUP._6_8M) {
            attrs = attributes.filter(attr => (
                attr.value_type === PRODUCT_ATTRIBUTE.TERM && parseInt(attr.name) >= 180 && parseInt(attr.name) <= 240 &&
                (attr.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attr.value !== null)
            ));
        } else if (term.name === TERM_GROUP._9_11M) {
            attrs = attributes.filter(attr => (
                attr.value_type === PRODUCT_ATTRIBUTE.TERM && parseInt(attr.name) > 240 && parseInt(attr.name) <= 359 &&
                (attr.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attr.value !== null)
            ));
        } else if (term.name === TERM_GROUP._1YR) {
            attrs = attributes.filter(attr => (
                attr.value_type === PRODUCT_ATTRIBUTE.TERM && parseInt(attr.name) === 360 &&
                (attr.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attr.value !== null)
            ));
        } else if (term.name === TERM_GROUP._1YR_PLUS) {
            attrs = attributes.filter(attr => (
                attr.value_type === PRODUCT_ATTRIBUTE.TERM && parseInt(attr.name) > 360 &&
                (attr.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attr.value !== null)
            ));
        }
        return attrs;
    } else {
        const attr = attributes.find(function(element) { return element.name === term.name;});
        if (attr !== undefined) {
            return [attr]
        } else {
            return [];
        }
    }
};

export const getMaxValue = (term, attributes) => {
    const attrs = getAttributesOnTerm(term, attributes);
    if (attrs.length === 1) {
        return attrs[0].visibility === PRODUCT_VISIBILITY.ON_REQUEST ?getOnRequestFormatted(): attrs[0].value;
    }
    if (attrs.every(element => element.value === null)) {
        if (attrs.some(element => element !== null && element.visibility === PRODUCT_VISIBILITY.ON_REQUEST)) {
            return getOnRequestFormatted();
        }
        return null;
    }
    return Math.max.apply(Math, attrs.map(function (attr) {return attr.value}));
};


export const getMaxValueForCSVFile = (term, attributes) => {
    const attrs = getAttributesOnTerm(term, attributes);
    const onRequestValue = "-";
    if (attrs.length === 1) {
        return attrs[0].visibility === PRODUCT_VISIBILITY.ON_REQUEST ? onRequestValue : attrs[0].value;
    }
    if (attrs.every(element => element.value === null)) {
        if (attrs.some(element => element !== null && element.visibility === PRODUCT_VISIBILITY.ON_REQUEST)) {
            return onRequestValue;
        }
        return null;
    }
    return Math.max.apply(Math, attrs.map(function (attr) {return attr.value}));
};

export const getOnRequestFormatted = () => {
    return <Fragment> <span className='on-request'>Rate on request</span> <span className='on-request-print'>RoR</span></Fragment>;
}

export const termWasRecentlyUpdated = (term) => {
    // a term is considered recently updates if it eas updated in the last 10 seconds
    const x = 10;
    const diff = term.updated_date !== null ? moment.utc().diff(moment.utc(term.updated_date), 'seconds') : null;
    return diff !== null && diff <= x;
};

export const isRateLiveToday = (product) => {
    return product.show_live_rate_datetime && DateUtil.isToday(product.show_live_rate_datetime);
};

export const isRateLiveInTheLast60Seconds = (product) => {
    return product.show_live_rate_datetime && DateUtil.isInTheLast60_Seconds(product.show_live_rate_datetime);
};

class RenderAvailability extends Component {
	render(){
	    return(
	        <div className="availability" tooltip={getAvailabilityLabel(this.props.product.availability)} flow="up">
	            <div className={this.props.product.availability}></div>
	        </div>
	    );
	}
};

class RenderBankName extends Component {
    render(){
        const bankNameClass = 'bank-name '+(this.props.bank.name?"":"hide");
        const bankImageUrl = getBankImageUrl(this.props.bank.name, this.props.bank.logo);
        return(
            <div className="bank-column">
                <div className={bankNameClass}>
                    <BankImage bankImageUrl={bankImageUrl} bankName={this.props.bank.name}/>
                </div>
                {this.props.bank.fossil_fuel_free === 'FOSSIL_FUEL_FREE' &&
                <div className="fossil-fuel-free" tooltip="Fossil Fuel free – See 'About' for more info" flow="right"/>
                }
                {this.props.bank.financial_scheme === FINANCIAL_SCHEME.ADI_BRANCH_OF_FOREIGN_BANK &&
                <div className="branch-foreign-bank" tooltip="Branch of a Foreign Bank – See 'About' for more info" flow="right"/>
                }
            </div>
        );
    }
};
