import React from 'react';

const Checkbox = (props) => {

    return (
        <label className={"curves-checkbox checked-"+props.checked}>
            <input type="checkbox" {...props} />
            { props.label }
        </label>
    );

}

export default Checkbox;
