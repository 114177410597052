import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {formatAmount} from "../../common/formatUtil";
import {
    getBankRatingTooltipMessage,
    getBankRating
} from "../../common/bankRatingUtil";
import {getBankImageUrl} from "../../common/bankImageUtil";
import SemiCircleProgress from "../common/SemiCircleProgress";
import BankImage from "./BankImage";
import {CSSTransition} from "react-transition-group";
import CountUp from 'react-countup';
import MaturityEOI from "./MaturityEOI";
import {CONTRACT_STATUS_TYPES} from "../../common/types/contractStatusTypes";
import {PRODUCT_TYPES} from "../../common/types/productTypes";

class MaturityCard extends Component {


    constructor(props) {
        super(props);

        this.selectTerm = this.selectTerm.bind(this);
        this.rolloverSuccess = this.rolloverSuccess.bind(this);
        this.redeemSuccess = this.redeemSuccess.bind(this);

        //Force Live status for admin preview
        const contractStatus = this.props.adminPreview?CONTRACT_STATUS_TYPES.LIVE:this.props.maturity.contract.status;
        const showLiveRate = this.props.adminPreview || (contractStatus == CONTRACT_STATUS_TYPES.LIVE && this.props.maturity.contract.set_live_date!==null);
        const allowRollover = (contractStatus == CONTRACT_STATUS_TYPES.LIVE || contractStatus == CONTRACT_STATUS_TYPES.PENDING);

        this.state = {
            open: this.props.maturity.contract.id === this.props.openProductId,
            allowRollover: allowRollover,
            showLiveRate : showLiveRate,
            contractStatus: contractStatus,
            contractActionedStatus: this.props.maturity.contract.actioned_status
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.openProductId !== prevProps.openProductId) {
            this.setState({open: this.props.maturity.contract.id === this.props.openProductId });
        }
    }

    selectTerm(productId) {
        if (this.props.openProductId === productId) {
            this.props.expressInterest(null);
        } else {
            this.props.expressInterest(productId);
        }
    }


    isEOIOpened() {
        return this.state.open;
    }


    renderDropDownRates() {
        return (
            <svg>
                <use xlinkHref={this.isEOIOpened() ? '#curves-svg-triangle-up' : '#curves-svg-triangle-down'}></use>
            </svg>
        )
    }

    /**
     *
     * @param rolloverSameBank indicate if the user made a Rollover on the same bank
     * @param rateRequestSubmitted indicate if the user only asked for rates
     */
    rolloverSuccess(rolloverSameBank, rateRequestSubmitted) {
        if(!rateRequestSubmitted){
            this.setState({
                open: false,
                allowRollover: false,
                contractStatus: CONTRACT_STATUS_TYPES.SUBMITTED,
                contractActionedStatus: rolloverSameBank ? 'Rolled' : 'Switched'
            });
        }else {
            this.setState({
                open: false,
            });
        }
    }

    /**
     *
     * @param rolloverSameBank indicate if the user made a Rollover on the same bank
     */
    redeemSuccess() {
        this.setState({
            open: false,
            allowRollover: false,
            contractStatus: CONTRACT_STATUS_TYPES.REDEEMED,
        });
    }

    getMessageByStatus(contractStatus, actionedStatus) {
        if (contractStatus === CONTRACT_STATUS_TYPES.SUBMITTED) {
            if (actionedStatus !== null) {
                return actionedStatus + " Call 02 9690 2188 to adjust";
            }
            return "We are working on your instructions now you'll get  a confirmation shortly";
        } else if (contractStatus === CONTRACT_STATUS_TYPES.ACTIONED) {
            return "Your instructions have been processed";
        } else if (contractStatus === CONTRACT_STATUS_TYPES.REDEEMED) {
            return "Redeemed Call 02 9690 2188 to adjust";
        }
    }

    showActionTitle(){
        if(this.state.contractStatus === CONTRACT_STATUS_TYPES.PENDING){
            return 'Pending';
        }
        if(this.state.contractStatus === CONTRACT_STATUS_TYPES.LIVE) {
            return <div className={"live"}>Live<div className='live-circle rec'></div> </div>;
        }
        return 'Actioned';

    }


    render() {
        const ratesToShow = [];
        const bankImageUrl = this.props.maturity.main_product.bank && getBankImageUrl(this.props.maturity.main_product.bank.name, this.props.maturity.main_product.bank.logo);

        return (
            <div id={"contract-" + this.props.maturity.contract.id} className={'curves-product-card maturity live-rate ' +
            (this.state.open ? ' open-product' : '') +
            (ratesToShow.length > 9 ? ' small-font' : '') +
            (this.props.maturity.contract.product_type === PRODUCT_TYPES.TERM_DEPOSIT ? 'term-deposit' : ' ncd')}
            >
                {!this.state.allowRollover &&
                    <div className="card-overlay">
                        <div className="hover-text">
                            <div>
                                <h2>{this.getMessageByStatus(this.state.contractStatus, this.state.contractActionedStatus)}</h2>
                            </div>
                        </div>
                    </div>
                }
                <div className="main" onClick={() => this.selectTerm(this.props.maturity.contract.id)}>
                    <div className="bank-column">
                        <div className="img">
                            <BankImage bankImageUrl={bankImageUrl} bankName={this.props.maturity.main_product.bank.name}/>
                        </div>
                        {this.props.maturity.main_product.bank.short_rating && this.props.maturity.main_product.bank.long_rating &&
                        <div className="rating" tooltip={getBankRatingTooltipMessage()} flow="right">
                            {getBankRating(this.props.maturity.main_product.bank)}
                        </div>
                        }
                    </div>

                    <div className="rates-column">
                        <div className="best-rate rates">
                            <div className={this.isEOIOpened() ? "tab-item opened" : "tab-item"}>
                                <div className="rate">
                                    {this.showActionTitle()}
                                    <span className="special-arrow">{this.renderDropDownRates()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.maturity.contract.product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT &&
                        <div className="consideration-column">
                            <div className="label">Face Value</div>
                            <SemiCircleProgress fillAngle={180}>
                                <div>${formatAmount(this.props.maturity.contract.face_value)}</div>
                            </SemiCircleProgress>
                        </div>
                    }
                    {this.props.maturity.contract.product_type === PRODUCT_TYPES.TERM_DEPOSIT &&
                        <div className="consideration-column">
                            <div className="label">Consideration Amount</div>
                            <SemiCircleProgress fillAngle={180}>
                                <div>${formatAmount(this.props.maturity.contract.consideration)}</div>
                            </SemiCircleProgress>
                        </div>
                    }
                    <div className="interest-column">
                        <div className="label">Interest from maturity</div>
                        <div className="interest">
                            <p className="value">
                                <CountUp
                                    end={this.props.maturity.contract.interest}
                                    separator= ","
                                    prefix= "$"
                                    decimals={2}
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <CSSTransition in={this.state.open} timeout={1000} classNames="eoi-node" unmountOnExit>
                    <MaturityEOI maturity={this.props.maturity}
                                 showLiveRate = {this.state.showLiveRate}
                                 rolloverSuccessAction={this.rolloverSuccess}
                                 redeemSuccessAction={this.redeemSuccess}/>
                </CSSTransition>
            </div>
        )
    }
}

export default withRouter(MaturityCard);
