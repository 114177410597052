import axios from "axios";

class UserFilterService {

    static createOrUpdate(userFilters) {
        return new Promise((resolve, reject) => {
            axios.post('/api/client/user_filters', userFilters).then(() => {
                resolve();
            });
        });
    }

    static delete(name) {
        return new Promise((resolve, reject) => {
            axios.delete('/api/client/user_filters/' + name.trim()).then(() => {
                resolve();
            });
        });
    }

    static findAll() {
        return new Promise((resolve, reject) => {
            axios.get('/api/client/user_filters').then(response => {
                resolve(response.data);
            });
        });
    }

}

export default UserFilterService;
