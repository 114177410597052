import React, { Component } from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";
import UserFilterService from "../../service/UserFilterService";
import ConfirmDialog from "./ConfirmDialog";

class SavedFiltersDialog extends Component {

    constructor(props) {
        super(props);

        this.accept = this.accept.bind(this);
        this.delete = this.delete.bind(this);
        this.validate = this.validate.bind(this);

        this.state = {
            filterName: '',
            validationErrors: {}
        };
    }

    componentDidMount() {
        UserFilterService.findAll().then(userFilters => {
            this.setState({userFilters});
        });
    }

    validate() {
        let validationErrors = {};

        if (this.state.filterName.trim() === '') {
            validationErrors.filterName = 'A name is required.';
        }

        this.setState({
            validationErrors
        });

        return validationErrors;
    }

    accept() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const userFilter = this.state.userFilters.find(it => it.name === this.state.filterName);
        DialogUtil.resolve(userFilter);
    }

    delete() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        DialogUtil.open(<ConfirmDialog message={"Delete user filter named '" + this.state.filterName + "' ?"} />).then(() => {
            UserFilterService.delete(this.state.filterName).then(() => {
                DialogUtil.close();
            });
        });
    }

    render() {
        return (
            <SimpleDialog className="curves-saved-filters-dialog" title="Saved filters"
                          onClickOutside={DialogUtil.close}>
                <label className="filter-name">
                    Filter:
                    {this.state.userFilters &&
                        <select value={this.state.filterName}
                                onChange={e => this.setState({filterName: e.target.value})} >
                            <option></option>
                            {this.state.userFilters.map(userFilter =>
                                <option key={userFilter.name}>{userFilter.name}</option>)}
                        </select>
                    }
                    <div className="validation-errors">{this.state.validationErrors.filterName}</div>
                </label>
                <div className="options">
                    <a className="cancel-btn" onClick={DialogUtil.close}>Cancel</a>
                    <a className="cancel-btn" onClick={this.delete}>Delete ...</a>
                    <a className="confirm-btn" onClick={this.accept}>Accept</a>
                </div>
            </SimpleDialog>
        );
    }

}

export default SavedFiltersDialog;
