import moment from "moment";

class CryptoUtil {

    static hashString(string) {
        let hash = 0;
        if (string.length == 0) return hash;
        for (let i = 0; i < string.length; i++) {
            hash = ((hash<<5)-hash)+string.charCodeAt(i);
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    static hashJSON(json) {
       return this.hashString(JSON.stringify(json));
    }

}

export default CryptoUtil;
