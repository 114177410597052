import React from 'react';
import UserStatService from "../../service/UserStatService";
import DialogUtil from "../../common/DialogUtil";
import TermsAndConditionsDialog from "../dialog/TermsAndConditionsDialog";
import GlobalStore from "../store/GlobalStore";
import FloatFooter from './FloatFooter';
import OnboardingDefinitionsDialog from "../dialog/OnboardingDefinitionsDialog";
import PrivacyPolicyDialog from '../dialog/PrivacyPolicyDialog';

const Footer = ({globalStore}) => {

    return (


        <div className="curves-footer">
            <FloatFooter/>

            <div className="disclaimer">
                <div className="first-column">
                    <p>DISCLAIMER</p>
                    <p>
                        This website is not intended to imply a recommendation or otherwise constitute advice in relation to financial products.
                        It does not take into account your investment objectives, financial situation or particular needs.
                        Before acting on any information you obtain from this website you need to consider the
                        appropriateness of the information in lieu of your investment objectives, financial situation or needs.
                    </p>
                    <p>
                        Curve Securities or Yield Hub may receive a platform fee / commission from an institution receiving funds as a result of opportunities being sourced through the Yield Hub platform or Curve Securities.
                    </p>
                    <p>
                        © {new Date().getFullYear()} Copyright – Yield Hub Pty Ltd ABN (60 647 213 536) Authorised Representative 001286144 of Curve Securities Pty LTD AFSL 405751
                    </p>
                    <p>
                        <a href="#" onClick={() => {
                            UserStatService.createPageLoadUserStat('PRIVACY_POLICY');
                            DialogUtil.open(<PrivacyPolicyDialog acceptTerms={() => {
                                return;
                            }}/>);
                        }}> Privacy policy</a> |
                        <a href="#" onClick={() => {
                            UserStatService.createPageLoadUserStat('TERMS_AND_CONDITIONS');
                            DialogUtil.open(<TermsAndConditionsDialog acceptTerms={() => {
                                return;
                            }}/>);
                        }}> End User Terms and Conditions</a> |
                        <a href="#" onClick={()=> {DialogUtil.open(<OnboardingDefinitionsDialog/>)}}> Yield Hub Onboarding Definitions</a>
                    </p>
                </div>
                <div className="second-column">
                    <div className="powered-by">
                        Rates Powered By
                    </div>
                    <img src="/img/Curve_Mono_Logo_White_RGB.svg" />
                </div>
            </div>
        </div>

    );

};

//export default Footer;
export default GlobalStore.connect(Footer);
