import React, {Component, Fragment} from 'react';
import {format_num, formatAmount, formatTermRate, getAttributeRate, strip_num} from "../../common/formatUtil";
import EOIService from '../../service/EOIService'
import {
    hasTermDepositBehavior,
    PRODUCT_TYPES,
    productSecondaryLabels
} from "../../common/types/productTypes";
import {formatTermName} from "../../common/termLabel";
import Spinner from "../common/Spinner";
import moment from "moment";
import {PRODUCT_VISIBILITY} from "../../common/types/productVisibilityTypes";
import {getFullBankRating} from "../../common/bankRatingUtil";
import {fossilFuelFreeLabel} from "../../common/types/fossilFuelFreeTypes";
import MaturityDateUtil from "../../common/MaturityDateUtil";
import MaturityDatePicker from "../common/MaturityDatePicker";
import NonStandardTermForm from "./NonStandardTermForm";
import DateUtil from "../../common/DateUtil";


class EOIForm extends Component {

    constructor(props) {
        super(props);
        this.changeEOIAmount = this.changeEOIAmount.bind(this);
        this.selectTerm = this.selectTerm.bind(this);
        this.submitEOI = this.submitEOI.bind(this);
        this.submitEOI_NonStandardTerm = this.submitEOI_NonStandardTerm.bind(this);
        this.renderEOISelectAmount = this.renderEOISelectAmount.bind(this)
        this.requestRate = this.requestRate.bind(this);
        this.renderEOIForm = this.renderEOIForm.bind(this);
        this.renderRequestMoreInformationForm = this.renderRequestMoreInformationForm.bind(this);
        this.renderOnRequestForm = this.renderOnRequestForm.bind(this);
        this.renderSuccessSubmit = this.renderSuccessSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.validate = this.validate.bind(this);
        this.eoiTerm = this.eoiTerm.bind(this);
        this.isNonStandardTerm = this.isNonStandardTerm.bind(this);
        this.termSelected = this.termSelected.bind(this);
        this.makeOverflowTemporaryHidden = this.makeOverflowTemporaryHidden.bind(this);
        this.closeNonStandardTermForm = this.closeNonStandardTermForm.bind(this);
        this.state = {
            eoiValue: props.product.min_deposit,
            comments:'',
            submitOk: null,
            sending: false,
            validationErrors: {},
            overflow: 'hidden'
        };
    }

    componentDidMount() {

        /**
         * HACK: to show date picker I need overflow: visible, not hidden.
         */
        setTimeout(() => {
            this.setState({overflow: 'visible'});
        }, 1000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.term && this.props.term && prevProps.term.name != this.props.term.name) {
            this.setState({
                submitOk: null
            });
        }

        /**
         * Force re render when some properties changes.
         */
        if (prevProps.maturityDate !== this.props.maturityDate) {
            this.setState({
                status: undefined
            });
        }

    }

    changeEOIAmount(event) {
        try {
            this.setState({
                eoiValue: strip_num(event.target.value)
            });
        }
        catch (e) {

        }
    }

    selectTerm(term) {
        if (term === this.eoiTerm()) {
            this.props.closeEOIForm();
        } else {
            this.props.selectMaturityDate(MaturityDateUtil.closestMoment(term.name));
        }
    }

    /**
     * Submit EOI for non standard term.
     */
    submitEOI_NonStandardTerm() {
        if (this.state.eoiValue == 0) {
            return;
        }

        this.setState({status: 'NON_STANDARD_TERM_FORM'});
        this.makeOverflowTemporaryHidden();
    }

    submitEOI() {
        if (this.state.eoiValue == 0) {
            return;
        }
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const eoi = {
            product_id: this.props.product.id,
            product_attribute_id: this.eoiTerm().id,
            amount: this.state.eoiValue,
            maturity_day_diff: moment(this.props.maturityDate).startOf('day').diff(moment().startOf('day').add(parseInt(this.eoiTerm().name), 'days'), 'days'),
            additional_note: this.state.comments
        };
        this.setState({sending: true});
        EOIService.post(eoi)
            .then((response) => {
                this.setState({submitOk: true, sending: false, comments:''});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false, comments:''});
            });
    }

    requestRate() {
        const eoi = {
            product_id: this.props.product.id,
            product_attribute_id: this.eoiTerm().id,
            amount: this.state.eoiValue
        };
        this.setState({sending: true});
        EOIService.requestRate(eoi)
            .then((response) => {
                this.setState({submitOk: true, sending: false});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false});
            });
    }

    requestMoreInformation(){
        const eoi = {
            product_id: this.props.product.id,
            amount: this.state.eoiValue
        };
        this.setState({sending: true});
        EOIService.requestMoreInformation(eoi)
            .then((response) => {
                this.setState({submitOk: true, sending: false});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false});
            });

    }

    isEOISubmitFail() {
        return this.state.submitOk !== null && this.state.submitOk === false;
    }

    isEOISubmitSuccess() {
        return this.state.submitOk !== null && this.state.submitOk === true;
    }

    closeSuccessInformation() {
        this.setState({
            submitOk: null
        });
    }

    validate() {
        const validationErrors = {};

        if (isNaN(this.state.eoiValue)) {
            validationErrors.eoiAmount = 'Must be a number.';
        }
        else if (this.state.eoiValue < this.props.product.min_deposit) {
            validationErrors.eoiAmount = 'Must be greater than $ ' + formatAmount(this.props.product.min_deposit);
        }
        else if (this.state.eoiValue > this.props.product.max_deposit) {
            validationErrors.eoiAmount = 'Must be less than $ ' + formatAmount(this.props.product.max_deposit);
        }

        this.setState({validationErrors});

        return validationErrors;
    }

    /**
     * Is non standard term.
     * Selected maturity date doesn't belong to a term.
     *
     * @returns {null|boolean}
     */
    isNonStandardTerm() {
        return this.props.maturityDate && !this.eoiTerm();
    }

    /**
     * A term is selected.
     * Maturity date is not null/undefined.
     *
     */
    termSelected() {
        return this.props.maturityDate;
    }

    /**
     * Return current term if there is one.
     *
     * @returns {number | never | bigint | T | T | *}
     */
    eoiTerm() {
        return this.props.productCardTerms.find(term => (term.value || term.visibility === PRODUCT_VISIBILITY.ON_REQUEST)
            && MaturityDateUtil.getPossibleMaturityMoments(term.name)
                .some(mom => mom.isSame(this.props.maturityDate, 'day')));
    }

    makeOverflowTemporaryHidden() {
        this.setState({overflow: 'hidden'});

        /**
         * HACK: to show date picker I need overflow: visible, not hidden.
         */
        setTimeout(() => {
            this.setState({overflow: 'visible'});
        }, 1000)
    }

    /**
     * Close non standard term form.
     *
     */
    closeNonStandardTermForm() {

        this.setState({
            eoiValue: 0,
            status: undefined
        });

    }

    renderEOISelectAmount() {
        if (!this.termSelected()) {
            return (
                <div className="select-term"><p>Please select a Term from above to continue.</p></div>
            );
        } else {
            return (
                <Fragment>
                    {this.state.sending && <div className="eoi-sending"><Spinner/></div>}
                    <div className="eoi-data">
                        <div className="eoi-title">EXPRESSION OF INTEREST</div>
                        {this.isEOISubmitFail() &&
                        <div className="error-message">An error occurs, please try again later</div>
                        }
                        {this.isEOISubmitSuccess() ?
                            this.renderSuccessSubmit()
                            :
                            this.renderForm()
                        }
                    </div>
                </Fragment>
            )
        }
    }

    renderEOIForm() {
        // noinspection JSAnnotator
        return (
            <div>
                <div className="eoi-range-container">
                    <label>
                        Amount $
                        <div>
                            <input type="text"
                                   value={format_num(this.state.eoiValue)}
                                   onChange={event => this.changeEOIAmount(event)}/>
                            <div className="validation-errors">{this.state.validationErrors.eoiAmount}</div>
                        </div>
                    </label>
                </div>
                <div className="maturity-date">
                    <label>
                        Maturity Date:
                        <MaturityDatePicker value={moment(this.props.maturityDate)}
                                            onChange={this.props.selectMaturityDate}
                                            productCardTerms={this.props.productCardTerms.filter(term => term.value !== null && !term.maturity_has_better_rate)} />
                    </label>
                </div>

                <div className="comments">
                    <label>
                        Comments:
                    </label>
                    <div>
                          <textarea className="width-100per-minus-100px margin-top-10px margin-right-50px margin-bottom-10px height-60px background-color-grey6"
                                    onChange={e => this.setState({comments: e.target.value})}
                                    value={this.state.comments} />
                    </div>
                </div>


                <div className="text-align-center eoi-btn">
                    {this.isNonStandardTerm() ?
                        <input type="submit"
                               className={this.state.eoiValue == 0 ? 'disabled' : ""}
                               onClick={event => this.submitEOI_NonStandardTerm(event)}
                               value="Continue"/>
                        :
                        <input type="submit"
                               className={this.state.eoiValue == 0 ? 'disabled' : ""}
                               onClick={event => this.submitEOI(event)}
                               value="Submit EOI"/>
                    }
                    <div className="disable-eoi-message">Please enter an amount</div>
                </div>
            </div>
        );
    }

    renderOnRequestForm() {
        return (
            <div className="rate-request">
                <p>This rate is available on request</p>
                <input type="submit" onClick={event => this.requestRate(event)} value="Request rate"/>
            </div>
        );
    }

    renderRequestMoreInformationForm() {
        return (
            <div className="rate-request">
                <p>Request more information about this product</p>
                <input type="submit" onClick={event => this.requestMoreInformation(event)} value="Submit"/>
            </div>
        );
    }

    renderForm(){
        if(this.props.product.permission_dto.allow_eoi){
            if(this.props.product && hasTermDepositBehavior(this.props.product)){
                if(this.eoiTerm() !== undefined && this.eoiTerm().visibility === PRODUCT_VISIBILITY.ON_REQUEST){
                    return this.renderOnRequestForm();
                } else{
                    return this.renderEOIForm();
                }
            }else{
                return this.renderRequestMoreInformationForm();
            }
        }else{
            return (
                <div></div>
            )
        }


    }



    renderSuccessSubmit() {
        return (
                <div className="success-eoi-container">
                    <h3>Sent message</h3>
                    <p>Thank you - A Curve team member will be in touch with you shortly.</p>
                    <div className="button-container">
                        <button className="accept-btn" onClick={() => this.closeSuccessInformation()}>DONE</button>
                    </div>
                </div>
        );
    }

    renderRecentlyUpdatedLabel(term) {
        const diff = term.updated_date !== null ? moment.utc().diff(moment.utc(term.updated_date), 'minutes') : null;
        if (diff !== null && diff <= 20) {
            return (
                <span className="recently-updated" tooltip={'Updated '+diff+" minutes ago"} flow="up">
                    <svg>
                        <use xlinkHref="#curves-svg-clock"></use>
                    </svg>
                </span>
            )
        }
    }

    renderVariableMargin() {
        const term = this.eoiTerm();
        if (term !== undefined && term.variable_margin !== null) {
            return (
                <div className="variable-margin">
                    {formatTermName(term.variable_margin.term, true) + " + " + term.variable_margin.margin}
                </div>
            )
        }
    }

    render() {
        if(this.props.showOnlyOnRequestLabel){
            this.props.productCardTerms.forEach(function (term) {
                term.visibility=PRODUCT_VISIBILITY.ON_REQUEST;
            });
        }
        return (
            <div className="curves-eoi" style={{overflow: this.state.overflow}}>

                {this.props.term && this.props.term.attributes.length > 1 &&
                <div className="select-term">
                    <table className="sub-terms">
                        <tbody>
                        <tr>
                            {this.props.term.attributes.map(term => {
                                if (term.value === '') {
                                    return <td className="disabled" key={term.name}>
                                        <div className="term-rate-div">
                                            <p className="term">{formatTermName(term.name)}</p>
                                            <p className="rate">{formatTermRate(term)}</p>
                                        </div>
                                    </td>
                                } else {
                                    return <td key={term.name}
                                               onClick={() => !term.maturity_has_better_rate && this.selectTerm(term)}
                                               className={'position-relative hoverable ' + (term === this.eoiTerm() ? " selected" : '')}>
                                        {term.maturity_has_better_rate &&
                                            <Fragment>
                                                <div className="position-absolute left-0 top-0 width-100per height-100per background-white opacity-06 cursor-not-allowed-important background-color-light-green-important"></div>
                                                <div className="curves-popover-message popover-top z-index-1 width-200px left-50per top-48px translate-minus-50per-0 white-space-normal">
                                                    There are maturities with higher rates for this bank.
                                                </div>
                                            </Fragment>
                                        }
                                        <div className="term-rate-div">
                                            <p className="term">{formatTermName(term.name)}</p>
                                            <p className="rate">{formatTermRate(term)} {this.renderRecentlyUpdatedLabel(term)}</p>
                                        </div>
                                    </td>
                                }
                            } )
                            }
                        </tr>
                        </tbody>
                    </table>
                </div>
                }
                <div className="eoi-container position-relative">
                    <div className={"eoi-row transition-all-400ms-ease-in-out"
                                + (this.props.product.product_category !== 'SPECIAL' ? ' white-space-nowrap' : '')
                                + (this.state.status === 'NON_STANDARD_TERM_FORM' ? ' overflow-hidden max-width-0' : ' max-width-100per')
                                + (this.state.overflow === 'hidden' ? ' overflow-hidden' : '')}>
                        <div className="product-details">
                            <div className="product-title">
                                <div className="product-information">
                                    <div className="type">
                                        {
                                            productSecondaryLabels(this.props.product.type) + ((this.props.product.bank && this.props.product.bank.name)?" - "+this.props.product.bank.name:"")
                                        }
                                    </div>
                                    <div className="term">
                                        {this.props.maturityDate && (this.eoiTerm() !== undefined ? formatTermName(this.eoiTerm().name,true) : "Non Standard Term")}
                                    </div>
                                </div>
                                {hasTermDepositBehavior(this.props.product) &&
                                    <div className="product-term">
                                        <div className="term-rate">
                                            {this.props.maturityDate && (this.eoiTerm() !== undefined ? getAttributeRate(this.eoiTerm()) : (moment(this.props.maturityDate).diff(moment(), 'day') + " days"))}
                                        </div>
                                        {this.renderVariableMargin()}
                                    </div>
                                }
                            </div>
                            <div className="description-section">
                                <div className="description">
                                    <div className="description-item"><span>Rating (S&P/Moody's/Fitch):</span> {getFullBankRating(this.props.product.bank)}</div>
                                    <div className="description-item"><span>Market Forces Classification:</span> {this.props.product.bank?fossilFuelFreeLabel(this.props.product.bank.fossil_fuel_free):"-"}</div>
                                    {(this.props.product.product_type !== PRODUCT_TYPES.NOTICE_ACCOUNT && this.props.product.product_type !== PRODUCT_TYPES.CASH_ACCOUNT) &&
                                        <div className="description-item"><span>Deposit Limits: </span>
                                            <span> Min </span> {this.props.product.min_deposit > 0 ? ("$" + formatAmount(this.props.product.min_deposit)) : "-"} | <span>Max </span> {this.props.product.max_deposit > 0 ? ("$" + formatAmount(this.props.product.max_deposit)) : "-"}
                                        </div>
                                    }
                                    {this.props.product.information &&
                                        <Fragment>
                                            <div className="description-item"><span>Additional Notes:</span></div>
                                            <div className="additional-notes">
                                                {this.props.product.information}
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                                {hasTermDepositBehavior(this.props.product) &&
                                    <div className="status">
                                        {(!this.props.product.show_live_rate_datetime ||
                                            !DateUtil.isToday(this.props.product.show_live_rate_datetime)) &&
                                        this.props.product.product_category === 'PRODUCT' &&
                                        <Fragment>Indicative Pending Update</Fragment>
                                        }
                                        <br></br>
                                        { this.props.showRolloverOnlyWording && this.props.showRolloverOnlyWording === true && "Rollovers Only"}
                                    </div>
                                }
                            </div>
                            <div className="disclaimer">
                                <p>
                                    Rates are indicative at the time of publishing and are subject to change. Deals are
                                    subject to confirmation by a Curve representative.
                                </p>
                                <p>
                                    Please note break fees may apply for the early redemption or sale of this investment.
                                    Some term deposits may be unbreakable or have a notice period required, please refer
                                    to the terms and conditions. A copy of the terms and conditions relating to this
                                    deposit is available upon request.
                                </p>
                            </div>
                        </div>
                        <div className="eoi-select-amount">
                            {this.renderEOISelectAmount()}
                        </div>
                    </div>
                    <NonStandardTermForm productId={this.props.product.id}
                                         amount={this.state.eoiValue}
                                         maturityDate={this.props.maturityDate}
                                         show={this.state.status === 'NON_STANDARD_TERM_FORM'}
                                         close={this.closeNonStandardTermForm} />
                </div>
            </div>
        )
    }

}

export default EOIForm;
