import React from 'react';
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import termLabel from "../../common/termLabel";
import {fossilFuelFreeLabel} from "../../common/types/fossilFuelFreeTypes";
import {formatLongRating, formatShortRating} from "../../common/bankRatingUtil";
import {financialSchemeLabel} from "../../common/types/financialSchemeType";
import FilterUtil, {
    FILTER_ALL, FILTER_DEPOSIT_MAX, FILTER_DEPOSIT_MIN, FILTER_FOSSIL_FUEL_FREE, FILTER_INSTITUTION_TYPE,
    FILTER_RATING_LONG_TERM,
    FILTER_RATING_SHORT_TERM,
    FILTER_TERM
} from "../../common/FilterUtil";

/**
 * Format amount: 1000 => 1K, 1000000 => 1M
 *
 * @param inputRangeValue
 * @returns {string}
 */
const formatDepositLimit = inputRangeValue =>
    inputRangeValue < 1000000 ? (inputRangeValue / 1000) + 'K' : (inputRangeValue / 1000000) + 'M';


const FilterTags = ({location, history}) => {
    const urlQueryParams = queryString.parse(location.search);

    /**
     * Sort terms.
     */
    const filterTerm = getArrayQueryParam(urlQueryParams, FILTER_TERM.label);
    filterTerm.sort((a, b) => a - b);

    /**
     * Sort short term ratings.
     */
    const filterRatingShortTerm = getArrayQueryParam(urlQueryParams, FILTER_RATING_SHORT_TERM.label);
    filterRatingShortTerm.sort();

    /**
     * Sort long term ratings.
     */
    const filterRatingLongTerm = getArrayQueryParam(urlQueryParams, FILTER_RATING_LONG_TERM.label);
    filterRatingLongTerm.sort();


    /**
     * Sort Fossil fuel free.
     */
    const filterFossilFuelFree = getArrayQueryParam(urlQueryParams, FILTER_FOSSIL_FUEL_FREE.label);
    filterFossilFuelFree.sort();

    /**
     * Sort Institution type.
     */
    const filterInstitutionType = getArrayQueryParam(urlQueryParams, FILTER_INSTITUTION_TYPE.label);
    filterInstitutionType.sort();

    return(
        <div className="curves-filter-tags">
            {filterTerm.map((term, idx) =>
                <div key={idx} className="term">{termLabel(term)} <a onClick={() => FilterUtil.removeFilter(FILTER_TERM, term, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>)}
            {filterRatingShortTerm.map((rating, idx) =>
                <div key={idx} className="rating">S. Term {formatShortRating(rating)} <a onClick={() => FilterUtil.removeFilter(FILTER_RATING_SHORT_TERM, rating, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>)}
            {filterRatingLongTerm.map((rating, idx) =>
                <div key={idx} className="rating">L. Term {formatLongRating(rating)} <a onClick={() => FilterUtil.removeFilter(FILTER_RATING_LONG_TERM, rating, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>)}
            {filterFossilFuelFree.map((fossil_fuel_free, idx) =>
                <div key={idx} className="fossil-fuel-free"> {fossilFuelFreeLabel(fossil_fuel_free)} <a onClick={() => FilterUtil.removeFilter(FILTER_FOSSIL_FUEL_FREE, fossil_fuel_free, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>)}
            {urlQueryParams.filterDepositMin &&
                <div className="deposit">Min. deposit: {formatDepositLimit(urlQueryParams.filterDepositMin)} <a onClick={() => FilterUtil.removeFilter(FILTER_DEPOSIT_MIN, urlQueryParams.filterDepositMin, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>}
            {urlQueryParams.filterDepositMax &&
                <div className="deposit">Max. deposit: {formatDepositLimit(urlQueryParams.filterDepositMax)} <a onClick={() => FilterUtil.removeFilter(FILTER_DEPOSIT_MAX, urlQueryParams.filterDepositMax, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>}
            {filterInstitutionType.map((financial_scheme, idx) =>
                <div key={idx} className="financial-scheme"> {financialSchemeLabel(financial_scheme)} <a onClick={() => FilterUtil.removeFilter(FILTER_INSTITUTION_TYPE, financial_scheme, location, history)}><svg><use xlinkHref="#curves-svg-close"></use></svg></a></div>)}
            {FilterUtil.haveAnyFilterApplied(location) &&
                <div className="clear-all"><a onClick={() => FilterUtil.removeFilter(FILTER_ALL, null, location, history)}>Clear all filters</a></div>}
        </div>
    );
};

export default withRouter(FilterTags);
