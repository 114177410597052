import React, { Component } from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";

class SaveFilterDialog extends Component {

    constructor(props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.accept = this.accept.bind(this);

        this.state = {
            filterName: '',
            validationErrors: {}
        };
    }

    validate() {
        let validationErrors = {};

        if (this.state.filterName.trim() === '') {
            validationErrors.filterName = 'A name is required.';
        }

        if (this.state.filterName.trim().length > 60) {
            validationErrors.filterName = 'Name too long.';
        }

        this.setState({
            validationErrors
        });

        return validationErrors;
    }

    accept() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        DialogUtil.resolve(this.state.filterName);
    }

    render() {
        return (
            <SimpleDialog className="curves-save-filter-dialog" title="Save this filter"
                          onClickOutside={DialogUtil.close}>
                <label className="filter-name">
                    Name:
                    <input type="text" value={this.state.filterName} onChange={e => this.setState({filterName: e.target.value})}/>
                    <div className="validation-errors">{this.state.validationErrors.filterName}</div>
                </label>
                <div className="options">
                    <a className="confirm-btn" onClick={DialogUtil.close}>Cancel</a>
                    <a className="confirm-btn" onClick={this.accept}>Save</a>
                </div>
            </SimpleDialog>
        );
    }

}

export default SaveFilterDialog;
