import React, { Component } from 'react';
import {leftMenu, rightMenu} from "../config/routes";
import {NavLink, withRouter} from "react-router-dom";
import queryString from "query-string";
import GlobalStore from "../store/GlobalStore";
import UserStatService from "../../service/UserStatService";
import NotificationsMenu from "./NotificationsMenu";

const preservedQueryParams = (location) => {
    const urlQueryParams = queryString.parse(location.search);

    return queryString.stringify({
        limit: urlQueryParams.limit,
        filterTerm: urlQueryParams.filterTerm,
        filterRatingShortTerm: urlQueryParams.filterRatingShortTerm,
        filterRatingLongTerm: urlQueryParams.filterRatingLongTerm,
        filterDepositMin: urlQueryParams.filterDepositMin,
        filterDepositMax: urlQueryParams.filterDepositMax,
        filterFossilFuelFree: urlQueryParams.filterFossilFuelFree,
        filterInstitutionType: urlQueryParams.filterInstitutionType,
        view: urlQueryParams.view,
        loginAsUser: urlQueryParams.loginAsUser
    });
};

class Menu extends Component {

    constructor(props) {
        super(props);
        this.menuClick = this.menuClick.bind(this);
    }

    menuClick() {
    	GlobalStore.setState({
    		menuOpen: false,
            showGlobalEnquiryForm:false
        });
    }

    showGeneralInquiryForm(){
        UserStatService.createPageLoadUserStat('GENERAL_INQUIRY');

        GlobalStore.setState({
            showGlobalEnquiryForm:true
        })
    }

    filterRoute(idx,route){
        const user = this.props.globalStore.user;
        let onClick = () => this.menuClick();

        if(user && ((route.linkLabel !== 'NCDs' || user.ncd_available) && (route.linkLabel !== 'Yieldhub Analytics' || user.yieldhub_analytics))) {
            // if(route.linkLabel === 'General inquiry'){
            //     onClick = () => this.showGeneralInquiryForm();
            // }
            if(route.level === 0){
                return <li className={route.cssClass} style={{order: route.order}} key={idx}><NavLink onClick={onClick}
                to={{pathname: route.path, search: preservedQueryParams(this.props.location)}}
                exact={route.exact}>{route.linkLabel}</NavLink></li>
            }
        }
    }
    filterSubmenuRoute(idx,route,menuParent){
        const user = this.props.globalStore.user;
        let onClick = () => this.menuClick();

        if(user && (route.linkLabel !== 'NCDs' || user.ncd_available)){

            if(route.level === 1 && route.parent === menuParent){
                return <li className={route.cssClass} key={idx}><NavLink onClick={onClick}
                to={{pathname: route.path, search: preservedQueryParams(this.props.location)}}
                exact={route.exact}>{route.linkLabel}</NavLink></li>
            }
        }
    }

    createBookmark() {
        const title = document.title;
        const url = window.location.href;
        const ctrlKey = (navigator.userAgent.toLowerCase().indexOf('mac') !== -1 ? 'Cmd' : 'Ctrl');
        const message = "You can add this page to your bookmarks by pressing " + ctrlKey + " + D on your keyboard.";

        try {
            /* IE Favorite */
            window.external.AddFavorite(url, title);
        } catch (e) {
            try {
                /* Firefox - Chrome */
                let API = chrome || browser;
                let createBookmark = API.bookmarks.create({
                    title: title,
                    url: url
                });

                createBookmark.then(console.log('created'));
            } catch (e) {
                try {
                    /* Mozilla Firefox Bookmark - works with opening in a side panel only  */
                    window.sidebar.addPanel(title, url, "");
                } catch (e) {
                    console.log("3: ", e);
                    alert(message);
                }
            }
        }
    }


    render() {
        const portalUser = this.props.globalStore.user.portal_user;
        const userProfile = this.props.globalStore.user;
        const accountNameLabel = this.props.globalStore.user.client_name_label;

	    return (
	        <nav className={"curves-menu view-open-"+this.props.globalStore.menuOpen}>
	            <ul className="menu-left">
	                {leftMenu().map((route, idx) =>
	                    this.filterRoute(idx,route)
                    )}
                    <li className="submenu" style={{order: 3}}><a href="#">Other Products</a>
                        <ul className="dropdown">
                        {leftMenu().map((route, idx) =>
                            this.filterSubmenuRoute(idx,route,'other-products')
                        )}
                        </ul>
                    </li>
	            </ul>
                {accountNameLabel && <ul style={{marginLeft: 'auto', fontWeight: 'bold'}}>{accountNameLabel}</ul>}
                <ul className="menu-right">
                    {rightMenu().map((route, idx) =>
                        this.filterRoute(idx,route)
                    )}
                    {portalUser &&
                    <li><a href={this.props.globalStore.portfolioManagerUrl+(userProfile.admin_user_admin_dto !== null ? '&curve_staff=true&curve_staff_email=andrew@curve.com.au' : '')}>Portfolio</a></li>
                    }

                    <NotificationsMenu/>

                    <li className="submenu"><a href="#">{userProfile.first_name} {userProfile.last_name}</a>
                        <ul className="dropdown">
                            {/*<li>{<div className="edit">Manage account</div>}</li>*/}
                            {rightMenu().map((route, idx) =>
                                this.filterSubmenuRoute(idx,route,'profile-name')
                            )}
                            <li>
                                <a className="add-to-bookmarks" onClick={this.createBookmark} href="javascript:void(0)" rel="sidebar">Add to bookmarks</a>
                            </li>
                            <li className="logout"><a href="/api/salesforce_auth/logout">Log out</a></li>
                        </ul>
                    </li>

                    
                </ul>
	        </nav>
	    );
    }

};

export default withRouter(GlobalStore.connect(Menu));
