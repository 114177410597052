import moment from 'moment';

const termDepositDays = [31, 63, 91, 119, 154, 182, 210, 245, 273, 301, 336, 364];

class MaturityDateUtil {

    static closestDate(startDate, term) {
        const dates = this.getPossibleMaturityDates(startDate, term);
        const endDate = moment(startDate).add(this.getActualTermDepositDays(term), 'days');

        let closestDate = undefined;

        for (let i = dates.length - 1; i >= 0; i--) {
            if (closestDate === undefined
                    || Math.abs(moment(dates[i]).diff(endDate, 'days'))
                        < Math.abs(moment(closestDate).diff(endDate, 'days'))) {
                closestDate = dates[i];
            }
        }

        return closestDate;
    }

    /**
     * Like closestDate but with moment.
     *
     * @param startDate
     * @param term
     */
    static closestMoment(term) {
        return moment(this.closestDate(new Date(), this.hackTermDays(term)));
    }

    static getPossibleMaturityDates(startDate, term) {
        const endDate = moment(startDate).add(this.getActualTermDepositDays(term), 'days');

        const maturityDates = [];
        for (let i = (term < 360 ? -5 : -10); i <= (term < 360 ? 5 : 10); i++) {
            const maturityDate = moment(endDate).add(i, 'days').toDate();
            if (!this.isHolyday(maturityDate)) {
                maturityDates.push(maturityDate);
            }
        }

        return maturityDates;
    }

    /**
     * Same as getPossibleMaturityDates, but returns moment.js objects.
     *
     * @param term
     * @returns {moment.Moment[]}
     */
    static getPossibleMaturityMoments(term) {
        return this.getPossibleMaturityDates(new Date(), this.hackTermDays(term)).map(date => moment(date));
    }

    static getActualTermDepositDays(term) {
        if (term > 360) {
            return term;
        }
        else {
            return termDepositDays.find(it => Math.abs(it - term) < 10);
        }
    }

    /**
     * Tell if a date is holyday.
     *
     * @param date
     * @returns {boolean}
     */
    static isHolyday(date) {
        if (date.getDay() === 0 || date.getDay() === 6) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Get all standard term days for an array of terms.
     *
     * @param terms Example: [30, 60, 180, 270, 360]
     * @returns {moment.Moment[]}
     */
    static getAllStandardTermDays(terms) {

        return terms.reduce((acc, term) => {
            return acc.concat(this.getPossibleMaturityMoments(term.name));
        }, []);

    }

    /**
     * Moment belongs to standard term.
     *
     * @param terms
     * @param date
     * @returns {*}
     */
    static momentBelongsToStandardTerm(terms, mom) {
        return MaturityDateUtil.getAllStandardTermDays(terms).some(it => it.isSame(mom, 'day'));
    }

    /**
     * Get closest moments for an array of terms.
     *
     * @param terms
     */
    static getClosestMomentsForTerms(terms) {
        return terms.map(term => this.closestMoment(term.name));
    }

    /**
     * Converts terms based on 360 days per year into 365 days per year as requested by the client.
     *
     * @param days
     * @returns {number}
     */
    static hackTermDays(days) {
        const transforms = [
            {x: 360 * 1, y: 365 * 1},
            {x: 360 * 2, y: 365 * 2},
            {x: 360 * 3, y: 365 * 3},
            {x: 360 * 4, y: 365 * 4},
            {x: 360 * 5, y: 365 * 5}
        ];

        const transform = transforms.find(it => it.x === parseInt(days));

        return transform ? transform.y : parseInt(days);
    };

}

export default MaturityDateUtil;
