import { createStore } from 'redux';
import {connect} from "react-redux";
import ProductService from "../../service/ProductService";
import ProductVariableService from "../../service/ProductVariableService";
import SockJS from 'sockjs-client'
import {Stomp} from '@stomp/stompjs'
import {getEnv, getEnvParam, getWebSocketURL} from "../../common/getEnvParam";
import TextEncodingPolyfill from "text-encoder-lite";


if (typeof TextEncoder !== 'function') {
    Object.assign(global, { TextEncoder: TextEncodingPolyfill.TextEncoderLite});
    Object.assign(global, { TextDecoder: TextEncodingPolyfill.TextDecoderLite});
}

const GlobalStore_reducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STATE':
            return action.newState;
        default:
            return state;
    }
};

/**
 * Redux store.
 */
let GlobalStore_reduxStore;

class GlobalStore {

    /**
     * Creates a Redux store.
     * If client side rendering, store it in a local variable.
     *
     * @param initialState
     * @returns {any}
     */
    static create(initialState) {
        const reduxStore = createStore(GlobalStore_reducer, initialState);

        /**
         * Executed only in browser.
         */
        if (typeof window !== 'undefined' && initialState.user !== undefined) {
            GlobalStore_reduxStore = reduxStore;

            ProductVariableService.getBankBillSwaps().then(response =>{
                const bankBillSwaps = response.data;
                GlobalStore.setState({bankBillSwaps});
            });

            /**
             * Fetch some global data.
             */
            const getLastUpdateDateTime = () => {
                ProductService.getLastUpdateDateTime().then(lastUpdateDateTime => {
                    GlobalStore.setState({lastUpdateDateTime});
                    /**
                     * Get last update datetime periodically with a random interval between 47 secs and 76 secs.
                     * Use random interval in order to avoid synchrony.
                     */
                    setTimeout(getLastUpdateDateTime, 47000 + Math.floor((Math.random() * 29000)));
                });
            };

            getLastUpdateDateTime();
            
            const socketURL = getWebSocketURL();
                        
            let stompClient = Stomp.over(() => new SockJS(socketURL));
            
            stompClient.reconnect_delay = 1000;
            stompClient.connect({}, function(frame) {

                stompClient.subscribe('/messages/productUpdated', function(messageOutput) {
                    GlobalStore.setState({productUpdated: JSON.parse(messageOutput.body)});
                });
            }
           
            );

            GlobalStore.setState({productUpdated: undefined});
        }

        return reduxStore;
    }

    /**
     * Gets state.
     *
     * @returns {any}
     */
    static getState() {
        return GlobalStore_reduxStore.getState();
    }

    /**
     * Change stored data.
     *
     * @param stateChanges
     */
    static setState(stateChanges) {
        const currentState = GlobalStore_reduxStore.getState();
        const newState = {...currentState, ...stateChanges};
        GlobalStore_reduxStore.dispatch({type: 'SET_STATE', newState});
    }

    /**
     * Connect React component props with global store state.
     *
     * @param componentToConnect
     */
    static connect(componentToConnect) {
        const mapStateToProps = state => ({
            globalStore: state
        });

        return connect(mapStateToProps)(componentToConnect);
    }

}

export default GlobalStore;
