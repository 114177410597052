import axios from "axios";

class UserNotificationService {


    static markAsRead(notificationId) {
        return new Promise((resolve) => {
            axios.put('/api/client/user_notifications/' + notificationId).then(() => {
                resolve();
            });
        });
    }

    static findAll() {
        return new Promise((resolve, reject) => {
            axios.get('/api/client/user_notifications').then(response => {
                resolve(response.data);
            });
        });
    }

}

export default UserNotificationService;
