import React, {Component, Fragment} from 'react';
import Spinner from "../common/Spinner";
import UserService from "../../service/UserService";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import GlobalStore from "../store/GlobalStore";
import getArrayQueryParam from "../../common/getArrayQueryParam";


class ResetPasswordPage extends Component {

    constructor(props) {
        super(props);

        this.submitResetPasswordForm = this.submitResetPasswordForm.bind(this);

        const urlQueryParams = queryString.parse(this.props.location.search);
        const code = getArrayQueryParam(urlQueryParams, 'code')[0];

        this.state = {
            resetPasswordCode: code,
            password: '',
            passwordRepeat: '',
            validationErrors: {},
            sending: false,
        };


    }

    validate() {
        let validationErrors = {};

        const passwordStrengthRegex = /(?=.*[a-z])+(?=.*[A-Z])+(?=.*[0-9])+(?=.{8,})/;
        if (!passwordStrengthRegex.test(String(this.state.password))) {
            validationErrors.password = 'For security reason passwords must be 8 characters in length, and contain at least one number and one upper case letter';
        }

        if (this.state.password.trim() === '') {
            validationErrors.password = 'Please enter your password';
        }

        if (this.state.passwordRepeat.trim() === '') {
            validationErrors.passwordRepeat = 'Please repeat your password';
        }

        if (this.state.password.trim() !== this.state.passwordRepeat.trim()) {
            validationErrors.passwordRepeat = 'Passwords do not match';
        }

        this.setState({
            validationErrors
        });

        return validationErrors;
    }


    submitResetPasswordForm() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        this.setState({sending: true});

        const resetPasswordData = {
            code: this.state.resetPasswordCode,
            password: this.state.password
        };

        UserService.resetPassword(resetPasswordData)
            .then((response) => {
                const successMessage = "Your password has been updated.";
                this.setState({sending: false, successMessage:successMessage, password:'',passwordRepeat:''});
            })
            .catch((error) => {
                const errorMessage = error.response.data ? error.response.data.message : "An error occurs, please try again later";
                this.setState({errorMessage: errorMessage, sending: false});
            });
    }


    isSubmitFail() {
        return this.state.errorMessage && this.state.errorMessage !== '';
    }

    backToLogin() {
        window.location.href = '/login';
    }

    rendeSuccessMessage(){
        return(
            <div className="reset-password-form">
                <div className="success-message">{this.state.successMessage}</div>
                <div className="submit-btn common-login-row">
                    <input type="submit"
                           onClick={this.backToLogin}
                           value="Back to Login"/>
                </div>
            </div>
        );
    }


    renderResetPasswordForm() {
        return (
            <div className="reset-password-form">
                {this.isSubmitFail() &&
                <div className="error-message">{this.state.errorMessage}</div>
                }
                <div className="input-form">
                    <label htmlFor="password" className="label passwordLabel">Password</label>
                    <input type="password" placeholder="Enter your password" value={this.state.password}
                           onChange={e => this.setState({password: e.target.value})}/>
                    <div className="validation-errors">{this.state.validationErrors.password}</div>
                </div>
                <div className="input-form">
                    <label htmlFor="passwordRepeat" className="label passwordlabel">Repeat your Password</label>
                    <input type="password" placeholder="Repeat your password" value={this.state.passwordRepeat}
                           onChange={e => this.setState({passwordRepeat: e.target.value})}/>
                    <div className="validation-errors">{this.state.validationErrors.passwordRepeat}</div>
                </div>
                {this.state.sending === true && <div className="form-sending"><Spinner/></div>}
                {this.state.sending === false &&
                <Fragment>
                    <div className="submit-btn common-login-row">
                        <input type="submit"
                               onClick={this.submitResetPasswordForm}
                               value="Submit"/>
                    </div>
                </Fragment>
                }
            </div>
        );
    }


    render() {
        return (
            <div className="curves-reset-password-page">
                <div className="curves-logo">
                    <img src="/img/Yieldhub_Reverse_Logo_RGB.svg"/>
                </div>
                <div className="login-text">
                    Yieldhub is a central platform where you can access rates and products from over 80 of our Banking Partners. You will not only be able to access market rates, but any specials available on the day, filtering, sorting and interacting with all aspects of the platform!
                </div>
                <div className="form-container">
                    {this.state.successMessage &&
                    this.rendeSuccessMessage()
                    }
                {!this.state.successMessage && this.renderResetPasswordForm()}
                </div>
                <div className="login-browser-notice">
                    Optimised for Chrome, Safari and Firefox browsers on MAC and PC desktops and laptops.
                </div>
            </div>


        )
    }

}

export default withRouter(ResetPasswordPage);
