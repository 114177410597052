import React, {Component, Fragment} from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";
import {format_num, formatAmount, strip_num} from "../../common/formatUtil";
import Spinner from "../common/Spinner";
import MaturityService from "../../service/MaturityService";

class MaturityLookingForSomethingElseDialog extends Component {

    constructor(props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.accept = this.accept.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.renderSuccessSubmission = this.renderSuccessSubmission.bind(this);
        this.state = {
            sending: false,
            amount: props.amount !== undefined ? Math.trunc(props.amount).toString() : '',
            submitOk: null,
            term: props.term.toString(),
            otherRequirements: '',
            validationErrors: {},
            contract: props.contract
        };
    }

    validate() {
        let validationErrors = {};

        if (this.state.amount.trim() === '' && this.state.term.trim() === '' && this.state.otherRequirements.trim() ==='') {
            validationErrors.error = 'You must complete at least one field.';
        }


        this.setState({
            validationErrors
        });

        return validationErrors;
    }

    isSubmitFail() {
        return this.state.submitOk !== null && this.state.submitOk === false;
    }

    accept() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const generalInquiry = {
            contract_id: this.state.contract.id,
            amount: this.state.amount,
            term: this.state.term,
            message: this.state.otherRequirements
        };

        this.setState({sending: true});

        MaturityService.sendInquiry(generalInquiry)
        .then((response) => {
            this.setState({submitOk: true, sending: false});
        })
        .catch((error) => {
            this.setState({submitOk: false, sending: false});
        });
    }


    renderForm() {
        return (
            <Fragment>
                <div className="maturity-inquiry">
                    <div className="text-section">
                        We understand these opportunities might not be what you are looking for. If there is something more specific, let us know and we'll find some other options!
                    </div>
                    <div className="form-section">
                        {this.isSubmitFail() &&
                        <div className="error-message">An error occurs, please try again later</div>
                        }
                        <div className="form-group">
                            <label>
                                <Information tooltip="If your placing more than one deposit let us know" flow="right" />
                                Amount (s):
                            </label>
                            <input type="text" value={format_num(this.state.amount)} onChange={e => this.setState({amount: strip_num(e.target.value)})}/>
                            <div className="validation-errors">{this.state.validationErrors.amount}</div>
                        </div>
                        <div className="form-group">
                            <label>
                                <Information tooltip="Let us know if you’re looking for more than one term" flow="right"/>
                                Term (s):
                            </label>
                            <input type="text" value={this.state.term} onChange={e => this.setState({term: e.target.value})}/>
                            <div className="validation-errors">{this.state.validationErrors.term}</div>
                        </div>
                        <div className="form-group">
                            <label>
                                <Information tooltip="Do you need a specific credit rating today? How many deposits are you looking to do? Anything else?" flow="right"/>
                                Other requirements:
                            </label>
                            <textarea value={this.state.otherRequirements} onChange={e => this.setState({otherRequirements: e.target.value})}/>
                            <div className="validation-errors">{this.state.validationErrors.otherRequirements}</div>
                        </div>

                        {this.state.sending && <div className="general-inquiry-sending"><Spinner/></div>}
                        <div className="validation-errors">{this.state.validationErrors.error}</div>
                    </div>
                </div>
                <div className="options">
                    <a className="confirm-btn" onClick={DialogUtil.close}>Cancel</a>
                    <a className="confirm-btn" onClick={this.accept}>Submit</a>
                </div>
            </Fragment>
        )
    }

    renderSuccessSubmission() {
        return (
            <div>
                <div className="success-message-container">
                    <h3>Sent message</h3>
                    <p>Thank you - A Curve team member will be in touch with you shortly.</p>
                    <div className="button-container">
                        <button className="accept-btn" onClick={DialogUtil.resolve}>DONE</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <SimpleDialog className="modal-lg" title="Looking for something else" onClickOutside={DialogUtil.close}>
                {this.state.submitOk ? this.renderSuccessSubmission() : this.renderForm()}
            </SimpleDialog>
        );
    }

}

const Information = ({tooltip, flow}) => (
    <div className="information tooltip" tooltip={tooltip} flow={flow}><i className="icon icon-info"></i></div>
);

export default MaturityLookingForSomethingElseDialog;
