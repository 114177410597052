import axios from "axios";

class SpecialService {

    static findAllByProductType(product_type) {
        return new Promise((resolve, reject) => {
            axios.get('/api/client/products',{params: {product_type, product_category: 'SPECIAL'}}).then(response => {
                resolve(response.data);
            });
        });
    }

}

export default SpecialService;
