export default () => {
    /**
     * Executed only in browser.
     */
    if (typeof window !== 'undefined') {
        return window.environment || 'DEV';
    }
    else {
        return process.env.CSEC_ENVIRONMENT || 'DEV';
    }
};
