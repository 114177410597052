import React, {Component, Fragment} from 'react';
import GlobalStore from "../store/GlobalStore";
import {withRouter} from "react-router-dom";
import UserService from "../../service/UserService";
import UserFilterService from '../../service/UserFilterService';
import {formatAmount} from "../../common/formatUtil";
import DateUtil from "../../common/DateUtil";
import BankImage from "../card/BankImage";
import {getBankImageUrl} from "../../common/bankImageUtil";
import ProductService from "../../service/ProductService";
import {PRODUCT_TYPES, productSecondaryLabels} from "../../common/types/productTypes";
import DialogUtil from "../../common/DialogUtil";
import ConfirmDialog from "../dialog/ConfirmDialog";

class UserProfile extends Component {

    constructor(props) {

        super(props);

        this.state = {
            filters: [],
            eois: [],
        };

    }

    deleteFilter(filterName) {
        UserFilterService.delete(filterName).then(() => {
            UserFilterService.findAll().then(data=>{
                this.setState({filters:data});
            })
        });

    }

    renderFilters() {
        return (
            this.state.filters.map((filter) => {
                return <div className="financial-scheme" key={filter.name}>{filter.name}<a
                    onClick={() => {
                        DialogUtil.open(<ConfirmDialog message={"Delete user filter named "+ filter.name+"?"}/>).then(()=>{
                        UserFilterService.delete(filter.name).then(() => {
                                DialogUtil.close();
                                UserFilterService.findAll().then(data=>{
                                    this.setState({filters:data});
                                })
                            });

                        });
                    }}>
                    <svg>
                        <use xlinkHref="#curves-svg-close"></use>
                    </svg>
                </a></div>
            })
        )
    }

    renderEOis() {
        return (
            this.state.eois.map(eoi => {
                    const eoiDays = parseInt(eoi.term) - parseInt(eoi.maturity_day_diff); //moment(parseInt(eoi.term)).diff(parseInt(eoi.maturity_day_diff), 'days');
                    return (
                        <div key={"eoi-"+JSON.stringify(eoi)} className="eoi-row">
                            <div className="bank-image eoi-column">
                                <div className="img">
                                    <BankImage bankImageUrl={getBankImageUrl(eoi.bank.name, eoi.bank.logo)}
                                               bankName={eoi.bank.name}/>
                                </div>
                            </div>
                            <div className="bank-name eoi-column">
                                <div>{eoi.bank.name}</div>
                                <div className="product-type">{productSecondaryLabels(eoi.product_type)}</div>
                            </div>

                            <div className="eoi-date eoi-column">
                                <div>Date : {DateUtil.formatISO_8601_2(eoi.date)}</div>
                            </div>

                            <div className="amount-days eoi-column">
                                <div className="amount">${formatAmount(eoi.amount_committed)}</div>
                                <div className="days">{eoiDays} days</div>
                            </div>
                        </div>
                    )
                }
            )
        )
    }

    componentDidMount() {
        Promise.all([
            UserFilterService.findAll(),
            UserService.getEois()
        ]).then((data) => {
            this.setState({
                filters: data[0],
                eois: data[1]
            });
        });
    }

    render() {
        return (
            <div className="curves-user-profile-page">

                <div className="title">
                    <span className="main-title">My Profile</span>
                    <span className="client-name">{this.props.globalStore.user.client_name}</span>
                </div>


                <div className="main-content">
                    <div className="user-information">
                        <div className="title-section">User information</div>
                        <div className="user-email"><span>Name  :</span> {this.props.globalStore.user.first_name} {this.props.globalStore.user.last_name}</div>
                        <div className="user-email"><span>Email :</span> {this.props.globalStore.user.email}</div>
                    </div>

                    <div className="saved-filters">
                        <div className="title-section">Saved Filters</div>
                        <div className="curves-filter-tags">
                            {this.renderFilters()}
                        </div>
                    </div>

                    <div className="eoi">
                    <div className="title-section">EOI Submitted</div>
                        <div className="container-eois">
                            {this.renderEOis()}
                        </div>
                    </div>

                </div>
            </div>


        )
    }

}

//export default UserProfile;
export default withRouter(GlobalStore.connect(UserProfile), UserProfile);
