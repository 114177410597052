import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import EOIForm from "../card/EOIForm";
import queryString from "query-string";
import getArrayQueryParam from "../../common/getArrayQueryParam";
import {
    getProductsAttributesNames,
    formatProductsTableView,
    getAttributesOnTerm,
    termWasRecentlyUpdated, isRateLiveToday, isRateLiveInTheLast60Seconds
} from "../../common/productUtil";
import {calculateTimeDiff} from "../../common/formatUtil";
import termLabel from "../../common/termLabel";
import {hasTermDepositBehavior, PRODUCT_TYPES} from "../../common/types/productTypes";
import {PRODUCT_VISIBILITY} from "../../common/types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "../../common/types/productAttributeTypes";
import UserStatService from "../../service/UserStatService";
import {CSSTransition} from "react-transition-group";
import MaturityDateUtil from "../../common/MaturityDateUtil";
import {groupOnRequestRates, groupTerms, isTermHighlighted, isTermRateOnRequest} from "../../common/rateUtil";

class ProductTable extends Component {


    constructor(props) {
        super(props);
        this.selectTerm = this.selectTerm.bind(this);
        this.formatUpdateRow = this.formatUpdateRow.bind(this);
        this.getTermsFiltered = this.getTermsFiltered.bind(this);
        this.getTermsUnfiltered = this.getTermsUnfiltered.bind(this);
        this.getRatesToShow = this.getRatesToShow.bind(this);
        this.findTermGroupByTerm = this.findTermGroupByTerm.bind(this);
        this.selectMaturityDate = this.selectMaturityDate.bind(this);

        this.state = {
            selectedTerm: null,
            selectedProductIdx: null
        };
    }


    selectTerm(productIdx, term) {
        if (this.state.selectedTerm && term.name === this.state.selectedTerm.name) {
            this.setState({selectedTerm: null, selectedProductIdx: null, maturityDate: null});
        } else {
            const product = this.props.products[productIdx];
            const productId = product.id;

            if (term.attributes && term.attributes.length === 1) {
                UserStatService.createEOIFormOpenUserStat(productId, term.attributes[0].name);
            }

            term.attributes = getAttributesOnTerm(term, product.attributes);

            this.setState ({
                selectedProductIdx: productIdx,
                selectedTerm: term,
                maturityDate: term.attributes.length === 1 && MaturityDateUtil.closestDate(new Date(),
                    MaturityDateUtil.hackTermDays(term.attributes[0].name))
            });
        }
    }

    selectProduct(productIdx) {
        if (this.state.selectedProductIdx === productIdx) {
            this.setState({selectedProductIdx: null});
        } else {
            UserStatService.createEOIFormOpenUserStat(this.props.products[productIdx].id, null);

            this.setState({
                selectedProductIdx: productIdx,
            });

        }

    }

    isEOIOpened(productIdx) {
        return productIdx === this.state.selectedProductIdx;
    }

    formatUpdateRow(product) {
        return (
            <div className="update-cell">
                <div className="time">{calculateTimeDiff(product.updated_date)}</div>
                {product.information &&
                    <div className="info-column" tooltip={product.information} flow="left">
                        <div className="info-icon"></div>
                    </div>
                }
            </div>)
    }

    /**
     * Apply filters over attributes to get terms.
     *
     * @returns {*}
     */
    getTermsFiltered() {
        const urlQueryParams = queryString.parse(this.props.location.search);
        const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');
        const product = this.props.products[this.state.selectedProductIdx];

        return product.attributes.filter(it =>
            (filterTerms.length === 0 || filterTerms.indexOf(it.name) !== -1)
            && (it.value_type === PRODUCT_ATTRIBUTE.TERM));
    }

    /**
     * Get terms unfiltered.
     *
     * @returns {*}
     */
    getTermsUnfiltered() {
        const product = this.props.products[this.state.selectedProductIdx];

        return product.attributes.filter(it =>
            it.value_type === PRODUCT_ATTRIBUTE.TERM
            && (it.value || it.visibility === PRODUCT_VISIBILITY.ON_REQUEST));
    }

    /**
     * Select maturity date in date picker.
     *
     * @param maturityDate
     */
    selectMaturityDate(maturityDate) {

        const selectedTerm = this.getTermsFiltered().find(term => MaturityDateUtil.getPossibleMaturityMoments(term.name)
            .some(mom => mom.isSame(maturityDate, 'day')));

        /**
         * Is not a standard term or the term is not being showed because of filters configuration.
         */
        if (selectedTerm === undefined || !this.findTermGroupByTerm(selectedTerm).value) {
            this.setState({
                selectedTerm: undefined,
                maturityDate: maturityDate.toDate()
            });
        }
        else {
            const selectedGroup = this.findTermGroupByTerm(selectedTerm);

            selectedGroup.attributes = selectedGroup.attributes.filter(attribute =>
                attribute.visibility === PRODUCT_VISIBILITY.ON_REQUEST || attribute.value !== null);

            const product = this.props.products[this.state.selectedProductIdx];
            UserStatService.createEOIFormOpenUserStat(product.id, selectedTerm.name);

            this.setState({
                selectedTerm: selectedGroup,
                maturityDate: maturityDate.toDate()
            });
        }

    }

    /**
     * Get rates to show.
     *
     */
    getRatesToShow() {
        return groupOnRequestRates(groupTerms(this.getTermsFiltered()));
    }

    /**
     * Find term group by term.
     *
     * @param term
     * @returns {number | never | bigint | T | T | *}
     */
    findTermGroupByTerm(term) {
        const ratesToShow = this.getRatesToShow();

        return ratesToShow.find(it => it.name === term.name || it.attributes.some(attr => attr.name === term.name));
    }

    render() {
        const urlQueryParams = queryString.parse(this.props.location.search);
        const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');
        this.props.products.forEach((product) => {
            product.attributes = product.attributes.filter(it => (filterTerms.length === 0 || filterTerms.indexOf(it.name) !== -1));
        });

        const attributeNames = getProductsAttributesNames(this.props.products);
        const rows = formatProductsTableView(this.props.products, attributeNames, this.formatUpdateRow);


        const isNCD = this.props.products && this.props.products[0] && this.props.products[0].product_type === PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT;
        const termDepositBehaviour = this.props.products && this.props.products[0] && hasTermDepositBehavior(this.props.products[0]);


        const titles = ["INSTITUTION", "RATING"];
        let staticColumns = isNCD?3:2;
        if(isNCD){
            //Add new element for availability
            titles.push("AVAILABILITY");
        }
        attributeNames.forEach(title => {titles.push ((title.value_type === PRODUCT_ATTRIBUTE.TERM ? termLabel(title.name) : title.name))});
        if(termDepositBehaviour){
            titles.push("LAST UPDATE");
        }


        let cellClassNames = [];
        let cellOnClick = [];
        let rowClassName = [];
        let rowOnClick = [];

        cellClassNames = rows.map((row, idxRow) => {
            // These nulls are Institution, Ranking, Availability and Update
            const className = isNCD?[null,null,null]:[null,null];
            attributeNames.forEach((attr, idx) => {
                let cellClassName = "";
                if (row[idx + staticColumns] !== 'N/A') {
                    cellClassName = "rate";
                    if (isTermHighlighted(this.props.products, this.props.products[idxRow],
                                          getAttributesOnTerm(attr, this.props.products[idxRow].attributes),
                                          this.props.location)) {
                        cellClassName += " highlight";
                    }
                    if(isTermRateOnRequest(getAttributesOnTerm(attr, this.props.products[idxRow].attributes))){
                        cellClassName += " on-request";
                    }
                    const term = this.props.products[idxRow].attributes.find(term => term.name === attr.name);
                    if (term !== undefined && termWasRecentlyUpdated(term)) {
                        cellClassName += " recently-updated";
                    }
                } else {
                    cellClassName = "disabled";
                }
                className.push(cellClassName), null
            });
            return className;
        });

        cellOnClick = rows.map((row, idxRow) => {
            // These nulls are Institution, Ranking, Availability and Update
            const cellEvents = isNCD?[null,null,null]:[null,null];
            attributeNames.map(
                (attr, idx) => cellEvents.push(row[idx+staticColumns] !== 'N/A' ? ()=>this.selectTerm(idxRow, attr) : null), null);
            return cellEvents;
        });

        return (
            <div className={"curves-table "+(this.props.termDeposits?'term-deposit':'')}>
                <table className="ct-table">
                    <thead>
                        <tr className="ct-tr">
                            { titles.map((title, idx) =>
                                <th key={idx} className="ct-th">{title}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        { rows.map((row, rowIdx) =>
                            [

                                <tr onClick={rowOnClick && rowOnClick[rowIdx]} id={"product-" + this.props.products[rowIdx].id} key={rowIdx}
                                        className={'ct-tr ' + rowClassName+ (rowIdx === this.state.selectedProductIdx ? "selected-row" : "") +
                                                        (isRateLiveInTheLast60Seconds(this.props.products[rowIdx]) ? ' blink ' : '') +
                                                        (isRateLiveToday(this.props.products[rowIdx]) ? ' live-rate ' : '')}>
                                    { row.map((value, attrIdx) =>
                                        <td id={"product-" + this.props.products[rowIdx].id+"-"+titles[attrIdx]}
                                            key={attrIdx}
                                            className={'ct-td ' + (cellClassNames && cellClassNames[rowIdx] && cellClassNames[rowIdx][attrIdx])}
                                            onClick={cellOnClick && cellOnClick[rowIdx] && cellOnClick[rowIdx][attrIdx] ? cellOnClick[rowIdx][attrIdx] : null}>
                                            {value}
                                        </td>
                                    )}
                                </tr>,
                                this.isEOIOpened(rowIdx) && <tr key={"eoi_row"+rowIdx} className="eoi-row ct-tr">
                                    <td key={"eoi"+rowIdx} colSpan={titles.length} className="ct-td">
                                        <EOIForm
                                            product={this.props.products[this.state.selectedProductIdx]}
                                            term={this.state.selectedTerm}
                                            productCardTerms={this.getTermsUnfiltered()}
                                            maturityDate={this.state.maturityDate}
                                            selectMaturityDate={this.selectMaturityDate}
                                        />
                                    </td>
                                </tr>
                            ]
                        )}
                        { rows.length === 0 &&
                            <tr className="no-items ct-tr"><td colSpan={titles.length} className="ct-td">No records found</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(ProductTable);
