import queryString from "query-string";
import getArrayQueryParam from "./getArrayQueryParam";
import {isInBetweenRange, TERM_GROUP, TERM_GROUP_WITH_RANGES_FOR_LIST_VIEW, TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW} from "./rateUtil";


export const groupBankBillSwaps = (productVariables, location, cardView) => {
    let termArrayToUse = null;
    if(cardView){
        termArrayToUse = TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW;
    } else {
        termArrayToUse = TERM_GROUP_WITH_RANGES_FOR_LIST_VIEW;
    }
    let grouped = [];
    if(productVariables!== undefined){
        let benchmarkTerm;
        const filteredVariables = filterProductVariables(productVariables, location);
        filteredVariables.forEach(function (variable) {
            benchmarkTerm = variable.benchmarkTerm;
            termArrayToUse.forEach(function (termGroup, index) {
                    if (isInBetweenRange(benchmarkTerm, termGroup.range)) {
                        addVariable(grouped, variable, termGroup.name);
                    }
                });
        });
    }

    return grouped;
};


export const getBBSWCash = (bankBillSwaps) => {
    //BenchmarkTerm == 0 is Cash
    const bbswCash = bankBillSwaps && bankBillSwaps.filter(it => it.benchmarkTerm === 0);
    return (bbswCash && bbswCash[0])?{name: "Cash", value: bbswCash[0].value, attributes:[bbswCash[0]]}:null;
};

export const formatBankBillSwaps = (productVariables, location)=> {
    let formatted = [];
    if(productVariables!==undefined){
        const filteredVariables = filterProductVariables(productVariables, location);

        filteredVariables.forEach(function (variable) {
            addVariable(formatted, variable, variable.benchmarkTerm.toString())
        });
    }
    return formatted;

};

const addVariable = (grouped, variable, key) => {
    let groupElement = grouped.find( item => item['name'] === key);
    if (groupElement === undefined) {
        grouped.push({name: key, value: variable.value, attributes:[variable]});
    } else {
        groupElement.attributes.push(variable);
        const max = Math.max.apply(Math, groupElement.attributes.map(function(item) { return item.value; }))
        groupElement.value = max;
    }
};

const filterProductVariables = (productVariables, location) => {
    const urlQueryParams = queryString.parse(location.search);
    const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');
    //BenchmarkTerm == 0 is Cash, it is removed
    return productVariables.filter(it => it.benchmarkTerm !== 0).filter(
        it => (filterTerms.length === 0 || filterTerms.indexOf(it.benchmarkTerm.toString()) !== -1)
    );
};
