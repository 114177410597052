import queryString from "query-string";
import getArrayQueryParam from "./getArrayQueryParam";
import UserStatService from "../service/UserStatService";
import Filters from "../client/layout/Filters";

export const FILTER_TERM =              { label: 'filterTerm',            value: "TERM" };
export const FILTER_RATING_SHORT_TERM = { label: 'filterRatingShortTerm', value: "RATING_SHORT_TERM" };
export const FILTER_RATING_LONG_TERM =  { label: 'filterRatingLongTerm',  value: "RATING_LONG_TERM" };
export const FILTER_FOSSIL_FUEL_FREE =  { label: 'filterFossilFuelFree',  value: "FOSSIL_FUEL_FREE" };
export const FILTER_DEPOSIT_MIN =       { label: 'filterDepositMin',      value: "DEPOSIT_MIN" };
export const FILTER_DEPOSIT_MAX =       { label: 'filterDepositMax',      value: "DEPOSIT_MAX" };
export const FILTER_INSTITUTION_TYPE =  { label: 'filterInstitutionType', value: "INSTITUTION_TYPE" };
export const FILTER_ALL =               { label: 'all',                   value: "ALL" };
export const FILTER_DEPOSIT_RANGE =     { min:250000                    , max:10000000};
export const FILTER_BANK_NAME =         { label: 'filterBankName',        value: ''};
class FilterUtil {

    /**
     * Get filters from url query parameters.
     *
     * @returns {{filterDepositMax: number, filterDepositMin: number, filterTerm: (*|boolean|*[]|Array), filterRating: (*|boolean|*[]|Array)}}
     */
    static getFiltersFromQueryParams(location) {

        const urlQueryParams = queryString.parse(location.search);

        const filterTerm = getArrayQueryParam(urlQueryParams, FILTER_TERM.label);

        const filterRatingShortTerm = getArrayQueryParam(urlQueryParams, FILTER_RATING_SHORT_TERM.label);
        const filterRatingLongTerm = getArrayQueryParam(urlQueryParams, FILTER_RATING_LONG_TERM.label);

        const filterDepositMin = urlQueryParams.filterDepositMin !== undefined ? parseInt(urlQueryParams.filterDepositMin) : FILTER_DEPOSIT_RANGE.min;
        const filterDepositMax = urlQueryParams.filterDepositMax !== undefined ? parseInt(urlQueryParams.filterDepositMax) : FILTER_DEPOSIT_RANGE.max;

        const filterFossilFuelFree = getArrayQueryParam(urlQueryParams, FILTER_FOSSIL_FUEL_FREE.label);

        const filterInstitutionType = getArrayQueryParam(urlQueryParams, FILTER_INSTITUTION_TYPE.label);

        const filterBankName = urlQueryParams.filterBankName !== undefined ? urlQueryParams.filterBankName : null;

        return { filterTerm, filterRatingShortTerm, filterRatingLongTerm, filterDepositMin, filterDepositMax, filterFossilFuelFree, filterInstitutionType, filterBankName };

    }

    static removeFilter(type, value, location, history) {
        const urlQueryParams = queryString.parse(location.search);

        UserStatService.createProductFilterRemoveUserStat(type.label, // filter
            type.label === FILTER_DEPOSIT_MIN.label || type.label === FILTER_DEPOSIT_MAX.label ?
                Filters.formatDepositLimit(parseInt(value)) : value);

        if (type.value === FILTER_DEPOSIT_MIN.value) {
            delete urlQueryParams.filterDepositMin;
        }
        else if (type.value === FILTER_DEPOSIT_MAX.value) {
            delete urlQueryParams.filterDepositMax;
        }
        else if (type.value === FILTER_ALL.value) {
            urlQueryParams.filterTerm = [];
            urlQueryParams.filterRatingShortTerm = [];
            urlQueryParams.filterRatingLongTerm = [];
            urlQueryParams.filterFossilFuelFree = [];
            urlQueryParams.filterInstitutionType = [];
            delete urlQueryParams.filterDepositMin;
            delete urlQueryParams.filterDepositMax;
        }
        else {
            const filterValues = getArrayQueryParam(urlQueryParams, type.label);
            urlQueryParams[type.label] = filterValues.filter(filterValue => filterValue !== value);
        }

        history.push('?' + queryString.stringify( urlQueryParams ));
    };

    static haveAnyFilterApplied(location) {
        const urlQueryParams = queryString.parse(location.search);

        const filterTerm = getArrayQueryParam(urlQueryParams, FILTER_TERM.label);
        const filterRatingShortTerm = getArrayQueryParam(urlQueryParams, FILTER_RATING_SHORT_TERM.label);
        const filterRatingLongTerm = getArrayQueryParam(urlQueryParams, FILTER_RATING_LONG_TERM.label);
        const filterFossilFuelFree = getArrayQueryParam(urlQueryParams, FILTER_FOSSIL_FUEL_FREE.label);
        const filterInstitutionType = getArrayQueryParam(urlQueryParams, FILTER_INSTITUTION_TYPE.label);
        const filterDepositMin = urlQueryParams.filterDepositMin;
        const filterDepositMax = urlQueryParams.filterDepositMax;
        return (filterTerm.length > 0 || filterRatingShortTerm.length > 0 || filterRatingLongTerm.length > 0 ||
                filterFossilFuelFree.length > 0 || filterInstitutionType.length > 0 || filterDepositMin || filterDepositMax);
    }
}

export default FilterUtil;
