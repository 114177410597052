import {Component, Fragment} from "react";
import {PRODUCT_TYPES} from "../../common/types/productTypes";
import MaturityService from "../../service/MaturityService";
import MaturityCard from "../card/MaturityCard";
import React from "react";

class ClientMaturities extends Component  {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = {};
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        if ([PRODUCT_TYPES.TERM_DEPOSIT,
            PRODUCT_TYPES.NEGOTIABLE_CERTIFICATE_OF_DEPOSIT].indexOf(this.props.productType) !== -1) {
            MaturityService.getMaturities(this.props.productType).then(data => {
                this.setState({
                    clientName : data.client_name,
                    clientMaturities: data.maturities,
                    children: data.children
                });
            });
        }
    }
    render() {
        return(
            <div className="maturities-container">
                {this.state.clientMaturities && this.state.clientMaturities.length > 0 &&
                <h1>Maturities {this.state.children && this.state.children.length > 0 ? ' for ' + this.state.clientName : ''}</h1>
                }
                {this.state.clientMaturities && this.state.clientMaturities.map((maturity, idx) =>
                    <div className="specials" key={idx}>
                        <div>
                            <MaturityCard key={"maturity"+JSON.stringify(maturity)} maturity={maturity}
                                          expressInterest={this.props.expressInterest}
                                          openProductId={this.props.openProductId}/>
                        </div>
                    </div>
                )}
                {this.state.children && this.state.children.length > 0 &&
                    <Fragment>
                        {this.state.children.map((child, idx) =>
                            <Fragment key={"maturities_"+idx}>
                                <h1>Maturities for {child.client_name_local}</h1>
                                <div className="specials" key={idx}>
                                    <div>
                                        {child.maturities.map((maturity, maturityIdx) =>
                                            <MaturityCard key={"maturity"+JSON.stringify(maturity)} maturity={maturity}
                                                          expressInterest={this.props.expressInterest}
                                                          openProductId={this.props.openProductId}/>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                }
            </div>
        );
    }
}

export default ClientMaturities;
