import React, {Component, Fragment} from 'react';
import GlobalStore from "../store/GlobalStore";
import {withRouter} from "react-router-dom";
import QueryParams from "../../common/QueryParams";


import {
    getLongRatingIndex,
    getShortRatingIndex,
    RATING_LONG_TERM,
    RATING_SHORT_TERM
} from "../../common/bankRatingUtil";
import UserStatService from "../../service/UserStatService";
import CryptoUtil from "../../common/CryptoUtil";
import {getAvailabilityIndex} from "../../common/types/ncdAvailabilityTypes";
import AskForPushNotifications from "../notifications/AskForPushNotifications";
import FlexProductTable from "../table/FlexProductTable";
import BankNameFilter from "../layout/BankNameFilter";
import ProductsExportCSV from "../common/ProductsExportCSV";
import ClientMaturities from "../layout/ClientMaturities";
import MaturityService from "../../service/MaturityService";
import MaturityCard from "../card/MaturityCard";


class MaturityPreviewPage extends Component {

    constructor(props) {
        super(props);

        this.fetchData = this.fetchData.bind(this);

        this.state = {
        };
    }

    componentDidMount() {
        this.setState({status: 'LOADING'});
        this.fetchData();
    }

    fetchData() {
        const contractId = QueryParams.get(this.props,'contractId');
        MaturityService.getMaturityByContractIdForPreview(contractId).then(data => {
            this.setState({
                maturity: data
            });
        });
    }

    expressOfInterest(){
        console.log("Express of interest");
    }



    render() {

        return (
            <div className="curves-maturity-preview">
                <div className="body">
                    <div className="maturities-container">
                        {this.state.maturity &&
                            <div className="specials">
                                <div>
                                    <MaturityCard key={"maturity"+JSON.stringify(this.state.maturity)} maturity={this.state.maturity}
                                                  expressInterest={this.props.expressInterest}
                                                  openProductId={this.state.maturity.contract.id}
                                                  adminPreview = {true}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(GlobalStore.connect(MaturityPreviewPage), MaturityPreviewPage);
