import React from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";

const ConfirmDialog = ({message}) => (

    <SimpleDialog className="curves-confirm-dialog" title="Confirm" onClickOutside={DialogUtil.close}>
        <div className="message">
            <p>{message}</p>
        </div>
        <div className="options">
            <a onClick={DialogUtil.close}>Cancel</a>
            <a className="confirm-btn" onClick={DialogUtil.resolve}>Accept</a>
        </div>
    </SimpleDialog>

);

export default ConfirmDialog;
