import moment from 'moment'
import {PRODUCT_VISIBILITY} from "./types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "./types/productAttributeTypes";

export const formatAmount = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

export const getAttributeRate = (attribute) => {
    if (attribute.visibility === PRODUCT_VISIBILITY.ON_REQUEST) {
        return "Rate on Request";
    }
    if ([PRODUCT_ATTRIBUTE.NUMBER, PRODUCT_ATTRIBUTE.TERM].includes(attribute.value_type) || !isNaN(attribute.value)) {
        return formatRate(attribute.value)
    }
    return attribute.value;
};

export const formatTermRate = (term) => {
    if (term.visibility === PRODUCT_VISIBILITY.ON_REQUEST) {
        return "";
    }
    return formatRate(term.value);

};

export const formatRate = (rate) => {
    if (rate === "" || isNaN(rate) || rate === null) {
        return rate;
    }
    return toFixed(2, parseFloat(rate)) + '%';
};

export const formatRate4 = (rate) => {
    if (rate === "" || isNaN(rate) || rate === null) {
        return rate;
    }
    return toFixed(4, parseFloat(rate)) + '%';
};

/**
 *
 * @param number string that contains a floating-point number.
 * @returns number A floating-point number with 2 decimals
 */
export const formatRateNumber = (number) => {
    return parseFloat(toFixed(2, parseFloat(number)));
};

/**
 *
 * @param number string that contains a floating-point number.
 * @returns number A floating-point number with 4 decimals
 */
export const formatRateNumber4 = (number) => {
    return parseFloat(toFixed(4, parseFloat(number)));
};

export const calculateTimeDiff = (date) => {
    if (!date) {
        return "N/A";
    }
    const diff = Math.abs(Math.floor(moment.duration(moment(new Date()).diff(moment.utc(date))).asMinutes()));
    if (diff < 60*24) {
        return Math.floor(diff/60) + ":" + (diff%60 < 10 ? "0"+diff%60 : diff%60) + "h"
    } else {
        return Math.floor(diff/(60*24))+"d";
    }
};

/**
 * Format amount: 1000 => 1K, 1000000 => 1M
 *
 * @param value
 * @returns {string}
 */
export const abbreviateNumber = value => {
    if (value >= 1000000) {
        return (value / 1000000) + 'M';
    }
    else if (value >= 1000) {
        return (value / 1000) + 'K';
    }
    else {
        return value;
    }
};

export const strip_num = value => {
    const rawNumber = value.replace(/\./g, '')
        .replace(/,/g, '')
        .replace(/^0+/g, '');

    if (!rawNumber.match(/^\d{0,9}$/)) {
        throw 'ERROR';
    }

    return rawNumber;
};

export const format_num = value => {
    const arr = value.toString().split('');

    return arr.reverse()
        .reduce(((acc, el, idx) => acc + (idx > 0 && idx % 3 === 0 ? ',' : '') + el), '')
        .split('')
        .reverse()
        .join('');
};

/**
 * To fixed applying YieldHub rounding politic.
 *
 * @param fractionDigits
 * @param float
 * @returns {string}
 */
const toFixed = (fractionDigits, float) => {

    // Multiply by pow fractionDigits. Discard float rounding errors.
    const floatMultiplied = parseFloat((float * Math.pow(10, fractionDigits)).toFixed(4));
    return (Math.round(floatMultiplied) / Math.pow(10, fractionDigits)).toFixed(fractionDigits);

};
