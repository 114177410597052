import React from 'react';

const SessionExpiredDialog = () => {

    const accept = (event) => {
        console.debug('SessionExpiredDialog.accept');

        event.preventDefault();
        event.stopPropagation();

        window.location.href = '/';
    };

    return (
        <div className="curves-modal">
            <div className="external-div" onClick={accept}></div>
            <div className="dialog">
                <div className="title">Expired Session</div>
                <div className="body">
                    <p>Your session has expired. Please, log in again.</p>
                </div>
                <div className="options">
                    <a hred="#" onClick={accept}>Accept</a>
                </div>
            </div>
        </div>
    )

};

export default SessionExpiredDialog;
