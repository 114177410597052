import React, { Component } from 'react';
import GlobalStore from "../store/GlobalStore";
import Spinner from "../common/Spinner";


const SF_CHAT_DEPLOYMENT_ID = '5720o000000gB7t';
const SF_ORGANIZATION_ID = '00D90000000HJvd';
const SF_CHAT_BUTTON_ID = '5730o000000gBIr';

class LiveChatButton extends Component {

    constructor(props) {
        super(props);

        this.state ={
            liveChatLoaded: false
		}
    }

	componentDidMount(){
        this.loadChatLive();
	}

    loadChatLive(){
        const script = document.createElement("script");
        script.src = "https://c.la1-c2-ukb.salesforceliveagent.com/content/g/js/48.0/deployment.js";
        script.async = true;
        script.onload = () => this.initChatLive();
        document.body.appendChild(script);
    }

     initChatLive(){
        liveagent.init('https://d.la1-c2-ukb.salesforceliveagent.com/chat', SF_CHAT_DEPLOYMENT_ID, SF_ORGANIZATION_ID);

        liveagent.setName(this.props.globalStore.user.first_name);

        //TODO verify which custom details is still necessary.
        if (this.props.globalStore.user.first_name != null){
            liveagent.addCustomDetail("First Name", this.props.globalStore.user.first_name);
        }
        if (this.props.globalStore.user.last_name != null){
            liveagent.addCustomDetail("Last Name", this.props.globalStore.user.last_name);
        }
        if (this.props.globalStore.user.contact_id != null){
            liveagent.addCustomDetail("Contact ID", this.props.globalStore.user.contact_id);
        }
        if (this.props.globalStore.user.account_id != null){
            liveagent.addCustomDetail("AccountId", this.props.globalStore.user.account_id, false);
        }
        if (this.props.globalStore.user.client_name != null){
            liveagent.addCustomDetail("Account Name", this.props.globalStore.user.client_name);
        }
        if (this.props.globalStore.user.owner_id != null){
            liveagent.addCustomDetail("Owner ID", this.props.globalStore.user.owner_id);
        }

        /* below line of code will search for contact based on contact id */
        liveagent.findOrCreate("Contact").map("Id", "Contact ID", true, true,false);
        /* below line of code with associate the identified contact with the chat transcript */
        liveagent.findOrCreate("Contact").saveToTranscript("ContactId").showOnCreate();

		/* below line of code will associate the identified account with the chat transcript */
		liveagent.findOrCreate("Account").map("Id", "AccountId", true, true,false);
		liveagent.findOrCreate("Account").saveToTranscript("AccountId").showOnCreate();

        this.setState({liveChatLoaded:true});
    }

    startChat(){
        const ownerId = this.props.globalStore.user.owner_id?this.props.globalStore.user.owner_id.substr(0,15):"";
        liveagent.startChat(SF_CHAT_BUTTON_ID,ownerId,true);
    }

    render() {

        const onlineButtonClass = "liveagent_button_online_"+SF_CHAT_BUTTON_ID;
        const offlineButtonClass = "liveagent_button_offline_"+SF_CHAT_BUTTON_ID;

        if (this.state.liveChatLoaded) {
            if (!window._laq) {
                window._laq = [];
            }
            window._laq.push(function () {
                liveagent.showWhenOnline(SF_CHAT_BUTTON_ID, document.getElementsByClassName(onlineButtonClass)[0]);
                liveagent.showWhenOffline(SF_CHAT_BUTTON_ID, document.getElementsByClassName(offlineButtonClass)[0]);
            });

            return (
                <div className='curves-live-chat'>
                    <div className={"chat-icon "+ onlineButtonClass} style={{display: 'none'}} tooltip="Chat now!"
                         flow="top"
                         onClick={() => this.startChat()}>
                        <svg>
                            <use xlinkHref={'#curves-svg-chat-online'}></use>
                        </svg>
                    </div>
                    <div className={"chat-icon "+ offlineButtonClass} style={{display: 'none'}} tooltip="Chat offline"
                         flow="top">
                        <svg>
                            <use xlinkHref={'#curves-svg-chat-offline'}></use>
                        </svg>
                    </div>
                </div>
            );
        }else{
            return(
                <div className='curves-live-chat'>
                    <Spinner/>
                </div>
            )
        }
    }
}

export default GlobalStore.connect(LiveChatButton);
