import React, { Component } from 'react';
import EOIService from "../../service/EOIService";
import Spinner from "../common/Spinner";
import GlobalStore from "../store/GlobalStore";
import scrollToElement from "scroll-to-element";
import {format_num, strip_num} from "../../common/formatUtil";
import DialogUtil from '../../common/DialogUtil';

class GeneralInquiryForm extends Component {

    constructor(props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.closeForm = this.closeForm.bind(this);

        this.state = {
            sending: false,
            amount: '',
            term: '',
            otherRequirements: '',
            validationErrors: {},
        };
    }

    validate() {
        let validationErrors = {};

        if (this.state.amount.trim() === '' && this.state.term.trim() === '' && this.state.otherRequirements.trim() ==='') {
            validationErrors.error = 'You must complete at least one field.';
        }


        this.setState({
            validationErrors
        });

        return validationErrors;
    }

    closeForm(){
        this.setState({
            amount: '',
            term: '',
            otherRequirements: '',
            validationErrors: {},
            submitOk:null
        });
        GlobalStore.setState({
            showGlobalEnquiryForm:false
        });
    }

    submitForm() {
        const validationErrors = this.validate();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const generalInquiry = {
            amount: this.state.amount,
            term: this.state.term,
            message: this.state.otherRequirements
        };

        this.setState({sending: true});

        EOIService.generalInquiry(generalInquiry)
            .then((response) => {
                this.setState({submitOk: true, sending: false});
            })
            .catch((error) => {
                this.setState({submitOk: false, sending: false});
            });
    }

    isSubmitFail() {
        return this.state.submitOk !== null && this.state.submitOk === false;
    }

    render() {
        const showForm = this.props.globalStore.showGlobalEnquiryForm;
        const cssClass = "general-inquiry-form " + (showForm?"":"hidden");
        if(this.state.submitOk){
            return(
                <div className={cssClass}>
                    <div className="success-message-container">
                        <h3>Sent message</h3>
                        <p>Thank you - A Curve team member will be in touch with you shortly.</p>
                        <div className="button-container">
                            <button className="accept-btn" onClick={DialogUtil.close}>DONE</button>
                        </div>
                    </div>
                </div>
            )
        }
            return (
                <div className={cssClass}>
                    <div className="text-section">
                        We understand these opportunities might not be what you are looking for. If there is something more specific, let us know and we'll find some other options!
                    </div>
                    <div className="form-section">
                        {this.isSubmitFail() &&
                        <div className="error-message">An error occurs, please try again later</div>
                        }
                        <label>
                            <Information tooltip="If your placing more than one deposit let us know" flow="left"/>Amount (s):
                            <input type="text" value={format_num(this.state.amount)} onChange={e => this.setState({amount: strip_num(e.target.value)})}/>
                            <div className="validation-errors">{this.state.validationErrors.amount}</div>
                        </label>
                        <label>
                            <Information tooltip="Let us know if you’re looking for more than one term" flow="left"/>Term (s):
                            <input type="text" value={this.state.term} onChange={e => this.setState({term: e.target.value})}/>
                            <div className="validation-errors">{this.state.validationErrors.term}</div>
                        </label>
                        <label>
                            <Information tooltip="Do you need a specific credit rating today? How many deposits are you looking to do? Anything else?" flow="left"/> Other requirements:
                            <textarea value={this.state.otherRequirements} onChange={e => this.setState({otherRequirements: e.target.value})}/>
                            <div className="validation-errors">{this.state.validationErrors.otherRequirements}</div>
                        </label>
                        {this.state.sending && <div className="general-inquiry-sending"><Spinner/></div>}
                        <div className="validation-errors">{this.state.validationErrors.error}</div>
                        <div className="submit-btn">
                            <input type="submit"
                                   onClick={this.submitForm}
                                   value="Submit"/>

                            {/* <input type="submit"
                                   onClick={DialogUtil.close}
                                   value="Close"/> */}
                        </div>
                    </div>
                </div>
            );



    }

}

const Information = ({tooltip, flow}) => (
    <div className="information tooltip" tooltip={tooltip} flow={flow}><i className="icon icon-info"></i></div>
);

export default GlobalStore.connect(GeneralInquiryForm);
