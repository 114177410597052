import React from 'react';
import SimpleDialog from "./SimpleDialog";
import DialogUtil from "../../common/DialogUtil";

const OnboardingDefinitionsDialog = () => {

    const handleAccept = () => {
        DialogUtil.close();
    };

    return (
        <SimpleDialog className="curves-terms-and-conditions-dialog" title="Terms & Conditions"
                      onClickOutside={DialogUtil.close}>
            <div className="message">
                <iframe src='/pdf/CRS AustraliaUS FATCA Definitions and Standards.pdf'></iframe>
            </div>
            <div className="options">
                <a className="confirm-btn" onClick={handleAccept}>Accept</a>
            </div>
        </SimpleDialog>
    );

};

export default OnboardingDefinitionsDialog;
