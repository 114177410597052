import React, { Component } from 'react';

import {withRouter} from 'react-router-dom';
import UserFilterService from "../../service/UserFilterService";
import UserStatService from "../../service/UserStatService";
import QueryParams from "../../common/QueryParams";
import DialogUtil from "../../common/DialogUtil";
import ConfirmDialog from "../dialog/ConfirmDialog";



class SavedFilters extends Component {

    constructor(props) {
        super(props);
        this.savedFilters = this.savedFilters.bind(this);
        this.applyUserFilter = this.applyUserFilter.bind(this);
        this.state = {
            savedFiltersVisible: false
        };

    }

    /**
     * Show saved filters.
     *
     */
    savedFilters() {
        if (this.state.savedFiltersVisible) {
            this.setState({savedFiltersVisible: false});
        } else {
            UserFilterService.findAll().then(userFilters => {
                this.setState({
                    savedFilters: userFilters,
                    savedFiltersVisible: true
                })
            });
        }

    }

    applyUserFilter(userFilter) {
        UserStatService.createProductFilterLoadUserStat(userFilter.id);

        const filters = JSON.parse(userFilter.filters);

        const newFilters = {
            filterTerm: filters.filterTerm,
            filterRatingShortTerm: filters.filterRatingShortTerm,
            filterRatingLongTerm: filters.filterRatingLongTerm,
            filterDepositMin: filters.filterDepositMin,
            filterDepositMax: filters.filterDepositMax,
            filterFossilFuelFree: filters.filterFossilFuelFree,
            filterInstitutionType: filters.filterInstitutionType
        };
        QueryParams.change(this.props, newFilters);
        this.setState({savedFiltersVisible: false});
    }

    deleteUserFilter(userFilter) {
        DialogUtil.open(<ConfirmDialog message={"Delete user filter named '" + userFilter.name + "' ?"} />).then(() => {
            UserFilterService.delete(userFilter.name).then(() => {
                DialogUtil.close();
                const items = this.state.savedFilters.filter(item => item.id !== userFilter.id);
                this.setState({ savedFilters: items });
            });
        });
    }

    render() {
        return (
            <div className={"filters-dropdown animate " + (this.state.savedFiltersVisible ? " open" : 'closed')}>
                <div className="title" onClick={this.savedFilters}>
                    <svg><use xlinkHref={this.state.savedFiltersVisible ? '#curves-svg-triangle-up' : '#curves-svg-triangle-down'}></use></svg>
                    <span>  Saved filters</span>
                </div>
                {this.state.savedFilters !== undefined &&
                <div className="filter-dropdown-content">
                    {this.state.savedFilters.map(userFilter =>
                        <div key={userFilter.id} className="filter-item">
                            <div className="filter-name" onClick={() => {this.applyUserFilter(userFilter)}}>{userFilter.name}</div>
                            <div className="delete-filter" onClick={() => {this.deleteUserFilter(userFilter)}}>
                                <img src="/img/icon/cancel.svg" />
                            </div>
                        </div>
                    )}
                </div>
                }
            </div>
        )
    }

}

export default withRouter(SavedFilters);
