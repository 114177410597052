export const CLIENT_TYPES = {
    BRONZE: 'BRONZE',
    SILVER: 'SILVER',
    GOLD: 'GOLD',
    PLATINIUM: 'PLATINIUM',
    PLATINIUM_PLUS: 'PLATINIUM_PLUS',
    TREASURY: 'TREASURY'
    //RETAIL:'RETAIL',
    //FIN_INSTITUTION:'FIN_INSTITUTION'
};




