import queryString from "query-string";
import getArrayQueryParam from "./getArrayQueryParam";
import moment from "moment";
import {PRODUCT_VISIBILITY} from "./types/productVisibilityTypes";
import {PRODUCT_ATTRIBUTE} from "./types/productAttributeTypes";
import {getAttributesOnTerm} from "./productUtil";

export const ON_REQUEST_LABEL = 'On request';
/*
export const TERM_GROUP = {
    _6_8M: '6 mth-8 mth',
    _9_11M: '9 mth-11 mth',
    _1YR: '1 yr',
    _1YR_PLUS: '>1 yr'
};
export const groupTerms = (terms) => {
    let termDays;
    let grouped = [];
    terms.forEach(function (term, index) {
        termDays = parseInt(term.name);
        if (termDays >= 180 && termDays <= 240) {
            addTerm(grouped, term, TERM_GROUP._6_8M);
        } else if (termDays > 240 && termDays <= 359) {
            addTerm(grouped, term, TERM_GROUP._9_11M);
        } else if (termDays === 360) {
            addTerm(grouped, term, TERM_GROUP._1YR);
        } else if (termDays > 360) {
            addTerm(grouped, term, TERM_GROUP._1YR_PLUS);
        } else {
            addTerm(grouped, term, term.name.toUpperCase(), index)
        }
    });
    return grouped;
};
export const getTermGroup = (term) => {
    if (term >= 180 && term <= 240) {
        return TERM_GROUP._6_8M;
    } else if (term > 240 && term <= 359) {
        return TERM_GROUP._9_11M;
    } else if (term == 360) {
        return TERM_GROUP._1YR;
    } else if (term > 360) {
        return TERM_GROUP._1YR_PLUS;
    } else {
        return term;
    }
};
*/
// isExpandable: show the + sign next to the column, to expand it
// show: show by default (5yr has to show by default, but it isn't expandable)
export const TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW = [
    {name: '1 mth', range: [0,30], show: true, isExpandable: true},
    {name: '2 mth', range: [30,60], show: false, isExpandable: false, parentTerm: '1 mth'},
    //{name: '1 mth-2 mth', range: [0,60], show: true, isExpandable: false},
    {name: '3 mth', range: [60,90], show: true, isExpandable: true},
    {name: '4 mth', range: [90,120], show: false, isExpandable: false, parentTerm: '3 mth'},
    {name: '5 mth', range: [120,150], show: false, isExpandable: false, parentTerm: '3 mth'},
    //{name: '3 mth-5 mth', range: [60,150], show: true, isExpandable: false},
    {name: '6 mth', range: [150,180], show: true, isExpandable: true},
    {name: '7 mth', range: [180,210], show: false, isExpandable: false, parentTerm: '6 mth'},
    {name: '8 mth', range: [210,240], show: false, isExpandable: false, parentTerm: '6 mth'},
    {name: '9 mth', range: [240,270], show: true, isExpandable: true},
    {name: '10 mth', range: [270,300], show: false, isExpandable: false, parentTerm: '9 mth'},
    {name: '11 mth', range: [300,330], show: false, isExpandable: false, parentTerm: '9 mth'},
    {name: '1 yr', range: [330,365], show: true, isExpandable: true},
    {name: '18 mth', range: [540,570], show: false, isExpandable: false, parentTerm: '1 yr'},
    {name: '2 yr', range: [365,730], show: true, isExpandable: true},
    {name: '3 yr', range: [730,1095], show: false, isExpandable: false, parentTerm: '2 yr'},
    {name: '4 yr', range: [1095,1460], show: false, isExpandable: false, parentTerm: '2 yr'},
    {name: '5 yr', range: [1460,1825], show: true, isExpandable: false},
    // This 1 yr-5 yr term group is taking into account the twelveth month (330>)
    //{name: '1 yr-5 yr', range: [330,1825], show: true,,
    //{name: '>5 yr', range: [1825,Number.POSITIVE_INFINITY], show: true, isExpandable: false}
]
export const TERM_GROUP_WITH_RANGES_FOR_LIST_VIEW = [
    {name: '1 mth', range: [0,30]},
    {name: '2 mth', range: [30,60]},
    {name: '3 mth', range: [60,90]},
    {name: '4 mth', range: [90,120]},
    {name: '5 mth', range: [120,150]},
    {name: '6 mth-8 mth', range: [150,240]},
    {name: '9 mth-11 mth', range: [240,330]},
    {name: '1 yr', range: [330,365]},
]

export function isInBetweenRange(value, range){
    value = parseInt(value);
    return (range[0] < value && value <= range[1]);
}

export const groupTermsForListView = (terms) => {
    let termDays;
    let grouped = [];
    terms.forEach(function (term, index) {
        termDays = parseInt(term.name);
        TERM_GROUP_WITH_RANGES_FOR_LIST_VIEW.forEach( function(termGroup,index){
            if(isInBetweenRange(termDays, termGroup.range)){
                term.show = termGroup.show;
                term.isExpandable = termGroup.isExpandable;
                term.parentTerm = termGroup.parentTerm;
                addTerm(grouped, term, termGroup);
            }
        });
    });
    return grouped;
}

const baseGroupedRatesForGivenTermGroups = (termGroups) => {
    let grouped = [];
    termGroups.forEach(function(termGroup){
        let element = {
            name: termGroup.name,
            value: ON_REQUEST_LABEL,
            attributes:[{placeholder:true}],
            updated_date: moment("10/11/1999 10:00"),
            show: termGroup.show,
            isExpandable: termGroup.isExpandable,
            parentTerm: termGroup.parentTerm,
        };
        grouped.push(element);
        });
    return grouped;
}
// weAreFilteringTerms is true by default because the function it's used in other places besides MaxYields
export const groupTermsForCardView = (terms, weAreFilteringTerms = true, searchString = null) => {
    let termDays;
    let grouped = null;
    let termGroups = TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW;
    if(weAreFilteringTerms) {
        let termGroupsForCardView = [...TERM_GROUP_WITH_RANGES_FOR_CARD_VIEW];
        let newTermGroups = [];
        termGroupsForCardView.forEach(function (termGroup) {
            let isTermOnFilters = false;
            let regex = new RegExp("filterTerm=[0-9]+","g");
            let matchVal = "";
            while ((matchVal = regex.exec(searchString)) !== null) {
                let numberOfDays = parseInt(matchVal[0].match("[0-9]+")[0]);
                if (isInBetweenRange(numberOfDays, termGroup.range)) {
                    isTermOnFilters = true;
                }
            }

            if (isTermOnFilters) {
                newTermGroups.push(termGroup);
            }
        });
        termGroups = newTermGroups;
    }
    grouped = baseGroupedRatesForGivenTermGroups(termGroups);
    terms.forEach(function (term, index) {
        termDays = parseInt(term.name);
        termGroups.forEach( function(termGroup,index){
            if(isInBetweenRange(termDays, termGroup.range)){
                term.show = termGroup.show;
                term.isExpandable = termGroup.isExpandable;
                term.parentTerm = termGroup.parentTerm;
                addTerm(grouped, term, termGroup);
            }
        });
    });
    return grouped;
}

export const groupGroupedTerms = (terms, cardView) =>{
    let groupedGroupTerms = [];
    let parentTerm;
    terms.forEach( function (term, index){
        if((term.isExpandable && term.isExpandable === true) || (term.show && term.show === true)){
            if(parentTerm !== undefined){
                groupedGroupTerms.push(parentTerm);
            }
            parentTerm = term;
            parentTerm.childTerms = [];
        } else {
            if(cardView){
                if(!term.dontShowOnCardView) {
                    parentTerm.childTerms.push(term);
                }
            } else {
                parentTerm.childTerms.push(term);
            }
        }
    });
    if(parentTerm !== undefined){
        groupedGroupTerms.push(parentTerm);
    }
    return groupedGroupTerms;
}

export const TERM_GROUP = {
    _1_M: '1 mth',
    _2_M: '2 mth',
    _1_2M: '1 mth-2 mth',
    _3_M: '3 mth',
    _4_M: '4 mth',
    _5_M: '5 mth',
    _3_5M: '3 mth-5 mth',
    _6_M: '6 mth',
    _7_M: '7 mth',
    _8_M: '8 mth',
    _6_8M: '6 mth-8 mth',
    _9_M: '6 mth',
    _10_M: '7 mth',
    _11_M: '8 mth',
    _9_11M: '9 mth-11 mth',
    _1_Y: '1 yr',
    _2_Y: '2 yr',
    _3_Y: '3 yr',
    _4_Y: '4 yr',
    _5_Y: '5 yr',
    _1_5Y: '1 yr-5 yr',
    _5YR_PLUS: '>5 yr'
};
export const getTermGroup = (term) => {
    if (termDays >= 30 && termDays <= 60) {
        return TERM_GROUP._1_2M;
    } else if (termDays > 60 && termDays <= 150) {
        return TERM_GROUP._3_5M;
    } else if (termDays > 150 && termDays <= 240) {
        return TERM_GROUP._6_8M;
    } else if (termDays > 240 && termDays <= 330) {
        return TERM_GROUP._9_11M;
    } else if (termDays > 330 && termDays <= (365*5) ) {
        return TERM_GROUP._1_5Y;
    } else if (termDays > (365*5)){
        return TERM_GROUP._5YR_PLUS;
    } else {
        return term;
    }
};

export const formatTerms = (terms) => {
    let formatted = [];

    terms.forEach(function (term, index) {
        addTerm(formatted, term, term, index)
    });
    return formatted;
};

export const bestRates = (products, includeStringTerms, location, allowTie = false, filterNoValues = true) => {

    const TERM_COLUMN_TYPE = PRODUCT_ATTRIBUTE.TERM;
    const urlQueryParams = queryString.parse(location.search);
    const filterTerms = getArrayQueryParam(urlQueryParams, 'filterTerm');
    let bestRates = [];
    products.forEach((product) => {
        product.attributes.forEach((attribute) => {
            const actualTerm = attribute.name;
            const actualRate = (attribute.value === '' || attribute.value === undefined || attribute.value === null
                || isNaN(attribute.value.replace(/,/, '.'))) ? 0 : parseFloat(attribute.value.replace(/,/, '.'));

            if(!includeStringTerms && attribute.value_type !== TERM_COLUMN_TYPE){
                return;
            }

            /**
             * Filtered by terms.
             */
            if (filterTerms.length > 0 && filterTerms.indexOf(actualTerm.toString()) === -1) {
                return;
            }


            const bRate = bestRates && bestRates.find(it => it.name === actualTerm.toString());
            //If term not exists, i add it to bestRates
            if (bRate === undefined) {
                bestRates.push({id: product.id, bank: product.bank, name: actualTerm, value: actualRate});
            } else {
                // If the term exists and has a better rate, I add it to bestRates
                if (actualRate > bRate.value) {
                    bestRates[bestRates.indexOf(bRate)] = {
                        id: product.id,
                        bank: product.bank,
                        name: actualTerm,
                        value: actualRate
                    };
                    //Remove all previous rates for the term
                    bestRates = bestRates.filter(it => ((it.name === actualTerm && it.value !== bRate.value) || it.name !== actualTerm));
                }
                // In case of a tie, more than one product per term is added
                else if (allowTie && actualRate > 0 && actualRate === bRate.value) {
                    bestRates.splice(bestRates.indexOf(bRate) + 1, 0, {
                        id: product.id,
                        bank: product.bank,
                        name: actualTerm,
                        value: actualRate
                    });
                }
            }
        });

    });
    if (filterNoValues) {
        bestRates = bestRates.filter(it => (it.value !== 0));
    }
    return bestRates;
};

/**
 * Does attribute has best rate?
 *
 * @param bestRates Example: [ { name: "60", value: 2.15 } ]
 * @param attribute Example: { name: "360", value: "1.9600" }
 * @returns boolean
 */
export const attributeHasBestRate = (bestRates, attribute) =>
    bestRates.some(it => it.name === attribute.name && it.value === parseFloat(attribute.value));

/**
 * Term is highlighted?
 * Note: term with best rate is also highlighted.
 *
 * @param products
 * @param product
 * @param term
 * @returns {boolean}
 */
export const isTermHighlighted = (products, product, attributes, location) => {

    const bRates = bestRates(products, false, location);

    return attributes.some(attr => attr.visibility === PRODUCT_VISIBILITY.HIGHLIGHT
                                || attr.value_type === PRODUCT_ATTRIBUTE.TERM
                                && attributeHasBestRate(bRates, attr));

};

/**
 * Term is on Request?
 * @param attributes
 * @returns {boolean | *}
 */
export const isTermRateOnRequest = (attributes) => {
    return attributes.some(attr => attr.visibility === PRODUCT_VISIBILITY.ON_REQUEST);
};

export const groupOnRequestRates = (terms) => {
    let grouped = [];
    let numberOfOnRequest = 0;
    let groupOnRequest = null;
    terms.forEach(function (term, index) {
        if (term.value === 'On Request') {
            numberOfOnRequest++;
            if (groupOnRequest === null) {
                groupOnRequest = term;
            } else {
                groupOnRequest.attributes.push(...term.attributes);
            }
            if (index === terms.length - 1){
                groupOnRequest.columns = numberOfOnRequest;
                grouped.push(groupOnRequest);
            }
        } else {
            if (groupOnRequest !== null) {
                if (numberOfOnRequest > 1) {
                    groupOnRequest.columns = numberOfOnRequest;
                }
                grouped.push(groupOnRequest);
                numberOfOnRequest = 0;
                groupOnRequest = null;
            }
            grouped.push(term);
        }
    });
    return grouped;
};

const addTerm = (grouped, term, termGroup, index) => {
    let value = grouped.find( term => term['name'] === termGroup.name);
    if (value === undefined) {
        const element = {
            name: termGroup.name,
            value: term.value !== null ? term.value : term.visibility === PRODUCT_VISIBILITY.ON_REQUEST ? ON_REQUEST_LABEL : "",
            attributes:[term],
            updated_date: term.updated_date,
            show: term.show,
            isExpandable: termGroup.isExpandable,
            parentTerm: termGroup.parentTerm,
        };
        if (index !== undefined) {
            grouped.splice(index, 0, element);
        } else {
            grouped.push(element);
        }
    } else {
        // Don't include '18 mth' on '2 yr' range
        if((termGroup.name === '2 yr' && !isInBetweenRange(term.name,[539,570])) || termGroup.name !== '2 yr'){
            if(value.attributes.length===1 && value.attributes[0].placeholder){
                value.attributes[0] = term;
            } else {
                value.attributes.push(term);
            }
            if (term.value !== null && (value.value === "" || parseFloat(term.value) > parseFloat(value.value) || isNaN(value.value))) {
                value.value = term.value;
            }
        }

        if (term.hasOwnProperty('updated_date') && value.hasOwnProperty('updated_date') && term.updated_date !== null
            && (value.updated_date === null || moment.utc(value.updated_date).isBefore(moment.utc(term.updated_date)))) {
                value.updated_date = term.updated_date;
        }
    }
};
