export const FINANCIAL_SCHEME = {
    ADI_MAJORS: 'ADI_MAJORS',
    ADI_BRANCH_OF_FOREIGN_BANK: 'ADI_BRANCH_OF_FOREIGN_BANK',
    AUSTRALIAN_INCORPORATED: 'AUSTRALIAN_INCORPORATED'
};


export const FINANCIAL_SCHEME_LABEL = [
    { label: 'Majors',  value: FINANCIAL_SCHEME.ADI_MAJORS },
    { label: 'Australian Incorporated Banking Institutions',  value: FINANCIAL_SCHEME.AUSTRALIAN_INCORPORATED },
    { label: 'Branch of Foreign Bank', value: FINANCIAL_SCHEME.ADI_BRANCH_OF_FOREIGN_BANK}];

export const financialSchemeLabel =  financial_scheme => {
    const financial_scheme_found = FINANCIAL_SCHEME_LABEL.find(it => it.value === (financial_scheme));

    return financial_scheme_found ? financial_scheme_found.label : financial_scheme;
};
