import React, { Component } from 'react';

import {BrowserRouter, StaticRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import GlobalStore from "./store/GlobalStore";
import AppContainer from "./layout/AppContainer";
import AxiosInterceptor from "./axios/AxiosInterceptor";
import axios from "axios";

class ClientApp extends Component {

    constructor(props) {
        super(props);
    }

    static main() {
        /**
         * Do not execute hydrate when we are doing server side rendering.
         */
        if (ClientApp.isServerSideRender()) {
            return;
        }

        AxiosInterceptor.install();

        //RSM-363. Set loginAsUser as default parameter.
        if( window.globalStoreInitialState !== undefined
            && window.globalStoreInitialState.user !== undefined
            && window.globalStoreInitialState.user.admin_user_admin_dto !== null) {
            axios.defaults.params = {loginAsUser:window.globalStoreInitialState.user.id};
        }

        console.debug('ClientApp.main');

        ReactDOM.hydrate(<ClientApp store={GlobalStore.create(window.globalStoreInitialState)} />,
                            document.getElementById('app'));



    }

    /**
     * Returns true when executing during server side rendering, ie window variable is undefined.
     *
     * @returns {boolean}
     */
    static isServerSideRender() {
        return typeof window === 'undefined';
    }

    render() {

        if (ClientApp.isServerSideRender()) {
            return (
                <Provider store={this.props.store}>
                    <StaticRouter location={this.props.url} context={{}}>
                        <AppContainer />
                    </StaticRouter>
                </Provider>
            )
        }
        else {
            return (
                <Provider store={this.props.store}>
                    <BrowserRouter>
                        <AppContainer />
                    </BrowserRouter>
                </Provider>
            )
        }

    }

}

export default ClientApp;

/**
 * Call client app entry point.
 */
ClientApp.main();
