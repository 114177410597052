import getEnvironment from "./getEnvironment";

export const ENV_VARIABLES = {
    API_INTERNAL_HOST_NAME: 'API_INTERNAL_HOST_NAME',
    PORTFOLIO_MANAGER_URL: 'PORTFOLIO_MANAGER_URL',
    GOOGLE_CLOUD_STORAGE_PROJECT_ID: 'GOOGLE_CLOUD_STORAGE_PROJECT_ID',
    CLOUD_BUCKET_NAME: 'CLOUD_BUCKET_NAME',
};


export const getEnvParam = (param) => {
    return process.env[param]
};

export const getWebSocketURL = () => {
    if (getEnv() === 'DEV') {
        return 'http://localhost:8080/socket'
    }
    else {
        return '/api/socket'
    }
};

export const getEnv = () => {
    return getEnvironment().toUpperCase();
};
