import axios from "axios";


class EOIService {

    static post(eoi) {
        return axios.post('/api/client/expression_of_interest', eoi);
    }

    static requestRate(eoi) {
        return axios.post('/api/client/expression_of_interest/request_rate', eoi);
    }

    static requestMoreInformation(eoi) {
        return axios.post('/api/client/expression_of_interest/request_more_information', eoi);
    }

    static generalInquiry(inquiry) {
        return axios.post('/api/client/expression_of_interest/general_inquiry', inquiry);
    }

    /**
     * Express interest in product with non standard term.
     *
     * @param product_id
     * @param amount
     * @param maturity_date
     * @param additional_notes
     * @returns {Promise<any>}
     */
    static expressInterestWithNonStandardTerm(product_id, amount, maturity_date, additional_notes) {
        additional_notes = additional_notes !== '' ? additional_notes : null;
        return new Promise(resolve => {
            axios.post('/api/client/expression_of_interest/non_standard_term',
                  {product_id, amount, maturity_date, additional_notes}).then(() => {
                resolve();
            });
        });
    }

}

export default EOIService;
