export const SPECIAL_STATUS_TYPES = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    FINISHED: 'FINISHED',
    CLOSED: "CLOSED"
};

export const SPECIAL_STATUS_LABEL = [
    {type: SPECIAL_STATUS_TYPES.PENDING, label: 'Pending'},
    {type: SPECIAL_STATUS_TYPES.ACTIVE,  label: 'Active'},
    {type: SPECIAL_STATUS_TYPES.PAUSED,  label: 'Paused'},
    {type: SPECIAL_STATUS_TYPES.FINISHED, label: 'Finished'},
    {type: SPECIAL_STATUS_TYPES.CLOSED, label: 'Closed'}
];
