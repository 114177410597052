import TermDepositsPage from "../page/TermDepositsPage";
import NCD_HQLA_Page from "../page/NCD_HQLA_Page";
import CashAccountsPage from "../page/CashAccountsPage";
import FloatingDepositPage from "../page/FloatingDepositPage";
import AboutPage from "../page/AboutPage";
import {CLIENT_TYPES} from "../../common/types/clientTypes";
import LoginPage from "../page/LoginPage";
import ResetPasswordPage from "../page/ResetPasswordPage";
import UserProfile from "../page/UserProfile";
import HistoricalAnalytics from "../page/HistoricalAnalytics";
import MaturityPreviewPage from "../page/MaturityPreviewPage";
import VideoPage from "../page/VideoPage";

export const routes = [
    {
        path: "/",
        component: TermDepositsPage,
        exact: true,
        linkLabel: 'Term Deposits',
        position:'left',
        level: 0,
        order: 1
    },
    {
        path: "/ncd",
        component: NCD_HQLA_Page,
        exact: true,
        linkLabel: 'NCDs',
        position:'left',
        level: 0,
        order: 2
    },
    {
        path: "/floating-deposits",
        component: FloatingDepositPage,
        exact: true,
        linkLabel: 'Floating Deposits',
        position:'left',
        level: 1,
        parent: 'other-products'
    },
    {
        path: "/cash-accounts",
        component: CashAccountsPage,
        exact: true,
        linkLabel: 'Cash & Notice Accounts',
        position:'left',
        level: 1,
        parent: 'other-products'
    },
    {
        path: "/yieldhub-analytics",
        component: HistoricalAnalytics,
        exact: true,
        linkLabel: 'Yieldhub Analytics',
        position:'right',
        level: 0,
    },
    // {
    //     path: "/",
    //     exact: true,
    //     cssClass:"general-inquiry",
    //     linkLabel: 'General inquiry',
    //     position:'right',
    //     level: 0
    // },

    {
        path: "/login",
        component: LoginPage,
        exact: true,
        fullWidth:true
    },
    {
        path: "/reset-password",
        component: ResetPasswordPage,
        exact: true,
        fullWidth:true
    },
    {
        path: "/my-profile",
        cssClass:"my-profile",
        component: UserProfile,
        exact: true,
        linkLabel: 'My Profile',
        position:'right',
        parent: 'profile-name',
        level: 1
    },
    {
        path: "/about",
        cssClass: "about",
        component: AboutPage,
        exact: true,
        linkLabel: 'About',
        position: 'right',
        parent: 'profile-name',
        level: 1
    },
    {
        path: "/videos",
        cssClass: "video",
        component: VideoPage,
        exact: true,
        linkLabel: 'Videos',
        position: 'right',
        parent: 'profile-name',
        level: 1
    },
    {
        path: "/maturity-preview",
        component: MaturityPreviewPage,
        exact: true,
        fullWidth:true,
        preview:true
    },



];

export const leftMenu = ()=>{
  return routes.filter(item => item.position === 'left');
};

export const rightMenu = ()=>{
    return  routes.filter(item => item.position === 'right');
};

export const currentRoute = (path)=>{
    return routes.filter(item => item.path === path);
}
